import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentOrg from 'sow/selectors/currentOrg';

import LandTypeSelect from 'sow/components/organisms/LandTypeSelect';

const mapStateToProps = (state, props) => ({
  visibleTypes: currentOrg.visibleTypes(state, props),
});

const LandTypeSelectContainer = ({ visibleTypes, ...props }) => {
  // Remove extraneous props that shouldn't be passed to the field
  const { router, match, location, routes, dispatch, type, ...fieldProps } = props;

  return <LandTypeSelect {...fieldProps} visibleTypes={visibleTypes} />;
};

LandTypeSelectContainer.propTypes = {
  visibleTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LandTypeSelectContainer);
