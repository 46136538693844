import * as R from 'ramda';
import recycleState from 'redux-recycle';
import { combineReducers } from 'redux';
import { reducer as thunk } from 'redux-saga-thunk';
import { connectRouter } from 'connected-react-router';

import actions from 'sow/actions/pure';

// original reducers
import auth from 'sow/reducers/auth';
import registration from 'sow/reducers/auth/registration';
import passwordReset from 'sow/reducers/auth/passwordReset';
import layout from 'sow/reducers/layout';
import messaging from 'sow/reducers/messaging';
import orgRegistration from 'sow/reducers/orgRegistration';
import fileUpload from 'sow/reducers/fileUpload';
import gis from 'sow/reducers/gis';
import acaAdminList from 'sow/reducers/acaAdminList';
import profile from 'sow/reducers/profile';
import acaActivityLog from 'sow/reducers/acaActivityLog';
import timezoneList from 'sow/reducers/timezoneList';
import attachment from 'sow/reducers/attachment';
import acaPendingList from 'sow/reducers/acaPendingList';
import acaNewRegistrationsList from 'sow/reducers/acaNewRegistrationsList';
import acaInProgressList from 'sow/reducers/acaInProgressList';
import orgExportFiles from 'sow/reducers/orgExportFiles';
import acaAdminAddStaff from 'sow/reducers/acaAdminAddStaff';
import certificationDocs from 'sow/reducers/certificationDocs';

// refactored reducers
import acas from 'sow/reducers/acas';
import organizations from 'sow/reducers/organizations';
import ospApplications from 'sow/reducers/ospApplications';
import ospDefinitions from 'sow/reducers/ospDefinitions';
import ospQualificationQuestions from 'sow/reducers/ospQualificationQuestions';
import ospSections from 'sow/reducers/ospSections';
import shell from 'sow/reducers/shell';
import worksheetAnswers from 'sow/reducers/worksheetAnswers';
import worksheetQuestions from 'sow/reducers/worksheetQuestions';
import worksheets from 'sow/reducers/worksheets';

// redux-fractal ported reducers (still need work)
import OspDefEditor from 'sow/components/old/OspDefEditor/reducers';

// redux-modules
import { reducer as entities } from 'sow/store/modules/entities';
import { reducer as resource } from 'sow/store/modules/resource';

const mapActionsToStrings = R.map(R.toString);
const recycleReducers = (...actions) =>
  R.map(reducer => recycleState(reducer, mapActionsToStrings(actions)));

export const appReducers = {
  // original reducers
  layout,
  auth,
  registration,
  passwordReset,
  messaging,
  orgRegistration,
  fileUpload,
  gis,
  acaAdminList,
  profile,
  acaActivityLog,
  timezoneList,
  attachment,
  acaPendingList,
  acaNewRegistrationsList,
  acaInProgressList,
  orgExportFiles,
  acaAdminAddStaff,
  certificationDocs,

  // refactored reducers
  acas,
  organizations,
  ospDefinitions,
  shell,

  // NOTE: reset all these reducers when detail view of org loaded
  // TODO: we should probably plan this out more
  ...recycleReducers(actions.org.resetChildData)({
    ospApplications,
    ospQualificationQuestions,
    ospSections,
    worksheetAnswers,
    worksheetQuestions,
    worksheets,
  }),

  // redux-fractal ported reducers
  OspDefEditor,
};

const libReducers = {
  thunk,
};

const reduxModulesReducers = {
  entities,
  resource,
};

// root reducer
const createRootReducer = history =>
  combineReducers({
    ...appReducers,
    ...libReducers,
    ...reduxModulesReducers,
    router: connectRouter(history),
  });

// reset all app state on logout
const resetActionTypes = [String(actions.core.state.reset)];
const resetReducer = R.pick(['router']); // keep routing state

const safeRootReducer = history =>
  recycleState(createRootReducer(history), resetActionTypes, resetReducer);

export default safeRootReducer;
