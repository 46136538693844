import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as ospSectionReducer from 'sow/reducers/ospSections';
// NOTE: In core selectors we only import other core selectors that have a parent
//       relationship to the core selector your importing to. This is to stop
//       circular dependencies. For example ospApplication can import
//       organization core selectors but not worksheets/worksheetAnswers core
//       selectors. Instead we would import ospApplication core selectors into
//       worksheet/worksheetAnswers core selectors and compose selectors there.
//       For higher level selectors that compose multiple core selectors you
//       should create them in files directly inside soworganic/selectors/
import * as ospDefSelect from 'sow/selectors/core/ospDefinition';
import * as ospAppSelect from 'sow/selectors/core/ospApplication';

export const ospSectionState = R.path(['ospSections']);

export const getSectionById = R.curry((state, sectionId) => {
  return ospSectionReducer.getOspSection(state)(sectionId);
});

// export const allOspSections = createSelector(
//   [ospSectionState],
//   ospSectionReducer.getOspSectionList
// );

export const ospSectionsForCurrentOspApp = createSelector(
  [ospSectionState, ospAppSelect.currentOspAppSectionIds],
  (state, ids) => {
    return ospSectionReducer.getOspSectionsByIds(state, ids);
  },
);

export const ospSectionIdsForCurrentOspDef = createSelector(
  [ospDefSelect.currentOspDefSectionIds],
  R.identity,
);

export const ospSectionsForCurrentOspDef = createSelector(
  [ospSectionState, ospSectionIdsForCurrentOspDef],
  ospSectionReducer.getOspSectionsByIds,
  // (state, ids) => {
  //   console.log('ospSectionsForCurrentOspDef::', {state, ids});
  //   return ospSectionReducer.getOspSectionsByIds(state, ids);
  // }
);

export const miniOspSectionIdsForCurrentOspDef = createSelector(
  [ospDefSelect.currentOspDefMiniOspSectionIds],
  R.identity,
);

export const miniOspSectionsForCurrentOspDef = createSelector(
  [ospSectionState, miniOspSectionIdsForCurrentOspDef],
  ospSectionReducer.getOspSectionsByIds,
  // (state, ids) => {
  //   return ospSectionReducer.getOspSectionsByIds(state, ids);
  // }
);

export const allOspSectionIdsForCurrentOspDef = createSelector(
  [ospSectionIdsForCurrentOspDef, miniOspSectionIdsForCurrentOspDef],
  R.concat,
);

export const allOspSectionsForCurrentOspDef = createSelector(
  [ospSectionsForCurrentOspDef, miniOspSectionsForCurrentOspDef],
  R.merge,
);
