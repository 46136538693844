import React from 'react';
import { hot } from 'react-hot-loader';
import { Switch, Redirect, Route } from 'react-router-dom';

import SecuredRoute from './SecuredRoute';

import DashboardRedirect from 'sow/containers/DashboardRedirect';
import LogoutPage from 'sow/containers/pages/LogoutPage';
import LoginPage from 'sow/containers/pages/LoginPage';
import EcertLoginPage from 'sow/containers/pages/EcertLoginPage';
import RequestNewPasswordPage from 'sow/components/old/auth/passwordReset/RequestNewPassword';
import RequestNewPasswordDonePage from 'sow/components/old/auth/passwordReset/RequestNewPasswordDone';
import ResetPasswordPage from 'sow/components/old/auth/passwordReset/ResetPassword';
import RegisterPage from 'sow/components/old/auth/register/Register';
import RegisterDonePage from 'sow/components/pages/RegisterDonePage';
import ValidateMailPage from 'sow/containers/pages/ValidateMailPage';
import AcaRegister from 'sow/components/old/auth/register/AcaRegister';
import InspectorRegister from 'sow/components/old/auth/register/InspectorRegister';
import AdminDashboardPage from 'sow/components/pages/AdminDashboardPage';
import CreateAcaPage from 'sow/containers/pages/CreateAcaPage';
import UserLookupPage from 'sow/components/pages/UserLookupPage';
import AcaDashboardPage from 'sow/containers/pages/AcaDashboardPage';
import AcaActivityDashboardPage from 'sow/containers/pages/AcaActivityDashboardPage';
import AcaPlanDashboardPage from 'sow/containers/pages/AcaPlanDashboardPage';
import OspDefinition from 'sow/containers/aca/OspDefinition';
import AcaOrgListPage from 'sow/containers/pages/AcaOrgListPage';
import AcaSettingsGeneralPage from 'sow/containers/pages/AcaSettingsGeneralPage';
import AcaSettingsStaffPage from 'sow/containers/pages/AcaSettingsStaffPage';
import AcaSettingsWorkflowPage from 'sow/components/pages/AcaSettingsWorkflowPage';
import AcaCertificationDocsPage from 'sow/containers/pages/AcaCertificationDocsPage';
import InspectorDashboardPage from 'sow/containers/pages/InspectorDashboardPage';
import ErrorUnauthorizedPage from 'sow/components/pages/ErrorUnauthorizedPage';
import ErrorForbiddenPage from 'sow/components/pages/ErrorForbiddenPage';
import ErrorNotFoundPage from 'sow/components/pages/ErrorNotFoundPage';
import ProfileMyProfilePage from 'sow/containers/pages/ProfileMyProfilePage';
import OrgDashboardPage from 'sow/containers/pages/OrgDashboardPage';
import OrgHistoricalPlansPage from 'sow/containers/pages/OrgHistoricalPlansPage';
import LegacyLocationsPage from 'sow/containers/pages/OrgLegacyLocationsPage';
import OrgSettingsGeneralTabPage from 'sow/components/pages/OrgSettingsGeneralTabPage';
import OrgSettingsAcaTabPage from 'sow/components/pages/OrgSettingsAcaTabPage';
import OrgSettingsInspectorsTabPage from 'sow/components/pages/OrgSettingsInspectorsTabPage';
import OrgSettingsMigratePage from 'sow/components/pages/OrgSettingsMigratePage';
import OrgPlanAppChecklistPage from 'sow/containers/pages/OrgPlanAppChecklistPage';
import OrgPlanAppWorksheetOverviewPage from 'sow/containers/pages/OrgPlanAppWorksheetOverviewPage';
import OrgPlanAppWorksheetPage from 'sow/containers/pages/OrgPlanAppWorksheetPage';
import OrgPlanAppLocationOverviewPage from 'sow/containers/pages/OrgPlanAppLocationOverviewPage';
import OrgPlanAppLocationPage from 'sow/containers/pages/OrgPlanAppLocationPage';
import OrgCertificationDocsPage from 'sow/containers/pages/OrgCertificationDocsPage';
import OrgPlanAppExportPage from 'sow/components/pages/OrgPlanAppExportPage';
import OrgAttachmentListPage from 'sow/containers/attachment/OrgAttachmentList';
import OrgAttachmentOspListPage from 'sow/containers/attachment/OrgAttachmentOspList';
import OrgSetupPage from 'sow/containers/pages/OrgSetupPage';

const Routes = () => (
  <Switch>
    <SecuredRoute path="/" component={DashboardRedirect} exact />

    {/* Authentication */}
    <Route path="/auth/logout" component={LogoutPage} exact />
    <Route path="/auth/login" component={LoginPage} exact />
    <Route path="/auth/ecert_login" component={EcertLoginPage} exact />
    <Route path="/auth/request-new-password" component={RequestNewPasswordPage} exact />
    <Route
      path="/auth/request-new-password/done"
      component={RequestNewPasswordDonePage}
      exact
    />
    <Route
      path="/auth/reset-password/:resetPasswordHash"
      component={ResetPasswordPage}
      exact
    />

    {/* Registration */}
    <Route path="/auth/register" component={RegisterPage} exact />
    <Route path="/auth/register/done" component={RegisterDonePage} exact />
    <Route
      path="/auth/register/validate-mail/:emailHash"
      component={ValidateMailPage}
      exact
    />
    <Route path="/-/:acaSlug" component={AcaRegister} exact />
    <Redirect from="/ccof" to="/-/ccof" exact />
    <Route path="/inspector" component={InspectorRegister} exact />

    {/* User Profile */}
    <SecuredRoute path="/profile" component={ProfileMyProfilePage} />

    {/* Administration */}
    <SecuredRoute
      path="/admin"
      component={AdminDashboardPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/create-aca"
      component={CreateAcaPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/user-lookup"
      component={UserLookupPage}
      allowedRoles={['admin']}
      exact
    />

    {/* ACA */}
    <SecuredRoute
      path="/aca/:acaId"
      component={AcaDashboardPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/activity"
      component={AcaActivityDashboardPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/osp"
      component={AcaPlanDashboardPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/osp/:ospDefId"
      component={OspDefinition}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/org"
      component={AcaOrgListPage}
      allowedRoles={['aca_admin']}
      exact
    />

    <Redirect from="/aca/:acaId/settings" to="/aca/:acaId/settings/general" exact />
    <SecuredRoute
      path="/aca/:acaId/settings/general"
      component={AcaSettingsGeneralPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/settings/staff"
      component={AcaSettingsStaffPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/settings/workflow"
      component={AcaSettingsWorkflowPage}
      allowedRoles={['aca_admin']}
      exact
    />

    <SecuredRoute
      path="/aca/:acaId/certification-docs"
      component={AcaCertificationDocsPage}
      allowedRoles={['aca_admin']}
      exact
    />

    {/* Inspector */}
    <SecuredRoute
      path="/inspect"
      component={InspectorDashboardPage}
      allowedRoles={['inspector']}
      exact
    />

    {/* Create Org */}
    <Redirect from="/org/create" to="/org/setup" />
    <SecuredRoute path="/org/setup" component={OrgSetupPage} exact />

    {/* Org */}
    <SecuredRoute path="/org/:orgId" component={OrgDashboardPage} exact />
    <SecuredRoute
      path="/org/:orgId/historical-plans"
      component={OrgHistoricalPlansPage}
      exact
    />
    <Route path="/org/:orgId/locations" component={LegacyLocationsPage} exact />

    {/* Org Settings */}
    <Redirect from="/org/:orgId/settings" to="/org/:orgId/settings/general" exact />
    <SecuredRoute
      path="/org/:orgId/settings/general"
      component={OrgSettingsGeneralTabPage}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/aca"
      component={OrgSettingsAcaTabPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/inspectors"
      component={OrgSettingsInspectorsTabPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/migrate"
      component={OrgSettingsMigratePage}
      allowedRoles={['admin']}
      exact
    />

    {/* Org Plan */}
    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/checklist"
      component={OrgPlanAppChecklistPage}
      exact
    />

    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/worksheet"
      component={OrgPlanAppWorksheetOverviewPage}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/worksheet/:worksheetId"
      component={OrgPlanAppWorksheetPage}
      exact
    />

    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/location"
      component={OrgPlanAppLocationOverviewPage}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/location/:locationId"
      component={OrgPlanAppLocationPage}
      exact
    />

    {/* Org Certification Docs */}
    <SecuredRoute
      path="/org/:orgId/certification-docs"
      component={OrgCertificationDocsPage}
      exact
    />

    {/* Export */}
    <SecuredRoute path="/org/:orgId/export" component={OrgPlanAppExportPage} exact />

    {/* Attachments */}
    <SecuredRoute path="/org/:orgId/attachment" component={OrgAttachmentListPage} exact />
    <SecuredRoute
      path="/org/:orgId/attachment/osp"
      component={OrgAttachmentOspListPage}
      exact
    />

    {/* Errors */}
    <Route path="/error/unauthorized" component={ErrorUnauthorizedPage} exact />
    <Route path="/error/forbidden" component={ErrorForbiddenPage} exact />
    <Route path="*" component={ErrorNotFoundPage} />
  </Switch>
);

// Authentication, password reset
const baseAuthRoute = '/auth';
export const loginRoute = () => `${baseAuthRoute}/login`;
export const logoutRoute = () => `${baseAuthRoute}/logout`;
export const requestNewPasswordRoute = () => `${baseAuthRoute}/request-new-password`;
export const requestNewPasswordDoneRoute = () =>
  `${baseAuthRoute}/request-new-password/done`;

export const resetPasswordRoute = resetPasswordHash =>
  `${baseAuthRoute}/reset-password/${resetPasswordHash}`;

// Registration
export const registerRoute = () => `${baseAuthRoute}/register`;
export const registerDoneRoute = () => `${registerRoute()}/done`;
export const validateMailRoute = emailHash =>
  `${registerRoute()}/validate-mail/${emailHash}`;

// Profile
export const profileRoute = () => '/profile';

// Superadmin
export const adminRoute = () => '/admin';
export const adminCreateAcaRoute = () => `${adminRoute()}/create-aca`;
export const adminUserLookupRoute = () => `${adminRoute()}/user-lookup`;

// Errors
const baseErrorRoute = '/error';
export const errorUnauthorizedRoute = () => `${baseErrorRoute}/unauthorized`;
export const errorForbiddenRoute = () => `${baseErrorRoute}/forbidden`;

// ACA Admin
export const acaRoute = acaId => `/aca/${acaId}`;
export const acaDashboardRoute = acaId => `${acaRoute(acaId)}/dashboard`;
export const acaActivityRoute = acaId => `${acaRoute(acaId)}/activity`;
export const acaCertificationDocsRoute = acaId => `${acaRoute(acaId)}/certification-docs`;
export const acaOspBaseRoute = acaId => `${acaRoute(acaId)}/osp`;
export const acaOspDefRoute = (acaId, ospDefId) => `${acaRoute(acaId)}/osp/${ospDefId}`;
export const acaOrgBaseRoute = acaId => `${acaRoute(acaId)}/org`;
export const acaSettingsRoute = acaId => `${acaRoute(acaId)}/settings`;
export const acaGeneralRoute = orgId => `${acaSettingsRoute(orgId)}/general`;
export const acaStaffRoute = acaId => `${acaSettingsRoute(acaId)}/staff`;
export const acaWorkflowRoute = orgId => `${acaSettingsRoute(orgId)}/workflow`;

// Inspector
export const inspectorRoute = () => `/inspect`;

// Org
export const orgRoute = orgId => `/org/${orgId}`;
export const historicalPlansRoute = orgId => `${orgRoute(orgId)}/historical-plans`;
export const exportRoute = orgId => `${orgRoute(orgId)}/export`;
export const attachmentRoute = orgId => `${orgRoute(orgId)}/attachment`;
export const attachmentOspRoute = orgId => `${attachmentRoute(orgId)}/osp`;
export const certificationDocsRoute = orgId => `${orgRoute(orgId)}/certification-docs`;
export const legacyLocationsRoute = orgId => `${orgRoute(orgId)}/locations`;

// Org Plan
export const planRoute = (orgId, planAppId) => `/org/${orgId}/plan/${planAppId}`;
export const checklistRoute = (orgId, planAppId) =>
  `${planRoute(orgId, planAppId)}/checklist`;
export const worksheetOverviewRoute = (orgId, planAppId) =>
  `${planRoute(orgId, planAppId)}/worksheet`;
export const worksheetRoute = (orgId, planAppId, wsId) =>
  `${planRoute(orgId, planAppId)}/worksheet/${wsId}`;
export const locationOverviewRoute = (orgId, planAppId) =>
  `${planRoute(orgId, planAppId)}/location`;
export const locationRoute = (orgId, planAppId, locationId) =>
  `${planRoute(orgId, planAppId)}/location/${locationId}`;

// Org settings
export const orgSettingsRoute = orgId => `${orgRoute(orgId)}/settings`;
export const orgSettingsAcaRoute = orgId => `${orgSettingsRoute(orgId)}/aca`;
export const orgSettingsGeneralRoute = orgId => `${orgSettingsRoute(orgId)}/general`;
export const orgSettingsInspectorRoute = orgId => `${orgSettingsRoute(orgId)}/inspectors`;
export const orgSettingsMigrateRoute = orgId => `${orgSettingsRoute(orgId)}/migrate`;
export default hot(module)(Routes);

// Create org
export const setupOrgRoute = () => '/org/setup';
