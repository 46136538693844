import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export const fetchAcaAdminListAsync = acaId =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/admin`,
  });

export const revokeAcaAdminAsync = (acaId, userId) =>
  apiRequest({
    method: 'DELETE',
    url: `${API_URL}/v1/aca/${acaId}/admin/${userId}`,
  });

export const createAcaAsync = aca =>
  apiRequest({
    method: 'POST',
    url: `${API_URL}/v1/aca`,
    data: { aca },
  });
