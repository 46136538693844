import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Input from 'sow/components/atoms/Input';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import FormGroup from 'sow/components/organisms/FormGroup';
import StaffForm from 'sow/components/organisms/AcaStaffForm';

const InviteUserForm = ({ user, onChange, errors }) =>
  <Block>
    <StaffForm user={user} onChange={onChange} errors={errors} />

    <InfoPanel text="We couldn't find a user with that email address. Would you like to invite them to Sow Organic?" />

    <FormGroup fieldName="name" errors={errors} label="What is their full name?" required>
      <Input id="name" value={user.name || ''} onChange={onChange('name')} required />
    </FormGroup>
  </Block>;

InviteUserForm.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default InviteUserForm;
