import React from 'react';
import PropTypes from 'prop-types';

import { adminRoute, adminUserLookupRoute } from 'sow/routes';
import SidebarLink from 'sow/components/molecules/SidebarLink';
import IconWithText from 'sow/components/molecules/IconWithText';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';

const SidebarAdmin = ({ navClasses }) => (
  <List className={navClasses}>
    <ListItem className="sidebar-heading" />

    <SidebarLink to={adminRoute()} exact>
      <IconWithText icon={<FontAwesome variant="far" icon="file-alt" />} text="ACAs" />
    </SidebarLink>

    <SidebarLink to={adminUserLookupRoute()} exact>
      <IconWithText icon={<FontAwesome icon="search" />} text="User Lookup" />
    </SidebarLink>
  </List>
);

SidebarAdmin.propTypes = {
  navClasses: PropTypes.string,
};

export default SidebarAdmin;
