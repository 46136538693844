import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import deepEqual from 'fast-deep-equal';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { PlanAppWorksheetAnswersLoader, PlanAppChangeLoader } from 'sow/store/containers';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { resourceDetailRead } from 'sow/store/helpers';
import * as currentUser from 'sow/selectors/currentUser';
import { orgRoute, worksheetRoute } from 'sow/routes';
import PlanAppWorksheetForm from 'sow/components/organisms/PlanAppWorksheetForm';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
  nextWorksheetId: fromPlanApp.nextWorksheetId(state, props),
  prevWorksheetId: fromPlanApp.prevWorksheetId(state, props),
  changeList: fromPlanApp.worksheetChangeList(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  worksheet: fromPlanApp.worksheet(state, props),
  worksheetAnswers: fromPlanApp.worksheetAnswers(state, props),
  isPlanAppClosed: fromPlanApp.isPlanAppClosed(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  initialValues: fromPlanApp.formWorksheetInitialValues(state, props),
  isWorksheetNotApplicable: fromPlanApp.isWorksheetNotApplicable(state, props),
});

const mapDispatchToProps = dispatch => ({
  redirect: (...args) => dispatch(routerPush(...args)),
  loadPlanApp: (orgId, planAppId) =>
    dispatch(
      resourceDetailRead(`org/${orgId}/application/${planAppId}`, 'planAppResp').action(),
    ),
  toast: (...args) => dispatch(actions.shell.toast(...args)),
});

class PlanAppWorksheetFormContainer extends React.Component {
  constructor() {
    super();
    this.formik = React.createRef();
  }

  buildUpdatedChangeList(worksheetAnswersChanges) {
    const getNewValue = change => {
      if (change.matrixRowId) {
        return R.path(
          ['matrixRows', change.matrixRowId, 'values', change.questionId],
          worksheetAnswersChanges,
        );
      } else if (change.type === 'worksheet') {
        return {
          isNotApplicable: worksheetAnswersChanges.isNotApplicable,
        };
      }

      return R.path(['values', change.questionId], worksheetAnswersChanges);
    };

    /**
     * This function takes a change (entity) object, tests if the change's new
     * property is different than the current form's value (using a deep equal,
     * NOT reference check!) and returns a new change object for PUT'ing to the
     * endpoint to save te update. If the value is not changed `null` is
     * returned.
     */
    const newChangeOrNil = change => {
      const newValue = getNewValue(change);
      const isEqual = deepEqual(change.new, newValue);
      return isEqual ? null : { ...change, new: newValue };
    };

    const isWorksheetRowChange = R.pathEq(['type'], 'matrix_row');

    /**
     * Takes list of change objects and returns array of any change objects that
     * have updated `new` value.
     */
    const updatedChangeList = R.pipe(
      R.map(newChangeOrNil),
      R.reject(R.isNil),
      R.reject(isWorksheetRowChange),
    );

    return updatedChangeList(this.props.changeList);
  }

  onClickSubmit = e => {
    e.preventDefault();
    this.setNoRedirect();
    this.formik.current.submitForm();
  };

  onClickSubmitNext = e => {
    e.preventDefault();
    const { nextWorksheetId, worksheetId } = this.props;

    if (nextWorksheetId === worksheetId) {
      // last worksheet so next goes to overview
      this.setRedirectOrgDashboard();
    } else {
      this.setRedirectToWorksheet(this.props.nextWorksheetId);
    }

    this.formik.current.submitForm();
  };

  onClickSubmitPrev = e => {
    e.preventDefault();
    this.setRedirectToWorksheet(this.props.prevWorksheetId);
    this.formik.current.submitForm();
  };

  handleSubmit = async (values, formikBag, saveChanges, saveWorksheetAnswers) => {
    try {
      await this.saveWorksheetAnswers(values, formikBag, saveWorksheetAnswers);
      await this.saveChangeRequestChanges(values, formikBag, saveChanges);

      const redirectTo = R.path(['state', 'status', 'redirectTo'], this.formik.current);
      if (redirectTo) {
        formikBag.resetForm(this.props.initialValues);
        this.props.redirect(redirectTo);
      } else {
        formikBag.resetForm(this.props.initialValues);
      }
    } catch (error) {
      console.error('PlanAppWorksheetForm ERRORS!!!', { error, values, status });
      formikBag.setStatus({ error: error.message });
      formikBag.setSubmitting(false);
    }
  };

  async loadPlanApp() {
    const { orgId, planAppId, loadPlanApp } = this.props;
    return loadPlanApp(orgId, planAppId);
  }

  saveChangeRequestChanges = async (values, formikBag, saveChanges) => {
    const { isStateInitialApplication, toast } = this.props;
    const updateChangeList = this.buildUpdatedChangeList(values.worksheetAnswersChanges);
    const hasUpdates = updateChangeList && !R.isEmpty(updateChangeList);

    if (hasUpdates) {
      await saveChanges({ changes: updateChangeList });
    }

    // only save worksheetAnswers for New Applications
    if (!isStateInitialApplication) {
      await toast('success', 'Worksheet changes saved.');
    }
  };

  saveWorksheetAnswers = async (values, formikBag, saveWorksheetAnswers) => {
    const { worksheetAnswers, isStateInitialApplication, toast } = this.props;

    // only save worksheetAnswers for New Applications
    if (!isStateInitialApplication) return false;

    const hasUpdates = !deepEqual(worksheetAnswers, values.worksheetAnswers);

    if (hasUpdates) {
      const wsAnswers = values.worksheetAnswers;
      await saveWorksheetAnswers({
        osp_application_answer: {
          osp_application_id: wsAnswers.ospAppId,
          worksheet_uuid: wsAnswers.worksheetId,
          answers: wsAnswers.answers,
          is_not_applicable: wsAnswers.isNotApplicable,
          ready_for_review: wsAnswers.readyForReview,
        },
      });
      await this.loadPlanApp();
    }

    await toast('success', 'Worksheet saved.');
  };

  setNoRedirect() {
    this.formik.current.setStatus({ redirectTo: false });
  }

  setRedirectOrgDashboard() {
    const { orgId } = this.props;
    const redirectTo = orgRoute(orgId);
    this.formik.current.setStatus({ redirectTo });
  }

  setRedirectToWorksheet(worksheetId) {
    const { orgId, planAppId } = this.props;
    const redirectTo = worksheetRoute(orgId, planAppId, worksheetId);
    this.formik.current.setStatus({ redirectTo });
  }

  render() {
    const {
      orgId,
      planAppId,
      worksheetId,
      worksheet,
      showAcaUI,
      changeRequest,
      changeList,
      isPlanAppLocked,
      isPlanAppClosed,
      isStateInitialApplication,
      changeRequestIsOpen,
      initialValues,
      isWorksheetNotApplicable,
    } = this.props;

    return (
      <PlanAppWorksheetAnswersLoader>
        {({ updateResource: saveWorksheetAnswers }) => (
          <PlanAppChangeLoader action="update">
            {({ updateResource: saveChanges }) => (
              <Formik
                ref={this.formik}
                initialValues={initialValues}
                onSubmit={(values, formikBag) => {
                  this.handleSubmit(values, formikBag, saveChanges, saveWorksheetAnswers);
                }}
              >
                {({ values, isSubmitting, dirty }) => {
                  return (
                    <PlanAppWorksheetForm
                      orgId={orgId}
                      planAppId={planAppId}
                      worksheetId={worksheetId}
                      worksheet={worksheet}
                      values={values}
                      onClickSubmit={this.onClickSubmit}
                      onClickSubmitNext={this.onClickSubmitNext}
                      onClickSubmitPrev={this.onClickSubmitPrev}
                      showAcaUI={showAcaUI}
                      changeRequest={changeRequest}
                      changeList={changeList}
                      isSubmitting={isSubmitting}
                      isPlanAppLocked={isPlanAppLocked}
                      isPlanAppClosed={isPlanAppClosed}
                      isStateInitialApplication={isStateInitialApplication}
                      changeRequestIsOpen={changeRequestIsOpen}
                      isWorksheetNotApplicable={isWorksheetNotApplicable}
                      isDirty={dirty}
                    />
                  );
                }}
              </Formik>
            )}
          </PlanAppChangeLoader>
        )}
      </PlanAppWorksheetAnswersLoader>
    );
  }
}

PlanAppWorksheetFormContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  nextWorksheetId: sowTypes.worksheetIdType,
  prevWorksheetId: sowTypes.worksheetIdType,
  changeList: sowTypes.planAppChangeListType,
  changeRequest: sowTypes.planAppChangeRequestType,
  showAcaUI: PropTypes.bool,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  worksheetAnswers: sowTypes.planAppWorksheetAnswersType,
  redirect: PropTypes.func.isRequired,
  isPlanAppLocked: PropTypes.bool,
  isPlanAppClosed: PropTypes.bool,
  isStateInitialApplication: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
  initialValues: PropTypes.object,
  isWorksheetNotApplicable: PropTypes.bool.isRequired,
  loadPlanApp: PropTypes.func.isRequired,
  toast: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PlanAppWorksheetFormContainer);
