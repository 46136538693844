import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import LocationWorksheet from 'sow/components/organisms/PlanAppLocationWorksheet';

const mapStateToProps = (state, props) => ({
  worksheetId: fromPlanApp.worksheetId(state, props),
  worksheet: fromPlanApp.locationWorksheet(state, props),
  questions: fromPlanApp.locationWorksheetQuestions(state, props),
});

const LocationWorksheetContainer = ({ worksheet, values, ...props }) => {
  return <LocationWorksheet {...props} worksheet={worksheet} values={values} />;
};

LocationWorksheetContainer.propTypes = {
  // Connected props
  worksheet: sowTypes.planAppWorksheetType.isRequired,

  // Passed props
  values: PropTypes.object.isRequired,
};

LocationWorksheetContainer.defaultProps = {
  worksheet: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LocationWorksheetContainer);
