import * as R from 'ramda';

import { isDeployQA } from 'sow/config';
import { acaIdPredFromSlug } from 'sow/utils/aca';
import {
  INTERCOM_APP_ID_CCOF,
  INTERCOM_APP_ID_SOWORG,
  INTERCOM_APP_ID_QA_SOWORG,
} from 'sow/constants/integrations';

export const intercomAppId = R.cond([
  [R.always(isDeployQA), R.always(INTERCOM_APP_ID_QA_SOWORG)],
  [acaIdPredFromSlug('ccof'), R.always(INTERCOM_APP_ID_CCOF)],
  [R.T, R.always(INTERCOM_APP_ID_SOWORG)],
]);

export const boot = (acaId, traits, forceSowOrgChat = false) => {
  const Intercom = window.Intercom;

  if (!Intercom) return;

  const appId = intercomAppId(forceSowOrgChat || acaId);

  // TODO remove this once backend provides aca_id and aca_name
  let extra = {};
  if (acaId === 2) {
    extra['company'] = {
      id: acaId,
      name: 'CCOF',
    };
  }
  if (acaId === 3) {
    extra['company'] = {
      id: acaId,
      name: 'Tilth',
    };
  }

  // console.log('intercom::boot', { appId, acaId, traits, extra });

  Intercom('boot', {
    app_id: appId,
    ...traits,
    ...extra,
  });
};

export const shutdown = (acaId, traits) => {
  const Intercom = window.Intercom;

  if (!Intercom) return;

  Intercom('shutdown');
};
