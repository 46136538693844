import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import * as sowTypes from 'sow/types';
import { resourceListRead, resourceUpdate } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';

import OrgDashboardActionButtonsAca from 'sow/components/organisms/OrgDashboardActionButtonsAca';

const withResourceHelpers = () => {
  const mapStateToProps = (state, props) => ({
    orgId: fromPlanApp.orgId(state, props),
    isPlanAppIdMostRecent: fromPlanApp.isPlanAppIdMostRecent(state, props),
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { orgId } = stateProps;
    const { planAppId } = ownProps;
    const baseUrl = `org/${orgId}/application/${planAppId}`;
    return {
      ...stateProps,
      ...ownProps,
      complete: resourceUpdate(`${baseUrl}/complete`, 'planAppResp'),
      markNoncompliant: resourceUpdate(`${baseUrl}/noncompliant`, 'planAppResp'),
      withdrawPlan: resourceUpdate(`${baseUrl}/withdraw`, 'planAppResp'),
      unwithdrawPlan: resourceUpdate(`${baseUrl}/unwithdraw`, 'planAppResp'),
    };
  };

  return connect(
    mapStateToProps,
    null,
    mergeProps,
  );
};

const mapStateToProps = (state, props) => {
  const { complete, markNoncompliant, withdrawPlan, unwithdrawPlan } = props;

  return {
    hasOpenPlanApp: fromPlanApp.hasOpenPlanApp(state, props),
    showAcaUI: currentUser.showAcaUI(state, props),
    showInspectorUI: currentUser.showInspectorUI(state, props),
    changeRequestCanBeApplied: fromPlanApp.changeRequestCanBeApplied(state, props),
    disabled: R.any(R.equals(true), [
      complete.selectors.pending(state, props),
      markNoncompliant.selectors.pending(state, props),
      withdrawPlan.selectors.pending(state, props),
      unwithdrawPlan.selectors.pending(state, props),
    ]),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    submitInitial,
    submitUpdate,
    complete,
    markNoncompliant,
    unlockForUpdate,
    withdrawPlan,
    unwithdrawPlan,
    orgId,
  },
) => {
  return {
    onSubmitInitial: () => dispatch(submitInitial.action()),
    onSubmitUpdate: () => dispatch(submitUpdate.action()),
    onComplete: async () => {
      // Submit the plan to be marked 'completed'. Changes will be rolled over...
      const resp = await dispatch(complete.action());

      // Load the new list of changes for this plan
      const planAppId = R.path(['data', 'detail', 'id'], resp);
      const changeRequestId = R.path(['data', 'detail', 'changeRequest', 'id'], resp);
      const loadChangesUrl = `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`;
      return dispatch(resourceListRead(loadChangesUrl, 'change').action());
    },
    onMarkNoncompliant: () => dispatch(markNoncompliant.action()),
    onUnlockForUpdate: () => dispatch(unlockForUpdate.action()),
    onWithdrawPlan: () => dispatch(withdrawPlan.action()),
    onUnwithdrawPlan: () => dispatch(unwithdrawPlan.action()),
  };
};

const OrgDashboardActionButtonsAcaContainer = props => (
  <OrgDashboardActionButtonsAca {...props} />
);

OrgDashboardActionButtonsAcaContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  disabled: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  onMarkNoncompliant: PropTypes.func.isRequired,
  onWithdrawPlan: PropTypes.func.isRequired,
};

OrgDashboardActionButtonsAcaContainer.defaultProps = {
  disabled: false,
};

export default R.compose(
  withRouter,
  withResourceHelpers(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OrgDashboardActionButtonsAcaContainer);
