import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { boot as intercomBoot } from 'sow/services/intercom';

import App from 'sow/components/App';
import ErrorBoundary from 'sow/components/ErrorBoundary';
import browserHistory from 'sow/browserHistory';
import store from 'sow/store';
import { syncHistoryScrollTo } from './utils/scrollTo';

syncHistoryScrollTo(browserHistory);

intercomBoot();

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
);

render(app, document.getElementById('app'));
