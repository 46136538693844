import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { fromRouter, fromPlanApp } from 'sow/store/selectors';
import * as currentUser from 'sow/selectors/currentUser';
import WorksheetMatrix from 'sow/components/organisms/WorksheetMatrix';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  questions: fromPlanApp.locationWorksheetQuestions(state, props),
  isPlanAppClosed: fromPlanApp.isPlanAppClosed(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  isDeletedLocation: fromPlanApp.isDeletedLocation(state, props),
});

const LocationWorksheetMatrixContainer = ({
  orgId,
  planAppId,
  worksheetId,
  locationId,
  questions,
  values,
  changeRequest,
  showAcaUI,
  isPlanAppLocked,
  isPlanAppClosed,
  changeRequestIsOpen,
  isDeletedLocation,
}) => {
  return (
    <WorksheetMatrix
      orgId={orgId}
      planAppId={planAppId}
      worksheetId={worksheetId}
      locationId={locationId}
      questions={questions}
      values={values}
      changeRequest={changeRequest}
      baseAnswersName={`answers.worksheets.${worksheetId}.answers`}
      baseAnswersChangesName={`answersChanges.worksheets.${worksheetId}.answers`}
      showAcaUI={showAcaUI}
      isPlanAppLocked={isPlanAppLocked}
      isWorksheetLocked={isPlanAppLocked}
      isPlanAppClosed={isPlanAppClosed}
      changeRequestIsOpen={changeRequestIsOpen}
      changeUIDisabled={isDeletedLocation}
    />
  );
};

LocationWorksheetMatrixContainer.propTypes = {
  // Passed props
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,

  // Connected props
  locationId: sowTypes.planAppLocationIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LocationWorksheetMatrixContainer);
