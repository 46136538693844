import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { resourceUpdate } from 'sow/store/helpers';

import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => {
  const { selectors } = getCreateChangeResource(
    props.orgId,
    props.planAppId,
    props.changeRequestId,
  );
  return {
    isCreating: selectors.pending(state, props),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    orgId,
    planAppId,
    changeRequestId,
    worksheetId,
    matrixRowId,
    locationId,
    questionId,
    field,
    form,
    changeFieldName,
  } = ownProps;
  const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  const newChange = {
    context: R.isNil(locationId) ? 'main' : 'land',
    type: R.isNil(matrixRowId) ? 'worksheet_answer' : 'matrix_row_answer',
    action: R.isNil(field.value) ? 'added' : 'updated',
    applicationId: planAppId,
    changeRequestId: changeRequestId,
    worksheetId: worksheetId,
    questionId: questionId,
    matrixRowId: matrixRowId,
    landId: locationId,
    old: field.value,
    new: field.value,
  };

  return {
    createChange: () =>
      dispatch(action(null, { changes: [newChange] })).then(() =>
        form.setFieldValue(changeFieldName, field.value),
      ),
  };
};

const PlanAppWorksheetCreateChangeButtonContainer = ({
  createChange,
  showAcaUI,
  disabled,
}) => {
  return (
    <Button
      sm
      warning={showAcaUI}
      primary={!showAcaUI}
      onClick={createChange}
      disabled={disabled}
    >
      {showAcaUI ? (
        <Fragment>
          <Glyphicon glyph="flag" /> Request Changes
        </Fragment>
      ) : (
        <Fragment>
          <Glyphicon glyph="pencil" /> Make Changes
        </Fragment>
      )}
    </Button>
  );
};

PlanAppWorksheetCreateChangeButtonContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType.isRequired,
  matrixRowId: PropTypes.string,
  locationId: sowTypes.planAppLocationIdType,
  questionId: sowTypes.planAppWorksheetQuestionIdType.isRequired,
  showAcaUI: PropTypes.bool,
  createChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

PlanAppWorksheetCreateChangeButtonContainer.defaultProps = {
  matrixRowId: null,
  locationId: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanAppWorksheetCreateChangeButtonContainer);
