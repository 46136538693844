import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Form } from 'formik';

import * as sowTypes from 'sow/types';

import Heading from 'sow/components/atoms/Heading';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';
import Spacer from 'sow/components/atoms/Spacer';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import PlanAppActionBar from 'sow/components/organisms/PlanAppActionBar';
import WorksheetBaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';
import WorksheetMatrix from 'sow/components/organisms/WorksheetMatrix';
import WorksheetRegulations from 'sow/components/organisms/WorksheetRegulations';
import WorksheetQuestionChange from 'sow/containers/planApp/WorksheetQuestionChange';
import ScrollToHashTargetOnMount from 'sow/components/atoms/ScrollToHashTargetOnMount';
import WorksheetBaseQuestionChange from 'sow/containers/planApp/WorksheetBaseQuestionChange';
import RouterWillLeaveBlock from 'sow/components/atoms/RouterWillLeaveBlock';

const noop = () => {};

const WorksheetHeader = ({ worksheet = {} }) => (
  <WidgetHeading>
    {worksheet.name || 'Worksheet'}
    {worksheet.tooltip && <InfoTooltip overlay={worksheet.tooltip} />}
  </WidgetHeading>
);

WorksheetHeader.propTypes = {
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClickSubmitNext: PropTypes.func.isRequired,
  onClickSubmitPrev: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
};

const PlanAppWorksheetForm = ({
  orgId,
  planAppId,
  worksheetId,
  worksheet,
  values,
  isPlanAppLocked,
  isPlanAppClosed,
  isStateInitialApplication,
  changeRequestIsOpen,
  onClickSubmit,
  onClickSubmitNext,
  onClickSubmitPrev,
  showAcaUI,
  changeRequest,
  changeList,
  isSubmitting,
  isWorksheetNotApplicable,
  isDirty,
}) => {
  const worksheetAnswers = R.prop('worksheetAnswers', values);
  const isWorksheetNA = R.pathOr(false, ['isNotApplicable'], worksheetAnswers);

  // Show the N/A option when the worksheet definition specifies the value may be set
  const isWorksheetLocked =
    isWorksheetNA || isPlanAppLocked || !isStateInitialApplication;

  const changeUIDisabled = isWorksheetNotApplicable;

  return (
    <Form>
      <RouterWillLeaveBlock
        blocked={isDirty}
        message="This worksheet contains unsaved changes, are you sure you would like to navigate away?"
      />

      <Widget>
        <ScrollToHashTargetOnMount />

        <WorksheetHeader
          worksheet={worksheet}
          onClickSubmit={onClickSubmit}
          onClickSubmitNext={onClickSubmitNext}
          onClickSubmitPrev={onClickSubmitPrev}
          submitDisabled={isSubmitting}
        />
        <WidgetBody>
          {worksheet.header && <InfoPanel text={worksheet.header} />}

          {isWorksheetNA && (
            <WorksheetBaseQuestionChange
              worksheetId={worksheet.uuid}
              name="isNotApplicable"
              baseAnswersName="worksheetAnswers"
              baseAnswersChangesName="worksheetAnswersChanges"
              isSubmitting={isSubmitting}
              // Never disable change UI here, so they can change the N/A value
              changeUIDisabled={false}
              showAcaUI={showAcaUI}
            />
          )}

          <WorksheetRegulations regulations={worksheet.regulations} />

          <Heading level={3} textMuted={isWorksheetLocked}>
            Worksheet Questions
          </Heading>

          {worksheet.matrix === false &&
            worksheet.questions.map(questionId => (
              <WorksheetQuestionChange
                key={questionId}
                questionId={questionId}
                disabled={isWorksheetLocked}
                changeUIDisabled={changeUIDisabled}
                baseAnswersName="worksheetAnswers.answers.values."
                baseAnswersChangesName="worksheetAnswersChanges.values."
              />
            ))}

          {worksheet.matrix === true && (
            <Fragment>
              <WorksheetMatrix
                orgId={orgId}
                planAppId={planAppId}
                worksheetId={worksheetId}
                questions={worksheet.questions}
                values={values}
                changeRequest={changeRequest}
                changeList={changeList}
                showAcaUI={showAcaUI}
                isWorksheetLocked={isWorksheetLocked}
                isPlanAppLocked={isPlanAppLocked}
                isPlanAppClosed={isPlanAppClosed}
                changeRequestIsOpen={changeRequestIsOpen}
                changeUIDisabled={changeUIDisabled}
              />
              <Spacer vertical={20} />
            </Fragment>
          )}

          {!isPlanAppLocked && (
            <WorksheetBaseQuestion
              name="worksheetAnswers.readyForReview"
              text="Are you finished with this worksheet?"
              fieldType="bool"
              orgId={orgId}
              ospId={planAppId}
              worksheetId={worksheet.uuid}
              answer={worksheetAnswers.readyForReview}
              disabled={isSubmitting || isWorksheetLocked}
            />
          )}
        </WidgetBody>

        <PlanAppActionBar
          onClickSubmit={onClickSubmit}
          onClickSubmitNext={onClickSubmitNext}
          onClickSubmitPrev={onClickSubmitPrev}
          submitDisabled={isSubmitting}
          saveable={isStateInitialApplication || changeRequestIsOpen}
        />
      </Widget>
    </Form>
  );
};

PlanAppWorksheetForm.propTypes = {
  onClickSubmit: noop,
  onClickSubmitNext: noop,
  onClickSubmitPrev: noop,
};

PlanAppWorksheetForm.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  values: PropTypes.object,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  showAcaUI: PropTypes.bool,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeList: sowTypes.planAppChangeListType,
  submitDisabled: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isPlanAppClosed: PropTypes.bool,
  isPlanAppLocked: PropTypes.bool,
  isStateInitialApplication: PropTypes.bool,
  onClickSubmit: PropTypes.func.isRequired,
  onClickSubmitNext: PropTypes.func.isRequired,
  onClickSubmitPrev: PropTypes.func.isRequired,
  isWorksheetNotApplicable: PropTypes.bool.isRequired,
};

export default PlanAppWorksheetForm;
