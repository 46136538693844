import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Image from 'sow/components/atoms/Image';

const CenteredLayout = ({ noHeader, children }) => (
  <Block className="container text-center">
    <Block className="centered-layout">
      <Block style={{ marginTop: '30px' }} />
      {!noHeader && (
        <Block className="header-logo-container">
          <Image src="/assets/images/soworganic-logo.png" />
        </Block>
      )}
      {children}
    </Block>
  </Block>
);

CenteredLayout.propTypes = {
  noHeader: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

CenteredLayout.defaultProps = {
  noHeader: false,
};

export default CenteredLayout;
