import React from 'react';
import PropTypes from 'prop-types';

import StandardLayout from 'sow/components/templates/StandardLayout';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import Button from 'sow/components/atoms/Button';
import Glyphicon from '../molecules/Glyphicon';
import Heading from '../atoms/Heading';
import InfoPanel from '../organisms/InfoPanel';
import OrgSettingsGeneralForm from 'sow/components/organisms/OrgSettingsGeneralForm';
import OrgSettingsCertifierForm from 'sow/components/organisms/OrgSettingsCertifierForm';

function OrgSetupPage({ disabled }) {
  return (
    <StandardLayout title="Organization Setup">
      <Widget>
        <WidgetHeading>Set up your Organization</WidgetHeading>
        <WidgetBody>
          <InfoPanel text="Before we can create your Plan, we need to know a bit about your Organization." />

          <Heading level={2}>General Information</Heading>
          <OrgSettingsGeneralForm disabled={disabled} />

          <Heading level={2}>Certifier</Heading>
          <OrgSettingsCertifierForm disabled={disabled} />
        </WidgetBody>
        <WidgetFooter textRight>
          <Button type="submit" primary disabled={disabled}>
            Save and Continue <Glyphicon glyph="chevron-right" />
          </Button>
        </WidgetFooter>
      </Widget>
    </StandardLayout>
  );
}

OrgSetupPage.propTypes = {
  disabled: PropTypes.bool,
};

OrgSetupPage.defaultProps = {
  disabled: false,
};

export default OrgSetupPage;
