import React from 'react';
import PropTypes from 'prop-types';

import { acaRoute } from 'sow/routes';
import { acaType } from 'sow/types';
import ListGroup from 'sow/components/molecules/ListGroup';
import ListGroupItem from 'sow/components/atoms/ListGroupItem';

const AcaLink = ({ aca }) => (
  <ListGroupItem to={acaRoute(aca.id)}>{aca.name}</ListGroupItem>
);

AcaLink.propTypes = {
  aca: acaType.isRequired,
};

const AcaList = ({ acas }) => (
  <ListGroup>
    {acas.map(aca => (
      <AcaLink key={aca.id} aca={aca} />
    ))}
  </ListGroup>
);

AcaList.propTypes = {
  acas: PropTypes.arrayOf(acaType),
};

export default AcaList;
