import * as R from 'ramda';
import RS from 'ramdasauce';

import { createSelector } from 'reselect';

import * as authSelect from 'sow/selectors/core/auth';
import * as acaSelect from 'sow/selectors/core/aca';

export const layoutState = state => state.layout;

export const isAuthenticated = createSelector(
  [authSelect.userId],
  RS.isNotNil,
);

export const isAuthenticating = createSelector(
  [authSelect.isAuthenticating],
  R.identity,
);

export const isValidating = createSelector(
  [authSelect.isValidating],
  R.identity,
);

export const id = createSelector(
  [authSelect.userId],
  R.identity,
);

export const acaIds = createSelector(
  [authSelect.acaIds],
  R.identity,
);

export const acaId = createSelector(
  [authSelect.acaId],
  R.identity,
);

export const orgId = createSelector(
  [authSelect.orgId],
  R.identity,
);

export const email = createSelector(
  [authSelect.username],
  R.identity,
);

export const name = createSelector(
  [authSelect.name],
  R.identity,
);

export const roles = createSelector(
  [authSelect.roles],
  R.defaultTo([]),
);

export const isSuperAdmin = createSelector(
  [roles],
  R.contains('admin'),
);

export const isInspector = createSelector(
  [roles],
  R.contains('inspector'),
);

export const isAcaAdmin = createSelector(
  [roles],
  R.contains('aca_admin'),
);

export const isAcaOspEditor = createSelector(
  [roles],
  R.contains('aca_osp_editor'),
);

export const isAcaOwner = createSelector(
  [roles],
  R.contains('aca_owner'),
);

export const isAcaStaff = createSelector(
  [isAcaAdmin, isAcaOspEditor, isAcaOwner],
  R.or,
);

export const isOrgAdmin = createSelector(
  [roles],
  R.contains('user'),
);

// TODO (orther) move all core logic to core selector and add base permissions selector
export const canEditOrg = createSelector(
  [isSuperAdmin, isAcaAdmin],
  R.or,
);

// TODO (orther) update OspDefEditor logic so there is a view mode instead
export const canEditOspDef = createSelector(
  [isSuperAdmin, isAcaOspEditor],
  R.or,
);

export const showAdminUI = createSelector(
  [isSuperAdmin],
  R.identity,
);

export const showAcaUI = createSelector(
  [isSuperAdmin, isAcaStaff],
  R.or,
);

export const hideAcaUI = createSelector(
  [showAcaUI],
  R.not,
);

export const showInspectorUI = isInspector;

export const showOrgUI = createSelector(
  [hideAcaUI, showInspectorUI],
  R.or,
);

export const isAdminXrayMode = createSelector(
  [layoutState],
  R.path(['adminXrayMode']),
);

export const settingsAcaFilterById = createSelector(
  [authSelect.settings],
  R.path(['aca_filter', 'id']),
);

const acaNameSort = R.sortWith([R.ascend(R.prop('name'))]);

export const acaList = createSelector(
  [acaSelect.acaList, settingsAcaFilterById],
  (acaList, acaId) => {
    const filterList = acaId ? R.filter(R.propEq('id', acaId)) : R.identity;

    return R.compose(
      acaNameSort,
      filterList,
    )(acaList);
  },
);
