import * as R from 'ramda';
import {
  ADD_SINGLE_FILE,
  ADD_TO_FILE_QUEUE,
  ADD_TO_PICTURE_QUEUE,
  REMOVE_FROM_FILE_QUEUE,
  REMOVE_ALL_FROM_FILE_QUEUE,
  REMOVE_FROM_PICTURE_QUEUE,
  UPDATE_FILE,
  UPDATE_PICTURE,
  UPDATE_SINGLE_FILE,
} from 'sow/actions/fileUpload';

const fileUploadDefaultState = {
  pictureQueue: [],
  fileQueue: [],
  singleFile: null,
};

function removeItem(queue, guid) {
  const index = queue.map(item => item.guid).indexOf(guid);

  if (index > -1) {
    queue.splice(index, 1);
  }

  return queue;
}

function updateItem(queue, guid, field, value) {
  const index = queue.map(item => item.guid).indexOf(guid);

  if (index > -1) {
    queue[index][field] = value;
  }

  return queue;
}

export default function fileUpload(state = fileUploadDefaultState, action) {
  let pictureQueue;
  let fileQueue;
  let singleFile;

  switch (action.type) {
    case ADD_TO_PICTURE_QUEUE:
      pictureQueue = state.pictureQueue.slice(0);
      pictureQueue.push(action.data);
      return { ...state, pictureQueue };

    case REMOVE_FROM_PICTURE_QUEUE:
      pictureQueue = removeItem(state.pictureQueue.slice(0), action.data);
      return { ...state, pictureQueue };

    case UPDATE_PICTURE:
      pictureQueue = updateItem(
        state.pictureQueue.slice(0),
        action.data.guid,
        action.data.field,
        action.data.value,
      );
      return { ...state, pictureQueue };

    case ADD_TO_FILE_QUEUE:
      fileQueue = state.fileQueue.slice(0);
      fileQueue.push(action.data);
      return { ...state, fileQueue };

    case REMOVE_ALL_FROM_FILE_QUEUE:
      return R.assoc('fileQueue', [], state);

    case REMOVE_FROM_FILE_QUEUE:
      fileQueue = removeItem(state.fileQueue.slice(0), action.data);
      return { ...state, fileQueue };

    case UPDATE_FILE:
      fileQueue = updateItem(
        state.fileQueue.slice(0),
        action.data.guid,
        action.data.field,
        action.data.value,
      );
      return { ...state, fileQueue };

    case ADD_SINGLE_FILE:
      return { ...state, singleFile: action.data };

    case UPDATE_SINGLE_FILE:
      singleFile = {
        ...state.singleFile,
        [action.data.field]: action.data.value,
      };
      console.log('Updated file:', singleFile);
      return { ...state, singleFile };

    default:
      return state;
  }
}

export function getFileUploadState(state) {
  return state.fileUpload;
}
