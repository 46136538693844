import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as sowTypes from 'sow/types';
import ExportContentWorksheetMatrixRow from 'sow/containers/planAppExport/Content/WorksheetMatrix';
import ExportContentWorksheetQuestion from 'sow/containers/planAppExport/Content/WorksheetQuestion';

import {
  Box,
  SubSectionHeading,
  WorksheetStandardTable,
  WorksheetMatrixTable,
} from './style/atoms';

export const WorksheetHeader = styled(Box).attrs({ as: 'p' })`
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 0;
`;

export const WorksheetIsNotApplicable = () => (
  <WorksheetMatrixTable>
    <tbody>
      <tr>
        <td className="empty">Not applicable</td>
      </tr>
    </tbody>
  </WorksheetMatrixTable>
);

const Worksheet = ({ isNotApplicable, ...props }) => {
  const { worksheet } = props;
  if (!worksheet) return null;

  return (
    <Fragment>
      <SubSectionHeading pageBreak>{worksheet.name}</SubSectionHeading>
      <WorksheetHeader>{worksheet.header}</WorksheetHeader>
      {isNotApplicable ? (
        <WorksheetIsNotApplicable />
      ) : worksheet.matrix ? (
        <ExportContentWorksheetMatrixRow {...props} />
      ) : (
        <WorksheetStandardTable>
          <tbody>
            {worksheet.questions.map(questionId => (
              <ExportContentWorksheetQuestion
                key={questionId}
                {...props}
                questionId={questionId}
              />
            ))}
          </tbody>
        </WorksheetStandardTable>
      )}
    </Fragment>
  );
};

Worksheet.propTypes = {
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  isNotApplicable: PropTypes.bool,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
};

export default Worksheet;
