import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resourceCreate } from 'sow/store/helpers';
import { orgIdType } from 'sow/types';

import InspectorAssignForm from 'sow/components/organisms/InspectorAssignForm';

const assignInspectorResource = orgId =>
  resourceCreate(`org/${orgId}/inspector_access`, 'inspectorAccess');

const mapDispatchToProps = {
  assignAccess: (orgId, inspectorId) =>
    assignInspectorResource(orgId).action({
      inspector_access: {
        inspector_id: inspectorId,
        complete: false,
      },
    }),
};

class InspectorAssignFormContainer extends Component {
  static propTypes = {
    orgId: orgIdType.isRequired,
    inspectorList: PropTypes.array.isRequired,
    assignAccess: PropTypes.func.isRequired,
  };

  handleSubmit = (values, formActions) => {
    const { orgId, assignAccess } = this.props;
    const inspectorId = parseInt(values.inspectorId);

    formActions.setSubmitting(true);

    assignAccess(orgId, inspectorId);
    // .then(() => {
    // Todo: attach this to the assignAccess thunk once it's refactored to new resource request
    formActions.setSubmitting(false);
    formActions.setValues({
      inspectorId: '',
    });
    // });
  };

  render() {
    const { inspectorList } = this.props;

    return (
      <InspectorAssignForm inspectorList={inspectorList} onSubmit={this.handleSubmit} />
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(InspectorAssignFormContainer);
