import * as R from 'ramda';
import { v4 as guid } from 'uuid';

import { moveListItemAway, moveListItemCloser } from 'sow/utils/data';

import { INIT_STATE } from './OspDefEditor';
import {
  OSP_SECTION_ADD,
  OSP_SECTION_REMOVE,
  OSP_SECTION_MOVE_DOWN,
  OSP_SECTION_MOVE_UP,
  // createSection,
} from './ospDef';

// Actions
export const WORKSHEET_ADD = 'OspDefEditor/ospSections/WORKSHEET_ADD';
export const WORKSHEET_MOVE_DOWN = 'OspDefEditor/ospSections/WORKSHEET_MOVE_DOWN';
export const WORKSHEET_MOVE_UP = 'OspDefEditor/ospSections/WORKSHEET_MOVE_UP';
export const WORKSHEET_REMOVE = 'OspDefEditor/ospSections/WORKSHEET_REMOVE';
export const SET_VALUE = 'OspDefEditor/ospSections/SET_VALUE';

export const SCOPE_WORKSHEETS_ADD = 'OspDefEditor/OSP_SECTION/SCOPE_WORKSHEETS_ADD';
export const SCOPE_WORKSHEETS_REMOVE = 'OspDefEditor/OSP_SECTION/SCOPE_WORKSHEETS_REMOVE';
export const SCOPE_WORKSHEETS_EDIT_DONE =
  'OspDefEditor!/OSP_SECTION/SCOPE_WORKSHEETS_EDIT_DONE';
export const SCOPE_WORKSHEETS_EDIT_START =
  'OspDefEditor!/OSP_SECTION/SCOPE_WORKSHEETS_EDIT_START';

// Reducer functions
export const reduceOspSectionAdd = (state = {}, action = {}) => {
  const { ospSection } = action.data;
  const ospSectionId = ospSection.uuid;
  return R.assoc(ospSectionId, ospSection, state);
};

export const reduceOspSectionRemove = (state = {}, action = {}) => {
  const { ospSectionId } = action.data;
  return R.dissoc(ospSectionId, state);
};

export const reduceSetValue = (state = {}, action = {}) => {
  const { ospSectionId, field, value } = action.data;
  return R.assocPath([ospSectionId, field], value, state);
};

export const reduceWorksheetAdd = (state = {}, action = {}) => {
  const { ospSectionId, worksheet } = action.data;
  const worksheetId = worksheet.uuid;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  return R.over(lens, R.append(worksheetId), state);
};

export const reduceWorksheetMoveDown = (state = {}, action = {}) => {
  const { worksheetId, ospSectionId } = action.data;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(worksheetId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceWorksheetMoveUp = (state = {}, action = {}) => {
  const { worksheetId, ospSectionId } = action.data;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(worksheetId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceWorksheetRemove = (state = {}, action = {}) => {
  const { ospSectionId, worksheetId } = action.data;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  return R.over(lens, R.without([worksheetId]), state);
};

export const reduceScopeWorksheetsEditStart = (state = {}, action = {}) => {
  const { ospSectionId, worksheetId } = action.data;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  return R.over(lens, R.without([worksheetId]), state);
};

export const reduceScopeWorksheetsEditDone = (state = {}, action = {}) => {
  const { ospSectionId, worksheetId } = action.data;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  return R.over(lens, R.without([worksheetId]), state);
};

export const reduceScopeWorksheetsAdd = (state = {}, action = {}) => {
  const { ospSectionId, scope, worksheetId } = action.data;
  const lens = R.lensPath([ospSectionId, 'scopeWorksheets']);
  return R.over(lens, R.without([worksheetId]), state);
};

export const reduceScopeWorksheetsRemove = (state = {}, action = {}) => {
  const { ospSectionId, scope, worksheetId } = action.data;
  const lens = R.lensPath([ospSectionId, 'worksheets']);
  return R.over(lens, R.without([worksheetId]), state);
};

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case INIT_STATE:
      return R.path(['payload', 'ospSections'], action);

    case OSP_SECTION_ADD:
      return reduceOspSectionAdd(state, action);
    // case OSP_SECTION_MOVE_DOWN:
    //   return reduceOspSectionMoveDown(state, action);

    // case OSP_SECTION_MOVE_UP:
    //   return reduceOspSectionMoveUp(state, action);

    case OSP_SECTION_REMOVE:
      return reduceOspSectionRemove(state, action);

    case SET_VALUE:
      return reduceSetValue(state, action);
    // WORKSHEET
    case WORKSHEET_ADD:
      return reduceWorksheetAdd(state, action);

    case WORKSHEET_MOVE_DOWN:
      return reduceWorksheetMoveDown(state, action);

    case WORKSHEET_MOVE_UP:
      return reduceWorksheetMoveUp(state, action);

    case WORKSHEET_REMOVE:
      return reduceWorksheetRemove(state, action);
    // SCOPE WORKSHEETS
    case SCOPE_WORKSHEETS_ADD: {
      const { ospSectionId, scope, worksheetId } = action.data;
      console.log(
        'SCOPE_WORKSHEETS_ADD',
        { ospSectionId, scope, worksheetId },
        { state, action },
      );
      const lens = R.lensPath([ospSectionId, 'scopeWorksheets', scope]);
      return R.over(lens, R.union([worksheetId]), state);
    }

    case SCOPE_WORKSHEETS_REMOVE: {
      const { ospSectionId, scope, worksheetId } = action.data;
      console.log('SCOPE_WORKSHEETS_REMOVE', { state, action });
      const lens = R.lensPath([ospSectionId, 'scopeWorksheets', scope]);
      return R.over(lens, R.without([worksheetId]), state);
    }

    case SCOPE_WORKSHEETS_EDIT_DONE: {
      return R.dissoc('scopeWorksheetsEdit', state);
    }

    case SCOPE_WORKSHEETS_EDIT_START: {
      const { ospSectionId } = action.data;
      return R.assoc('scopeWorksheetsEdit', ospSectionId, state);
    }

    default:
      return state;
  }
}

// Action Creators
export function worksheetAdd(ospSectionId, worksheet) {
  return { type: WORKSHEET_ADD, data: { ospSectionId, worksheet } };
}

export function worksheetMoveDown(ospSectionId, worksheetId) {
  return { type: WORKSHEET_MOVE_DOWN, data: { ospSectionId, worksheetId } };
}

export function worksheetMoveUp(ospSectionId, worksheetId) {
  return { type: WORKSHEET_MOVE_UP, data: { ospSectionId, worksheetId } };
}

export function worksheetRemove(ospSectionId, worksheetId) {
  return { type: WORKSHEET_REMOVE, data: { ospSectionId, worksheetId } };
}

export function setValue(ospSectionId, field, value) {
  return { type: SET_VALUE, data: { ospSectionId, field, value } };
}

export function scopeWorksheetsEditStart(ospSectionId) {
  return { type: SCOPE_WORKSHEETS_EDIT_START, data: { ospSectionId } };
}

export function scopeWorksheetsEditDone() {
  return { type: SCOPE_WORKSHEETS_EDIT_DONE };
}

export function scopeWorksheetsAdd(ospSectionId, scope, worksheetId) {
  return {
    type: SCOPE_WORKSHEETS_ADD,
    data: {
      ospSectionId,
      scope,
      worksheetId,
    },
  };
}

export function scopeWorksheetsRemove(ospSectionId, scope, worksheetId) {
  return {
    type: SCOPE_WORKSHEETS_REMOVE,
    data: {
      ospSectionId,
      scope,
      worksheetId,
    },
  };
}

// Action Creator Export Map
export const actions = {
  setValue,

  // worksheet
  worksheetAdd,
  worksheetMoveDown,
  worksheetMoveUp,
  worksheetRemove,

  // scope worksheets
  scopeWorksheetsEditDone,
  scopeWorksheetsEditStart,
  scopeWorksheetsAdd,
  scopeWorksheetsRemove,
};
