import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export function fetchAcaAsync(id) {
  return apiRequest({
    type: 'GET',
    url: `${API_URL}/v1/aca/${id}`,
  });
}

export function fetchAcaListAsync() {
  return apiRequest({
    type: 'GET',
    url: `${API_URL}/v1/aca/register`,
  });
}

export const fetchActivityLogAsync = (acaId, filters) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/activity_log`,
    data: filters,
  });
