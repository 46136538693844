import React from 'react';
import PropTypes from 'prop-types';
import ReactHelmet from 'react-helmet';

const Helmet = ({ title }) => {
  const titleTemplate = title === 'Sow Organic' ? 'Sow Organic' : '%s | Sow Organic';
  return <ReactHelmet title={title} titleTemplate={titleTemplate} />;
};

Helmet.propTypes = {
  title: PropTypes.string,
};

Helmet.defaultProps = {
  title: 'Sow Organic',
};

export default Helmet;
