import React from 'react';
import * as R from 'ramda';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import {
  adminRoute,
  acaRoute,
  inspectorRoute,
  errorForbiddenRoute,
  orgRoute,
  setupOrgRoute,
} from 'sow/routes';

const mapStateToProps = (state, props) => ({
  isAuthenticated: currentUser.isAuthenticated(state, props),
  isSuperAdmin: currentUser.isSuperAdmin(state, props),
  isInspector: currentUser.isInspector(state, props),
  isAcaAdmin: currentUser.isAcaAdmin(state, props),
  isOrgAdmin: currentUser.isOrgAdmin(state, props),
  acaId: currentUser.acaId(state, props),
  orgId: currentUser.orgId(state, props),
});

const DashboardRedirectContainer = ({
  isSuperAdmin,
  isInspector,
  isAcaAdmin,
  isOrgAdmin,
  acaId,
  orgId,
}) => {
  if (isSuperAdmin) return <Redirect to={adminRoute()} />;
  if (isInspector) return <Redirect to={inspectorRoute()} />;
  if (isAcaAdmin) return <Redirect to={acaRoute(acaId)} />;
  if (isOrgAdmin) {
    if (!R.isNil(orgId)) return <Redirect to={orgRoute(orgId)} />;
    return <Redirect to={setupOrgRoute()} />;
  }
  return <Redirect to={errorForbiddenRoute()} />;
};

DashboardRedirectContainer.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
  isAcaAdmin: PropTypes.bool.isRequired,
  isOrgAdmin: PropTypes.bool.isRequired,
  acaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DashboardRedirectContainer.defaultProps = {
  acaId: null,
  orgId: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(DashboardRedirectContainer);
