import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import ListItem from 'sow/components/atoms/ListItem';

function joinClassnames(...classnames) {
  return classnames.filter(i => i).join(' ');
}

const NavLink = ({
  'aria-current': ariaCurrent,
  activeClassName,
  activeStyle,
  className: classNameProp,
  exact,
  isActive: isActiveProp,
  location,
  strict,
  style: styleProp,
  to,
  children,
  ...rest
}) => (
  <Route
    path={typeof to === 'object' ? to.pathname : to}
    exact={exact}
    strict={strict}
    location={location}
  >
    {({ location, match }) => {
      const isActive = !!(isActiveProp ? isActiveProp(match, location) : match);

      const className = isActive
        ? joinClassnames(classNameProp, activeClassName)
        : classNameProp;
      const style = isActive ? { ...styleProp, ...activeStyle } : styleProp;

      return (
        <ListItem className={className}>
          <Link
            aria-current={(isActive && ariaCurrent) || null}
            className={className}
            style={style}
            to={to}
            {...rest}
          >
            {children}
          </Link>
        </ListItem>
      );
    }}
  </Route>
);

NavLink.defaultProps = {
  'aria-current': 'page',
  activeClassName: 'active',
};

const ariaCurrentType = PropTypes.oneOf([
  'page',
  'step',
  'location',
  'date',
  'time',
  'true',
]);

NavLink.propTypes = {
  'aria-current': ariaCurrentType,
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  className: PropTypes.string,
  exact: PropTypes.bool,
  isActive: PropTypes.func,
  location: PropTypes.object,
  strict: PropTypes.bool,
  style: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
};

export default NavLink;
