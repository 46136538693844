import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';

const OrgInfoSection = ({ userList, org }) => {
  const orgPath = path => R.path(path, org);
  const name = orgPath(['name']);
  const contactName = orgPath(['contact_name']);
  const contactPhone = orgPath(['contact_phone']);

  const address = orgPath(['location', 'address']);
  const addressText = orgPath(['location', 'address_text']);
  const addressCity = orgPath(['location', 'city']);
  const addressState = orgPath(['location', 'state']);
  const addressPostal = orgPath(['location', 'postal']);
  const addressCountry = orgPath(['location', 'country']);

  return (
    <div>
      <h2 className="">Organization Information</h2>
      <h3>Users</h3>
      <table className="">
        <tbody>
          {userList &&
            userList.map(user => (
              <tr key={user.id}>
                <td>
                  {user.name} ({user.email})
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <h3>General</h3>
      <table className="single">
        <tbody>
          <tr>
            <td>Organization name</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>Primary contact name</td>
            <td>{contactName}</td>
          </tr>
          <tr>
            <td>Primary contact phone</td>
            <td>{contactPhone}</td>
          </tr>
        </tbody>
      </table>

      <h3>Address</h3>
      <table className="single">
        <tbody>
          {!address ? (
            <tr>
              <td>Street Address</td>
              <td>{addressText}</td>
            </tr>
          ) : (
            <React.Fragment>
              <tr>
                <td>Street Address</td>
                <td>{address}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{addressCity}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{addressState}</td>
              </tr>
              <tr>
                <td>Postal</td>
                <td>{addressPostal}</td>
              </tr>
            </React.Fragment>
          )}
          <tr>
            <td>Country</td>
            <td>{addressCountry}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

OrgInfoSection.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  org: sowTypes.orgType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
  showMainPlan: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
  addNotes: PropTypes.bool.isRequired,
  addFiles: PropTypes.bool.isRequired,
  userList: sowTypes.userLimitedListType,
};

export default OrgInfoSection;
