import React, { Fragment } from 'react';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import { PLAN_APP_STATES_STATE_EDITABLE } from 'sow/constants/planApp';
import Block from 'sow/components/atoms/Block';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Button from 'sow/components/atoms/Button';
import Heading from 'sow/components/atoms/Heading';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import PlanAppEditStateModal from 'sow/containers/planApp/EditStateModal';
import OrgDashboardActionButtonsAca from 'sow/containers/planApp/OrgDashboardActionButtonsAca';

const OrgDashboardAcaOptions = ({ orgId, planAppId, planApp }) => {
  const stateCanBeUpdated = R.contains(R.__, PLAN_APP_STATES_STATE_EDITABLE)(
    planApp.state,
  );

  return (
    <Block>
      <Block textCenter>
        <Heading level={3}>Certifier Options</Heading>
      </Block>

      <Widget>
        <WidgetBody textCenter>
          {stateCanBeUpdated && (
            <Fragment>
              <PlanAppEditStateModal
                orgId={orgId}
                planAppId={planAppId}
                appState={planApp.state}
              >
                {showModal => (
                  <Button primary block onClick={showModal}>
                    Update Plan State
                  </Button>
                )}
              </PlanAppEditStateModal>
              <HorizontalRule style={{ margin: '5px auto 5px auto' }} />
            </Fragment>
          )}

          <OrgDashboardActionButtonsAca planAppId={planAppId} planApp={planApp} />
        </WidgetBody>
      </Widget>
    </Block>
  );
};

OrgDashboardAcaOptions.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
};

export default OrgDashboardAcaOptions;
