import React from 'react';

import FormGroup from 'sow/components/organisms/FormGroup';

const RegistrationForm = ({ user, handleChange, errors }) => (
  <div>
    <FormGroup fieldName="name" errors={errors} label="Your name">
      <input
        type="text"
        id="name"
        value={user.name}
        onChange={e => handleChange('name', e.target.value)}
        className="form-control"
      />
    </FormGroup>

    <FormGroup fieldName="email" errors={errors} label="Your email address">
      <input
        type="text"
        id="email"
        value={user.email}
        onChange={e => handleChange('email', e.target.value)}
        className="form-control"
      />
    </FormGroup>

    <div className="row">
      <div className="col-md-6">
        <FormGroup fieldName="password" errors={errors} label="Create a password">
          <input
            type="password"
            id="password"
            value={user.password}
            onChange={e => handleChange('password', e.target.value)}
            className="form-control"
          />
        </FormGroup>
      </div>

      <div className="col-md-6">
        <FormGroup
          fieldName="password_confirmation"
          errors={errors}
          label="Confirm password"
        >
          <input
            type="password"
            id="password_confirmation"
            value={user.password_confirmation}
            onChange={e => handleChange('password_confirmation', e.target.value)}
            className="form-control"
          />
        </FormGroup>
      </div>
    </div>
  </div>
);

export default RegistrationForm;
