import React from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import {
  isStateOpen,
  isStateSubmitted,
  isStateWithdrawn,
  isUnderReview,
} from 'sow/utils/planAppState';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Tooltip from 'sow/components/molecules/Tooltip';
import Confirm from 'sow/components/molecules/Confirm';

const OrgDashboardActionButtonsAca = ({
  planApp,
  disabled,
  showAcaUI,
  showInspectorUI,
  isPlanAppIdMostRecent,
  changeRequestCanBeApplied,
  onComplete,
  onMarkNoncompliant,
  onWithdrawPlan,
  onUnwithdrawPlan,
}) => {
  const showWithdrawButton = showAcaUI && isPlanAppIdMostRecent && isStateOpen(planApp);

  const showUnwithdrawButton =
    showAcaUI && isPlanAppIdMostRecent && isStateWithdrawn(planApp);

  const showMarkNoncompliantButton =
    (showAcaUI || showInspectorUI) &&
    isPlanAppIdMostRecent &&
    (isStateSubmitted(planApp) || isUnderReview(planApp));

  const showMarkCompleteButton = (showAcaUI || showInspectorUI) && isUnderReview(planApp);
  const disableMarkCompleteButton = disabled || !changeRequestCanBeApplied;

  const showSeparator = showMarkNoncompliantButton || showMarkCompleteButton;

  return (
    <Block>
      {showMarkCompleteButton && (
        <Confirm
          onConfirm={onComplete}
          message="All changes marked accepted will be applied to this application and overwrite their corresponding old answer, and the application will be marked complete. If there are any changes that have not been accepted or discarded, a new plan will be created with those pending changes."
        >
          {toggleConfirm => {
            const button = (
              <Button
                success
                block
                onClick={disableMarkCompleteButton ? () => {} : toggleConfirm}
                className={disableMarkCompleteButton ? 'disabled' : null}
              >
                Mark Plan Complete
              </Button>
            );

            if (disableMarkCompleteButton) {
              return (
                <Tooltip overlay="Before a plan can be completed, a change request must exist and the plan must be in the Final Review state.">
                  <Block>{button}</Block>
                </Tooltip>
              );
            }
            return button;
          }}
        </Confirm>
      )}

      {showSeparator && (
        <HorizontalRule style={{ marginTop: '5px', marginBottom: '5px' }} />
      )}

      {showWithdrawButton && (
        <Confirm
          message="This plan will be marked as Withdrawn. The organization will be shown a message to contact their certifier if they wish to unlock and update the plan for resubmission."
          onConfirm={onWithdrawPlan}
        >
          {toggleConfirm => (
            <Button danger block onClick={toggleConfirm} disabled={disabled}>
              Withdraw Plan
            </Button>
          )}
        </Confirm>
      )}

      {showUnwithdrawButton && (
        <Button warning block onClick={onUnwithdrawPlan} disabled={disabled}>
          Unwithdraw Plan
        </Button>
      )}

      {showMarkNoncompliantButton && (
        <Confirm
          message="This plan will be marked as Non-compliance. The organization will have the option to unlock the plan for update and make changes to bring the plan into compliance."
          onConfirm={onMarkNoncompliant}
        >
          {toggleConfirm => (
            <Button danger block onClick={toggleConfirm} disabled={disabled}>
              Mark Plan Noncompliant
            </Button>
          )}
        </Confirm>
      )}
    </Block>
  );
};

OrgDashboardActionButtonsAca.propTypes = {
  planApp: sowTypes.planAppType.isRequired,
  changeRequestCanBeApplied: PropTypes.bool.isRequired,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  onMarkNoncompliant: PropTypes.func.isRequired,
  onWithdrawPlan: PropTypes.func.isRequired,
  onUnwithdrawPlan: PropTypes.func.isRequired,
};

export default OrgDashboardActionButtonsAca;
