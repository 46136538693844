import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Form from 'sow/components/atoms/Form';

import StaffForm from 'sow/components/organisms/AcaStaffForm';
import InviteUserForm from 'sow/components/organisms/InviteUserForm';

const AcaAddStaffModal = ({
  showModal,
  showInviteFields,
  onShow,
  onHide,
  user,
  onChange,
  onSubmit,
  isAddingStaff,
  addStaffErrors,
}) =>
  <Block>
    <Button success onClick={onShow}>
      Add Staff
    </Button>

    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add administrator</Modal.Title>
      </Modal.Header>

      <Form onSubmit={onSubmit}>
        <Modal.Body>
          {showInviteFields
            ? <InviteUserForm user={user} onChange={onChange} errors={addStaffErrors} />
            : <StaffForm user={user} onChange={onChange} errors={addStaffErrors} />}
        </Modal.Body>

        <Modal.Footer>
          <Button default disabled={isAddingStaff} onClick={onHide}>
            Cancel
          </Button>

          <Button type="submit" primary disabled={isAddingStaff}>
            {showInviteFields ? 'Invite' : 'Add'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  </Block>;

AcaAddStaffModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showInviteFields: PropTypes.bool,
  isAddingStaff: PropTypes.bool.isRequired,
  addStaffErrors: PropTypes.object,
};

export default AcaAddStaffModal;
