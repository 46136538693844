import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal';
import { Formik } from 'formik';
import { push as routerPush } from 'connected-react-router';

import actions from 'sow/actions/pure';
import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate, resourceDetailRead } from 'sow/store/helpers';
import LocationWorksheetsForm from 'sow/components/organisms/PlanAppLocationWorksheetsForm';
import { locationOverviewRoute } from 'sow/routes';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  worksheets: fromPlanApp.locationQualifiedWorksheets(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  initialFormValues: fromPlanApp.locationFormInitialValues(state, props),
  locationAnswersChangesMap: fromPlanApp.locationAnswersChangesMap(state, props),
  isDeleted: fromPlanApp.isDeletedLocation(state, props),
});

const mapDispatchToProps = { routerPush };

function getAnswerValue(change, formValues) {
  const { worksheetId, questionId } = change;
  return R.path(['worksheets', worksheetId, 'answers', 'values', questionId], formValues);
}

function getMatrixAnswerValue(change, formValues) {
  const { worksheetId, matrixRowId, questionId } = change;
  return R.path(
    [
      'worksheets',
      worksheetId,
      'answers',
      'matrixRows',
      matrixRowId,
      'values',
      questionId,
    ],
    formValues,
  );
}

function areAnswersEqual(answer1, answer2) {
  // Sometimes the answers will be null AND undefined, which fails deepEqual
  if (R.isNil(answer1) && R.isNil(answer2)) {
    return true;
  }
  return deepEqual(answer1, answer2);
}

/** Returns a list of changes whose 'new' value has been updated by the form */
function buildUpdatedChangeList(changeMap, formValues) {
  let updatedChangeList = [];
  R.values(changeMap).forEach(change => {
    const originalNewAnswer = R.prop('new', change);
    const isMatrixRowChange = !R.isNil(change.matrixRowId);
    const formValue = isMatrixRowChange
      ? getMatrixAnswerValue(change, formValues)
      : getAnswerValue(change, formValues);

    if (!areAnswersEqual(originalNewAnswer, formValue)) {
      updatedChangeList.push(R.assoc('new', formValue, change));
    }
  });

  return updatedChangeList;
}

const mapResourceToProps = () => {
  // Update worksheets resource
  const getUpdateAnswersResource = (orgId, planAppId, locationId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/land/${locationId}/answers`,
      'locationAnswers',
    );

  const getUpdateChangesResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const {
      orgId,
      planAppId,
      changeRequestId,
      locationId,
      locationAnswersChangesMap,
      isStateInitialApplication,
    } = ownProps;

    const redirect = route => ownProps.routerPush(route);

    const toastSuccessAction = message => actions.shell.toast('success', message);

    const updateAnswersResource = getUpdateAnswersResource(orgId, planAppId, locationId);
    const updateChangesResource = getUpdateChangesResource(
      orgId,
      planAppId,
      changeRequestId,
    );
    const loadPlanAppResource = resourceDetailRead(
      `org/${orgId}/application/${planAppId}`,
      'planAppResp',
    );

    async function updateAnswers(formValues) {
      await dispatch(
        updateAnswersResource.action(null, {
          productAnswers: formValues,
        }),
      );
      return dispatch(loadPlanAppResource.action());
    }

    async function updateChanges(formValues) {
      const changes = buildUpdatedChangeList(
        locationAnswersChangesMap,
        formValues.answersChanges,
      );

      if (!changes.length) return; // change list length must be > 0
      return dispatch(updateChangesResource.action(null, { changes }));
    }

    return {
      redirect,
      onSubmit: async formValues => {
        const action = isStateInitialApplication ? updateAnswers : updateChanges;
        await action(formValues);
        return dispatch(toastSuccessAction('Location answers saved.'));
      },
    };
  };

  return connect(
    null,
    mapResourceDispatchToProps,
  );
};

class LocationWorksheetsFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
  }

  handleSubmitRedirect = event => {
    const { orgId, planAppId } = this.props;

    event.preventDefault();
    this.formik.current.setStatus({
      redirectTo: locationOverviewRoute(orgId, planAppId),
    });
    this.formik.current.submitForm();
  };

  handleSubmitStay = event => {
    event.preventDefault();
    this.formik.current.submitForm();
  };

  handleSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    try {
      await this.props.onSubmit(values);
      const redirectTo = R.path(['state', 'status', 'redirectTo'], this.formik.current);
      formikBag.resetForm(this.props.initialFormValues);
      formikBag.setSubmitting(false);
      if (redirectTo) {
        this.props.redirect(redirectTo);
      }
    } catch (err) {
      console.error('Error saving form:', err);
      formikBag.setSubmitting(false);
    }
  };

  render() {
    const {
      orgId,
      planAppId,
      isPlanAppLocked,
      isStateInitialApplication,
      changeRequestIsOpen,
      initialFormValues,
      worksheets,
      isDeleted,
    } = this.props;
    return (
      <Formik
        ref={this.formik}
        onSubmit={this.handleSubmit}
        initialValues={initialFormValues}
      >
        {({ values, isSubmitting }) => (
          <LocationWorksheetsForm
            orgId={orgId}
            planAppId={planAppId}
            worksheets={worksheets}
            values={values}
            isSubmitting={isSubmitting}
            onSubmit={this.handleSubmitStay}
            onSubmitRedirect={this.handleSubmitRedirect}
            isPlanAppLocked={isPlanAppLocked}
            isStateInitialApplication={isStateInitialApplication}
            changeRequestIsOpen={changeRequestIsOpen}
            isDeleted={isDeleted}
          />
        )}
      </Formik>
    );
  }
}

LocationWorksheetsFormContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheets: PropTypes.arrayOf(sowTypes.planAppWorksheetType).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    answers: PropTypes.object,
    answersChanges: PropTypes.object,
  }).isRequired,
  isPlanAppLocked: PropTypes.bool.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  isDeleted: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  mapResourceToProps(),
)(LocationWorksheetsFormContainer);
