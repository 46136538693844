import React from 'react';
import PropTypes from 'prop-types';

import { orgRoute } from 'sow/routes';
import StandardLayout from 'sow/components/templates/StandardLayout';
import Panel from 'sow/components/molecules/Panel';
import ListGroup from 'sow/components/molecules/ListGroup';
import ListGroupItem from 'sow/components/atoms/ListGroupItem';
import Spinner from 'sow/components/atoms/Spinner';

const InspectorDashboardPage = ({ orgListFailed, orgListLoading, orgList }) => (
  <StandardLayout title="Inspector Dashboard">
    <Panel title="Organizations">
      {orgListLoading ? (
        <Spinner />
      ) : orgListFailed ? (
        'Something went wrong while fetching inspectors. Please, try again later.'
      ) : orgList.length < 1 ? (
        'No organizations have been assigned.'
      ) : (
        <ListGroup linked>
          {orgList.map(org => (
            <ListGroupItem key={org.id} to={orgRoute(org.id)}>
              {org.name}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </Panel>
  </StandardLayout>
);

InspectorDashboardPage.propTypes = {
  orgListFailed: PropTypes.bool,
  orgListLoading: PropTypes.bool,
  orgList: PropTypes.array,
};

InspectorDashboardPage.defaultProps = {
  orgList: [],
};

export default InspectorDashboardPage;
