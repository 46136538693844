import React, { Component } from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import AttachmentForm from './AttachmentForm';
import { updateAttachment } from 'sow/actions/attachment';

const mapDispatchToProps = { updateAttachment };

class EditAttachmentModal extends Component {
  state = {};

  componentWillMount() {
    const { attachment } = this.props;

    this.setState({
      attachment: { ...attachment },
    });
  }

  hideModal = () => {
    this.componentWillMount();
    this.props.onHide();
  };

  handleChange = (field, value) => {
    if (field === 'public') {
      return this.setState(R.assocPath(['attachment', 'file', field], value));
    }

    return this.setState(R.assocPath(['attachment', field], value));
  };

  handleSubmit = event => {
    event.preventDefault();

    this.props.updateAttachment(this.props.orgId, this.state.attachment, this.hideModal);
  };

  render() {
    const { show, isAdmin } = this.props;
    const { attachment } = this.state;

    return (
      <Modal show={show} onHide={this.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit attachment</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form id="editAttachmentForm" onSubmit={this.handleSubmit}>
            <AttachmentForm
              attachment={attachment}
              isAdmin={isAdmin}
              handleChange={this.handleChange}
            />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button type="submit" form="editAttachmentForm" className="btn btn-primary">
            Done
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EditAttachmentModal);
