import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Small from 'sow/components/atoms/Small';
import Spacer from 'sow/components/atoms/Spacer';
import Heading from 'sow/components/atoms/Heading';
import PlanAppLocationList from 'sow/components/organisms/PlanAppLocationList';
import LocationCreateModal from 'sow/containers/planApp/LocationCreateModal';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';

const OrgPlanAppLocationOverviewPage = ({
  orgId,
  planAppId,
  locationIdList,
  minNumLocations,
}) => (
  <Fragment>
    <ChangeRequestManager title="Changes for plan locations" showLocations />

    <Spacer vertical={20} />

    <Block pullRight>
      <LocationCreateModal />
    </Block>

    <Heading level={2}>
      Locations{' '}
      {minNumLocations > 0 && <Small>{minNumLocations} locations required</Small>}
    </Heading>

    <PlanAppLocationList
      orgId={orgId}
      planAppId={planAppId}
      locationIdList={locationIdList}
    />
  </Fragment>
);

OrgPlanAppLocationOverviewPage.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  locationIdList: PropTypes.arrayOf(sowTypes.planAppLocationIdType).isRequired,
  minNumLocations: PropTypes.number,
};

export default OrgPlanAppLocationOverviewPage;
