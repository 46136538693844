import React, { Fragment } from 'react';

import * as sowTypes from 'sow/types';
import Spacer from 'sow/components/atoms/Spacer';
import PlanAppWorksheetForm from 'sow/containers/planApp/WorksheetForm';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';

const OrgPlanAppWorksheetPage = () => (
  <Fragment>
    <ChangeRequestManager title="Changes for this worksheet" showPlanWorksheets />
    <Spacer vertical={20} />

    <PlanAppWorksheetForm />
  </Fragment>
);

OrgPlanAppWorksheetPage.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
};

export default OrgPlanAppWorksheetPage;
