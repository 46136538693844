import { all, call, select, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';

import * as IntercomService from 'sow/services/intercom';
import * as currentUser from 'sow/selectors/currentUser';
import actions from 'sow/actions/pure';
import { acaSlugToId } from 'sow/utils/aca';
import { isDeployAlpha, isDeployBeta, isDeployProd } from 'sow/config';

export function* watchLoginDone({ payload }) {
  const userId = R.path(['user_id'], payload);
  const roles = R.path(['roles'], payload);
  const acaIds = R.path(['metadata', 'acas'], payload);
  const orgIds = R.path(['metadata', 'orgs'], payload);
  const orgAcaIds = R.pathOr([], ['metadata', 'orgs_v2'], payload);
  const email = R.path(['metadata', 'username'], payload);
  const name = R.path(['metadata', 'name'], payload);

  const traits = {
    userId,
    acaIds,
    orgIds,
    orgAcaIds,
    roles,
    email,
    name,
  };

  yield all([
    call(identifyBugsnag, traits),
    call(identifyIntercom, traits),
    call(identifyFullStory, traits),
  ]);
}

export function* watchLogout({ payload }) {
  yield call(logoutIntercom);
}

export function* identifyBugsnag({ userId, name, email }) {
  const bugsnagClient = window.bugsnagClient;

  if (!bugsnagClient) return;

  bugsnagClient.user = { id: userId, name, email };
}

export function* identifyFullStory({ userId, name, email }) {
  const FS = window.FS;
  if (!FS) return;

  if (isDeployAlpha || isDeployBeta || isDeployProd) {
    yield call(FS.identify, `${userId}`, {
      displayName: name,
      email,
    });
  }
}

// NOTE: this is a temp solution to only show CCOF intercom if all orgs are CCOF
const ccofAcaId = acaSlugToId('ccof');
export const hasOnlyCcofOrgs = R.both(
  R.compose(
    R.all(R.equals(ccofAcaId)),
    R.pluck('aca_id'),
  ),
  R.complement(R.isEmpty),
);

export function* identifyIntercom({ userId, acaIds, orgAcaIds, email, name, ...traits }) {
  // NOTE: this is a temp solution to only show CCOF intercom if all orgs are CCOF
  const acaId = hasOnlyCcofOrgs(orgAcaIds) ? ccofAcaId : R.head(acaIds);
  const orgIds = JSON.stringify(traits.orgIds);
  const roles = JSON.stringify(traits.roles);

  const showAcaUI = yield select(currentUser.showAcaUI);

  // always show soworg intercom if aca staff OR no beta/production (e.g. alpha)
  const forceSowOrgChat = showAcaUI || !(isDeployBeta || isDeployProd);

  yield call(
    IntercomService.boot,
    acaId,
    {
      user_id: userId,
      acaId,
      acaIds,
      email,
      name,
      orgIds,
      roles,
    },
    forceSowOrgChat,
  );
}

export function* logoutIntercom() {
  yield call(IntercomService.shutdown);
  yield call(IntercomService.boot);
}

export default function* rootSaga() {
  yield takeEvery(actions.auth.ecertLoginDone, watchLoginDone);
  yield takeEvery(actions.auth.loginDone, watchLoginDone);
  yield takeEvery(actions.auth.logoutDone, watchLogout);
  yield takeEvery(actions.auth.validateTokenDone, watchLoginDone);
}
