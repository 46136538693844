import React from 'react';
import PropTypes from 'prop-types';

import { API_URL } from 'sow/constants/api';
import Link from 'sow/components/atoms/Link';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const ExportActiveUsersButton = ({ acaId }) => (
  <Link
    href={`${API_URL}/v1/aca/${acaId}/export/active_users`}
    target="_blank"
    btn
    primary
    xs
  >
    <Glyphicon glyph="export" /> Export Active Users CSV
  </Link>
);

ExportActiveUsersButton.propTypes = {
  acaId: PropTypes.number.isRequired,
};

export default ExportActiveUsersButton;
