import aca from './aca';
import api from './api';
import auth from './auth';
import core from './core';
import gis from './gis';
import inspector from './inspector';
import layout from './layout';
import org from './organization';
import ospApp from './ospApplication';
import profile from './profile';
import shell from './shell';

const actionCreators = {
  aca,
  api,
  auth,
  core,
  gis,
  inspector,
  layout,
  org,
  ospApp,
  profile,
  shell,
};

export default actionCreators;
