import * as R from 'ramda';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { api } from 'sow/utils/apiNew';
import { consoleErrorRecovery, safeSaga } from 'sow/sagas/helpers';

export function* apiGet(url, data) {
  try {
    return { response: yield call(api.get, url, data) };
  } catch (error) {
    yield put(actions.api.requestError(error));
    return { error };
  }
}

export function* apiPost(url, data) {
  try {
    return { response: yield call(api.post, url, data) };
  } catch (error) {
    yield put(actions.api.requestError(error));
    return { error };
  }
}

export function* apiPut(url, data) {
  try {
    return { response: yield call(api.put, url, data) };
  } catch (error) {
    yield put(actions.api.requestError(error));
    return { error };
  }
}

export function* watchRequestError({ payload }) {
  const error = payload;

  try {
    yield call(requestError, error);
  } catch (error) {
    console.error(error);
  }
}

// TODO: replace this all together with a single error handler
const formatApiError = respError => {
  // NOTE: takes new apisauce based service api error and formats it to work with old api.requestError
  return {
    ...respError.response,
    responseJSON: R.path(['response', 'data'], respError),
  };
};

export function* requestError(error) {
  if (error instanceof Error) {
    error = formatApiError(error);
  }

  try {
    if (error.status === 403) {
      if (R.path(['responseJSON', 'error', 'message'], error) === 'User is not enabled') {
        const errorMessage =
          'You must confirm your email address before you can log in. ' +
          'To confirm, please click the link in the confirmation email we sent you. ' +
          'If you can not find the email in your inbox, please check your spam folder.';

        yield put(actions.shell.toast('danger', errorMessage));
      } else {
        yield put(
          actions.shell.toast(
            'danger',
            R.path(['responseJSON', 'error', 'message'], error),
          ),
        );
      }
    } else if (error.status === 404) {
      console.log('404 in here!', error);
      // yield put(error(error.statusText));
    } else if (error.status === 422) {
      yield put(
        actions.shell.toast(
          'danger',
          'There was a problem processing your request.',
          R.path(['responseJSON', 'error', 'errors'], error),
        ),
      );
    } else {
      if (R.path(['responseJSON', 'error'], error)) {
        const errorMessage = R.path(['responseJSON', 'error', 'message'], error);
        if (!R.isNil(errorMessage)) {
          yield put(actions.shell.toast('danger', errorMessage));
        }
      } else {
        console.error('Unhandled API request error!', error);
      }
    }
  } catch (error) {
    console.error('Unhandled error in requestError!', error);
  }
}

export default function* rootSaga() {
  const safeWorker = safeSaga(consoleErrorRecovery);

  try {
    yield all([takeEvery(actions.api.requestError, safeWorker(watchRequestError))]);
  } catch (error) {
    console.error('Unhandled error in sagas/api/rootSaga!', error);
  }
}
