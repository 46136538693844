export const planAppStates = {
  INITIAL_APPLICATION: 'initial_application',
  REVISION: 'revision',
  INITIAL_APPLICATION_SUBMITTED: 'initial_application_submitted',
  REVISION_SUBMITTED: 'revision_submitted',
  INITIAL_REVIEW: 'initial_review',
  INSPECTION: 'inspection',
  FINAL_REVIEW: 'final_review',
  COMPLETE: 'complete',
  NONCOMPLIANCE: 'noncompliance',
  WITHDRAWN: 'withdrawn',
};

// Display text for plan states
export const planAppStateText = {
  [planAppStates.INITIAL_APPLICATION]: 'Initial Application',
  [planAppStates.REVISION]: 'Unlocked for Update',
  [planAppStates.INITIAL_APPLICATION_SUBMITTED]: 'Initial Application Submitted',
  [planAppStates.REVISION_SUBMITTED]: 'Update Submitted',
  [planAppStates.INITIAL_REVIEW]: 'Initial Review',
  [planAppStates.INSPECTION]: 'Inspection',
  [planAppStates.FINAL_REVIEW]: 'Final Review',
  [planAppStates.COMPLETE]: 'Complete',
  [planAppStates.NONCOMPLIANCE]: 'Noncompliance',
  [planAppStates.WITHDRAWN]: 'Withdrawn',
};

// States in which a plan is considered "open" (all states up to completion)
export const PLAN_APP_STATES_OPEN_FOR_ORG = [
  planAppStates.INITIAL_APPLICATION,
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
  planAppStates.REVISION,
  planAppStates.REVISION_SUBMITTED,
  planAppStates.INITIAL_REVIEW,
  planAppStates.INSPECTION,
  planAppStates.FINAL_REVIEW,
];

// States in which an org can freely edit their application
// This can only be done on an initial application, afterwards it is always handled by a change request.
export const PLAN_APP_STATES_UNLOCKED_FOR_ORG = ['initial_application'];

// States in which a CR can be created on a plan if one does not already exist
export const PLAN_APP_STATES_CR_CREATABLE = [
  planAppStates.REVISION,
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
  planAppStates.REVISION_SUBMITTED,
  planAppStates.INITIAL_REVIEW,
  planAppStates.INSPECTION,
  planAppStates.FINAL_REVIEW,
];

// States in which an ACA can change the state of a plan using the state dropdown list
export const PLAN_APP_STATES_STATE_EDITABLE = [
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
  planAppStates.REVISION_SUBMITTED,
  planAppStates.INITIAL_REVIEW,
  planAppStates.INSPECTION,
  planAppStates.FINAL_REVIEW,
];

// States consiered "completed"
export const PLAN_APP_STATES_COMPLETED = [
  planAppStates.COMPLETE,
  planAppStates.NONCOMPLIANCE,
  planAppStates.WITHDRAWN,
];
