import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import PlanAppLocationFormFields from 'sow/components/organisms/PlanAppLocationFormFields';

import { defaultLocation, validateLocation } from 'sow/utils/planAppLocation';
import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';

class LocationCreateModal extends Component {
  state = {
    show: false,
  };

  setModalVisibility = show => this.setState({ show });
  showModal = () => this.setModalVisibility(true);
  hideModal = () => this.setModalVisibility(false);

  handleSubmit = (formData, formikBag) => {
    this.props
      .onCreate(formData)
      // User is redirected from the container, modal is unmounted automatically
      // .then(() => this.hideModal())
      .finally(() => formikBag.setSubmitting(false));
  };

  render() {
    const { show } = this.state;
    const { disabled } = this.props;

    return (
      <Fragment>
        <Button success onClick={this.showModal} disabled={disabled}>
          <Glyphicon glyph="plus-sign" /> Add Location
        </Button>

        <Modal bsSize="lg" show={show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Location</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={defaultLocation()}
            onSubmit={this.handleSubmit}
            validate={validateLocation}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Modal.Body>
                  <PlanAppLocationFormFields planAppLocation={values} />
                </Modal.Body>
                <Modal.Footer>
                  <Button default onClick={this.hideModal}>
                    Cancel
                  </Button>
                  <Button type="submit" primary disabled={isSubmitting}>
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}

LocationCreateModal.propTypes = {
  onCreate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default LocationCreateModal;
