import { uploadPictureAsync, uploadFileAsync } from 'sow/services/fileUpload';
import * as R from 'ramda';

const mark = action => `soworganic/fileUpload/${action}`;

export const ADD_TO_PICTURE_QUEUE = mark('ADD_TO_PICTURE_QUEUE');

export const addToPictureQueue = item => dispatch => {
  dispatch({
    type: ADD_TO_PICTURE_QUEUE,
    data: item,
  });
};

export const REMOVE_FROM_PICTURE_QUEUE = mark('REMOVE_FROM_PICTURE_QUEUE');

export const removeFromPictureQueue = guid => dispatch => {
  dispatch({
    type: REMOVE_FROM_PICTURE_QUEUE,
    data: guid,
  });
};

export const UPDATE_PICTURE = mark('UPDATE_PICTURE');

export const updatePicture = (guid, field, value) => dispatch => {
  dispatch({
    type: UPDATE_PICTURE,
    data: { guid, field, value },
  });
};

export const uploadPictureQueue = (queue, callback) => dispatch => {
  queue
    .filter(item => {
      // Only upload pending files
      return item.status === 'pending';
    })
    .forEach(item => {
      dispatch(updatePicture(item.guid, 'status', 'uploading'));

      uploadPictureAsync(item, onPictureUploadProgress)
        .done(res => {
          if (callback) callback(res);

          dispatch(updatePicture(item.guid, 'status', 'success'));
        })
        .fail(err => {
          console.log('uploadPictureQueue::uploadFileAsync:fail: err', err);
          dispatch(updatePicture(item.guid, 'status', 'failed'));

          const error = R.path(['responseJSON', 'error', 'message']);
          const errors = R.path(['responseJSON', 'error', 'errors']);

          if (error)
            dispatch(
              updatePicture(
                item.guid,
                'error',
                'There was a problem uploading the file.',
              ),
            );
          if (errors) dispatch(updatePicture(item.guid, 'errors', errors));
        });
    });
};

const onPictureUploadProgress = (guid, progress) => dispatch => {
  dispatch(updatePicture(guid, 'progress', progress));
};

export const ADD_TO_FILE_QUEUE = mark('ADD_TO_FILE_QUEUE');

export const addToFileQueue = item => ({
  type: ADD_TO_FILE_QUEUE,
  data: item,
});

export const REMOVE_ALL_FROM_FILE_QUEUE = mark('REMOVE_ALL_FROM_FILE_QUEUE');

export const removeAllFromFileQueue = () => ({
  type: REMOVE_ALL_FROM_FILE_QUEUE,
});

export const REMOVE_FROM_FILE_QUEUE = mark('REMOVE_FROM_FILE_QUEUE');

export const removeFromFileQueue = guid => dispatch => {
  dispatch({
    type: REMOVE_FROM_FILE_QUEUE,
    data: guid,
  });
};

export const UPDATE_FILE = mark('UPDATE_FILE');

export const updateFile = (guid, field, value) => dispatch => {
  dispatch({
    type: UPDATE_FILE,
    data: { guid, field, value },
  });
};

export const uploadFileQueue = (queue, callback) => dispatch => {
  queue
    .filter(item => {
      // Only upload pending files
      return item.status === 'pending';
    })
    .forEach(item => {
      dispatch(updateFile(item.guid, 'status', 'uploading'));

      uploadFileAsync(item, onFileUploadProgress)
        .done(res => {
          if (callback) callback(res);

          dispatch(updateFile(item.guid, 'status', 'success'));
        })
        .fail(err => {
          console.log('uploadFileQueue::uploadFileAsync:fail: err', err);
          dispatch(updateFile(item.guid, 'status', 'failed'));

          const error = R.path(['responseJSON', 'error', 'message']);
          const errors = R.path(['responseJSON', 'error', 'errors']);

          if (error)
            dispatch(
              updatePicture(
                item.guid,
                'error',
                'There was a problem uploading the file.',
              ),
            );
          if (errors) dispatch(updatePicture(item.guid, 'errors', errors));
        });
    });
};

const onFileUploadProgress = (guid, progress) => dispatch => {
  dispatch(updateFile(guid, 'progress', progress));
};

export const ADD_SINGLE_FILE = mark('ADD_SINGLE_FILE');

export const addSingleFile = file => dispatch => {
  dispatch({
    type: ADD_SINGLE_FILE,
    data: file,
  });
};

export const uploadSingleFile = (item, success) => dispatch => {
  dispatch(updateSingleFile('status', 'uploading'));

  uploadFileAsync(item, onSingleFileUploadProgress)
    .done(res => {
      dispatch(updateSingleFile('status', 'success'));
      if (success) success(res);
    })
    .fail(err => {
      console.log('uploadSingleFile::uploadFileAsync:fail: err', err);
      dispatch(updateSingleFile('status', 'failed'));

      const error = R.path(['responseJSON', 'error', 'message']);
      const errors = R.path(['responseJSON', 'error', 'errors']);

      if (error)
        dispatch(
          updatePicture(item.guid, 'error', 'There was a problem uploading the file.'),
        );
      if (errors) dispatch(updatePicture(item.guid, 'errors', errors));
    });
};

export const UPDATE_SINGLE_FILE = mark('UPDATE_SINGLE_FILE');

export const updateSingleFile = (field, value) => dispatch => {
  dispatch({
    type: UPDATE_SINGLE_FILE,
    data: { field, value },
  });
};

const onSingleFileUploadProgress = progress => dispatch => {
  dispatch(updateSingleFile('progress', progress));
};
