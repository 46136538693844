import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate } from 'sow/store/helpers';
import PlanAppLocationEditChangeModal from 'sow/components/organisms/PlanAppLocationEditChangeModal';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  // locationDetails: fromPlanApp.locationDetails(state, props),
  planAppLocation: fromPlanApp.planAppLocation(state, props),
  planAppLocationChange: fromPlanApp.locationChange(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isPlanAppOpen: fromPlanApp.isPlanAppOpen(state, props),
});

const mapResource = () => {
  const updateChangeResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const { orgId, planAppId, changeRequestId, planAppLocationChange } = ownProps;
    const { action } = updateChangeResource(orgId, planAppId, changeRequestId);

    return {
      onSubmit: values => {
        // If location values have not been updated with previous version of change, do not attempt to update
        if (deepEqual(values, planAppLocationChange.new)) {
          console.log('Form values unchanged, no update needed');
          return Promise.resolve();
        }

        // Update the 'new' property in the change object with the values submitted by the form
        const updatedChange = R.assoc('new', values, planAppLocationChange);

        return dispatch(action(null, { changes: [updatedChange] }))
          .then(() => dispatch(actions.shell.toast('success', 'Changes saved.')))
          .catch(() =>
            dispatch(
              actions.shell.toast('danger', 'There was a problem saving the changes.'),
            ),
          );
      },
    };
  };

  return connect(
    null,
    mapResourceDispatchToProps,
  );
};

const LocationEditChangeModalContainer = ({ children, ...props }) => {
  return (
    <PlanAppLocationEditChangeModal {...props}>{children}</PlanAppLocationEditChangeModal>
  );
};

LocationEditChangeModalContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  planAppLocation: sowTypes.planAppLocationType,
  planAppLocationChange: sowTypes.planAppChangeType,
  onSubmit: PropTypes.func.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isPlanAppOpen: PropTypes.bool.isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
};

LocationEditChangeModalContainer.defaultProps = {
  changeRequestId: null,
  planAppLocation: null,
  planAppLocationChange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
  mapResource(),
)(LocationEditChangeModalContainer);
