import React, { Component } from 'react';
import Recaptcha from 'react-grecaptcha';
import PropTypes from 'prop-types';

const RECAPTCHA_INVISIBLE_KEY = '6LdZfB0UAAAAAJ4F_f68QVIc10JMQJioJhFHtaFY';
const RECAPTCHA_V2_KEY = '6LfTfR0UAAAAAOFTpp6IbB1pUlQO8KeSmaQBQhDZ';

class GoogleRecaptcha extends Component {
  static propTypes = {
    invisible: PropTypes.bool,
    onVerifiedChange: PropTypes.func,
  };

  getSiteKey() {
    if (this.props.invisible) return RECAPTCHA_INVISIBLE_KEY;
    return RECAPTCHA_V2_KEY;
  }

  handleVerifyExpired = () => {
    this.props.onVerifiedChange(false);
  };

  handleVerified = () => {
    this.props.onVerifiedChange(true);
  };

  render() {
    const { invisible } = this.props;

    return (
      <Recaptcha
        sitekey={this.getSiteKey()}
        callback={this.handleVerified}
        expiredCallback={this.handleVerifyExpired}
        className="google-recaptcha"
        invisible={invisible}
      />
    );
  }
}

GoogleRecaptcha.propTypes = {};

export default GoogleRecaptcha;
