import React from 'react';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import * as routes from 'sow/routes';
import Atom from 'sow/components/atoms/Atom';
import Link from 'sow/components/atoms/Link';

/**
 * Returns the route with supplied params if all hold a value
 * @param {*} linkFn - The route to apply params to
 * @param  {...any} args - All necessary route params
 */
function createLink(linkFn, ...args) {
  if (R.any(R.isNil, args)) {
    return null;
  }
  return linkFn(...args);
}

const ActivityLogLink = ({ log }) => {
  // console.log('log:', log);

  const typeAction = `${log.log_type}.${log.action}`;

  const orgId = R.path(['organization', 'id'], log);
  const planAppId = R.path(['data', 'application_id'], log);
  const worksheetId = R.path(['data', 'worksheet_id'], log);
  const locationId = R.path(['data', 'land_id'], log);

  let linkTo;
  switch (typeAction) {
    case 'change_request.change_request_created':
    case 'change_request.change_request_submitted':
    case 'change_request.change_request_applied':
    // Deprecated "osp application" state changes
    case 'osp_application.application_state':
    case 'osp_application.application_created':
    case 'osp_application.application_renewed':
    case 'osp_application.application_changed':
    // New generic plan state change - not sure these are used
    case 'plan.application_state':
    case 'plan.application_created':
    case 'plan.application_renewed':
    case 'plan.application_changed':
    // Plan state changes
    case 'plan.initial_application_created':
    case 'plan.initial_application_submitted':
    case 'plan.revision_created':
    case 'plan.revision_submitted':
    case 'plan.entered_initial_review':
    case 'plan.entered_inspection':
    case 'plan.entered_final_review':
    case 'plan.completed':
    case 'plan.noncompliant':
    case 'plan.withdrawn':
      linkTo = createLink(routes.worksheetOverviewRoute, orgId, planAppId);
      break;

    // Worksheet-level answer updates and changes
    case 'change_request.change_added':
    case 'change_request.change_modified':
    case 'change_request.change_approved':
    case 'change_request.change_rejected':
    case 'change_request.comment_added':
    case 'note.note_added_by_aca':
    case 'note.note_added_by_admin':
    case 'note.note_added_by_org':
    case 'osp_application.worksheet_answer_added':
    case 'plan.worksheet_answer_added':
    case 'osp_application.worksheet_answer_changed':
    case 'plan.worksheet_answer_changed':
      linkTo = createLink(routes.worksheetRoute, orgId, planAppId, worksheetId);
      break;

    case 'organization.created':
      linkTo = createLink(routes.orgRoute, orgId);
      break;

    case 'osp_application.land_deleted':
      linkTo = createLink(routes.locationOverviewRoute, orgId, planAppId);
      break;

    case 'product.land_added':
    case 'product.land_changed':
    case 'product.land_deleted':
    case 'osp_application.land_added':
    case 'osp_application.land_changed':
    case 'osp_application.land_answer_added':
    case 'plan.land_answer_added':
    case 'osp_application.land_answer_changed':
    case 'plan.land_answer_changed':
      linkTo = createLink(routes.locationRoute, orgId, planAppId, locationId);
      break;

    // Products have been removed, no page to link to
    case 'product.product_added':
    case 'product.product_changed':
    case 'product.product_deleted':
    case 'osp_application.product_answer_added':
    case 'osp_application.product_answer_changed':
    case 'plan.product_answer_added':
    case 'plan.product_answer_changed':
      linkTo = null;
      break;

    default:
      linkTo = null;
    // Deprecated - backend hasn't updated this url property in a long time
    // default:
    //   linkTo = log.data.url;
  }

  if (!linkTo) {
    // Clicking on the log name will console.log the whole activity log item for debugging
    return <Atom onClick={() => console.log(log)}>{typeAction}</Atom>;
  }

  return <Link to={linkTo}>{typeAction}</Link>;
};

ActivityLogLink.propTypes = {
  log: sowTypes.activityLogType.isRequired,
};

export default ActivityLogLink;
