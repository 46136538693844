import { normalize } from 'normalizr';
import * as R from 'ramda';

import * as acaOspDefApi from 'sow/api/aca/ospDefinition';
import * as schema from 'sow/schema';
// import actions from 'sow/actions/pure';
import { makeImmutableData } from 'sow/utils/data';
import { getMiniOspDefaults } from 'sow/components/old/OspDefEditor/reducers/ospDef';

// temp noop until issue #307 solved
// Note (Dylan): I don't know if your errors are in a standard format,
// the argument order for the new error action is (string bsClass, mixed message, Object validationErrors)
const error = () => {};

// TODO (orther) move these to central module to be used EVERYWHERE!!!
const alertJsonError = (dispatch, apiError) => {
  const errorMsg = R.path(['responseJSON', 'error'], apiError);
  if (errorMsg) error(errorMsg);
};
const alertJsonErrorDefinitions = (dispatch, apiError) => {
  const errorDefs = R.path(['responseJSON', 'error', 'errors', 'definition'], apiError);

  if (errorDefs) {
    const errorMsg = errorDefs.join('\r\n');
    error(errorMsg);
  }
};

// const callIfFunction = (f, ...args) => {
//   if (typeof f === 'function') {
//     f(...args);
//   }
// };

const mark = action => `soworganic/ospDefinition/${action}`;

export const CREATE_ONE_FOR_ACA_REQUEST = mark('CREATE_ONE_FOR_ACA_REQUEST');
export const CREATE_ONE_FOR_ACA_SUCCESS = mark('CREATE_ONE_FOR_ACA_SUCCESS');
export const CREATE_ONE_FOR_ACA_FAILURE = mark('CREATE_ONE_FOR_ACA_FAILURE');

export const LOAD_ALL_FOR_ACA_REQUEST = mark('LOAD_ALL_FOR_ACA_REQUEST');
export const LOAD_ALL_FOR_ACA_SUCCESS = mark('LOAD_ALL_FOR_ACA_SUCCESS');
export const LOAD_ALL_FOR_ACA_FAILURE = mark('LOAD_ALL_FOR_ACA_FAILURE');

export const LOAD_ONE_FOR_ACA_REQUEST = mark('LOAD_ONE_FOR_ACA_REQUEST');
export const LOAD_ONE_FOR_ACA_SUCCESS = mark('LOAD_ONE_FOR_ACA_SUCCESS');
export const LOAD_ONE_FOR_ACA_FAILURE = mark('LOAD_ONE_FOR_ACA_FAILURE');

export const PATCH_ONE_FOR_ACA_REQUEST = mark('PATCH_ONE_FOR_ACA_REQUEST');
export const PATCH_ONE_FOR_ACA_SUCCESS = mark('PATCH_ONE_FOR_ACA_SUCCESS');
export const PATCH_ONE_FOR_ACA_FAILURE = mark('PATCH_ONE_FOR_ACA_FAILURE');

// Impure/Async Action Creators
export const loadAllOspDefsForAca = acaId => dispatch => {
  const buildData = makeImmutableData({ acaId });

  dispatch({
    type: LOAD_ALL_FOR_ACA_REQUEST,
    data: buildData(),
  });

  acaOspDefApi
    .fetchAll(acaId)
    .done(resp => {
      const data = normalize(resp.data, [schema.ospDef]);
      // const sepDataList = resp.data.map(ospDef =>
      //   normalize(ospDef, schema.ospDef)
      // );
      // const sepData = R.indexBy()

      dispatch({
        type: LOAD_ALL_FOR_ACA_SUCCESS,
        data: buildData(data),
        // data: buildData(sepDataList)
      });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ALL_FOR_ACA_FAILURE,
        data: buildData({ err }),
      });
    });
};

export const loadOspDefForAca = (acaId, ospDefId) => dispatch => {
  const buildData = makeImmutableData({ acaId, ospDefId });

  dispatch({
    type: LOAD_ONE_FOR_ACA_REQUEST,
    data: buildData(),
  });

  acaOspDefApi
    .fetchOne(acaId, ospDefId)
    .done(resp => {
      const respData = resp.data;

      const miniOspDefaults = getMiniOspDefaults();
      const landDefaults = R.propOr({}, 'land', miniOspDefaults);
      const productDefaults = R.propOr({}, 'product', miniOspDefaults);

      const rawData = R.compose(
        // remove old static worksheets (now we have real mini-osp)
        R.dissocPath(['definition', 'land']),
        R.dissocPath(['definition', 'product']),
        // add default land/product sections
        R.evolve({
          definition: {
            mini_osp: {
              land: R.merge(landDefaults),
              product: R.merge(productDefaults),
            },
          },
        }),
      )(respData);

      const data = normalize(rawData, schema.ospDef);

      dispatch({
        type: LOAD_ONE_FOR_ACA_SUCCESS,
        data: buildData(data),
      });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ONE_FOR_ACA_FAILURE,
        data: buildData({ err }),
      });
    });
};

export const createOspDefForAca = (acaId, ospDefData, onSuccess) => dispatch => {
  dispatch({
    type: CREATE_ONE_FOR_ACA_REQUEST,
    data: { acaId, ospDefData },
  });

  acaOspDefApi
    .postOne(acaId, ospDefData)
    .done(resp => {
      const data = normalize(resp.data, schema.ospDef);
      dispatch({
        type: CREATE_ONE_FOR_ACA_SUCCESS,
        data: { acaId, ospDefData, ...data },
      });

      // TODO (orther) I'm a bad person for putting this here and it should be moved
      if (typeof onSuccess === 'function') onSuccess();
    })
    .fail(err => {
      dispatch({
        type: CREATE_ONE_FOR_ACA_FAILURE,
        data: { acaId, ospDefData, ...err },
      });

      const errorMessage = R.path(['responseJSON', 'error', 'message'], err);
      if (errorMessage) {
        error(errorMessage);
      }

      const errorDefs = R.path(['responseJSON', 'error', 'errors', 'definition'], err);
      if (errorDefs) {
        error(errorDefs.join('\r\n'));
      }
    });
};

export const patchOspDefForAca = (acaId, ospDefId, ospDefData, onSuccess) => dispatch => {
  dispatch({
    type: PATCH_ONE_FOR_ACA_REQUEST,
    data: { acaId, ospDefId, ospDefData },
  });

  acaOspDefApi
    .patchOne(acaId, ospDefId, ospDefData)
    .done(resp => {
      const data = normalize(resp.data, schema.ospDef);
      dispatch({
        type: PATCH_ONE_FOR_ACA_SUCCESS,
        data: { acaId, ospDefId, ospDefData, ...data },
      });

      // TODO (orther) I'm a bad person for putting this here and it should be moved
      if (typeof onSuccess === 'function') onSuccess();
    })
    .fail(error => {
      dispatch({
        type: PATCH_ONE_FOR_ACA_FAILURE,
        data: { acaId, ospDefId, ospDefData, ...error },
      });

      alertJsonError(dispatch, error);
      alertJsonErrorDefinitions(dispatch, error);
    });
};

export const publishOspDefForAca = (acaId, ospDefId, onSuccess) => {
  return patchOspDefForAca(acaId, ospDefId, { published: true }, onSuccess);
};

export const unpublishOspDefForAca = (acaId, ospDefId, onSuccess) => {
  return patchOspDefForAca(acaId, ospDefId, { published: false }, onSuccess);
};
