import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const { google } = window;

class LatLngPickerMapField extends React.Component {
  handleDragEnd = mouseEvent => {
    this.props.setFieldValue(this.props.name, {
      lat: mouseEvent.latLng.lat(),
      lng: mouseEvent.latLng.lng(),
    });
  };

  render() {
    const { value, disabled } = this.props;
    const lat = value ? parseFloat(value.lat) : null;
    const lng = value ? parseFloat(value.lng) : null;

    const defaultZoom = 3;
    // If a lat/lng are blank, drop a pin in the center of the US
    // so something is visible to be dragged around
    const defaultCenter = lat || lng ? { lat, lng } : { lat: 38, lng: -97 };

    return (
      <GoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        mapTypeId={google.maps.MapTypeId.HYBRID}
      >
        <Marker
          draggable={!disabled}
          position={{ lat: lat || 38, lng: lng || -97 }}
          onDragEnd={this.handleDragEnd}
        />
      </GoogleMap>
    );
  }
}

LatLngPickerMapField.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.shape({
    // If the input fields are cleared out it will switch to being a string instead of a number,
    // so need to allow both types just in case. The map will cast it to a float before rendering.
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  disabled: PropTypes.bool,
};

LatLngPickerMapField.defaultProps = {
  disabled: false,
  value: null,
};

export default withGoogleMap(LatLngPickerMapField);
