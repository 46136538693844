import * as R from 'ramda';
import { createSelector } from 'reselect';
import _isArray from 'lodash/isArray';

import { fromRouter } from 'sow/store/selectors';
import { selectors as fromEntities } from 'sow/store/modules/entities';
import {
  planAppId,
  changeMap,
  worksheetEntity,
  worksheetId,
  matrixRowIdProp,
  questionIdProp,
} from './core';

export const planAppLocationEntity = state =>
  fromEntities.getEntity(state, 'planAppLocation');

export const locationAnswersEntity = state =>
  fromEntities.getEntity(state, 'locationAnswers');

export const locationWorksheetAnswerEntity = state =>
  fromEntities.getEntity(state, 'locationWorksheetAnswer');

export const locationIdProp = (state, props) => R.prop('locationId', props);

export const locationId = createSelector(
  [fromRouter.paramLocationId, locationIdProp],
  (paramLocationId, locationIdProp) => {
    if (locationIdProp) return locationIdProp;
    if (paramLocationId) return paramLocationId;
  },
);

export const locationMap = createSelector(
  [planAppLocationEntity, planAppId],
  (locationEntity, planAppId) => {
    return R.pickBy(R.propEq('applicationId', planAppId))(locationEntity);
  },
);

export const locationList = createSelector(
  [locationMap],
  R.pipe(
    R.values,
    R.sortWith([R.descend(R.prop('id'))]),
  ),
);

/**
 * Map of legacy locations (locations with no application_id set)
 */
const legacyLocationsMap = createSelector(
  planAppLocationEntity,
  R.pickBy(
    R.pipe(
      R.prop('applicationId'),
      R.isNil,
    ),
  ),
);

export const legacyLocationsList = createSelector(
  legacyLocationsMap,
  R.pipe(
    R.values,
    R.sortWith([R.ascend(R.prop('name'))]),
  ),
);

/** Changes for a location relevant to the answer fields */
export const locationAnswersChangesMap = createSelector(
  [planAppId, locationId, changeMap],
  (planAppId, locationId, changeMap) =>
    R.pickBy(
      R.where({
        applicationId: R.equals(planAppId),
        landId: R.equals(locationId),
        context: R.equals('land'),
        type: R.contains(R.__, ['worksheet_answer', 'matrix_row', 'matrix_row_answer']),
      }),
    )(changeMap),
);

export const locationAnswersChangeList = createSelector(
  locationAnswersChangesMap,
  R.values,
);

export const locationQuestionChange = createSelector(
  [locationAnswersChangesMap, matrixRowIdProp, questionIdProp],
  (changeMap, matrixRowId, questionId) => {
    return R.pipe(
      R.values,
      R.find(
        R.whereEq({
          matrixRowId: R.defaultTo(null, matrixRowId),
          questionId,
        }),
      ),
    )(changeMap);
  },
);

export const locationQuestionChangeId = createSelector(
  locationQuestionChange,
  R.path(['id']),
);

/** Map of changes on a location itself */
export const locationChangeMap = createSelector(
  [planAppId, changeMap],
  (planAppId, changeMap) =>
    R.pickBy(
      R.where({
        applicationId: R.equals(planAppId),
        context: R.equals('land'),
        type: R.equals('self'),
        landId: R.complement(R.isNil),
      }),
    )(changeMap),
);

export const locationChangeList = createSelector(
  locationChangeMap,
  R.values,
);

/** Creates a location list with new/removed locations (via changes) included */
export const planAppLocationIdList = createSelector(
  [locationId, locationList, locationChangeMap],
  (locationId, locationList, locationChangeMap) => {
    const locationIds = R.map(R.prop('id'), locationList);
    const locationChangesLandIds = R.pipe(
      R.values,
      R.map(R.prop('landId')),
    )(locationChangeMap);
    return R.union(locationIds, locationChangesLandIds);
  },
);

export const planAppLocation = createSelector(
  [locationId, planAppLocationEntity],
  R.prop,
);

const countChanges = whereClause =>
  R.pipe(
    R.pickBy(R.whereEq(whereClause)),
    R.keys,
    R.length,
  );

/** Returns true if there are > 0 'added' changes for a given location id */
export const isNewLocation = createSelector(
  [locationId, locationChangeMap],
  (locationId, locationChangeMap) =>
    countChanges({
      landId: locationId,
      action: 'added',
    })(locationChangeMap) > 0,
);

/** Returns true if there are > 0 'updated' changes for a given location id */
export const isUpdatedLocation = createSelector(
  [locationId, locationChangeMap],
  (locationId, locationChangeMap) =>
    countChanges({
      landId: locationId,
      action: 'updated',
    })(locationChangeMap) > 0,
);

/** Returns true if added or updated changes exist for given location id */
export const isNewOrUpdatedLocation = createSelector(
  [isNewLocation, isUpdatedLocation],
  (isNew, isUpdated) => isNew || isUpdated,
);

export const locationChange = createSelector(
  [locationId, locationChangeMap],
  (locationId, locationChangeMap) =>
    R.pipe(
      R.pickBy(R.whereEq({ landId: locationId })),
      R.values,
      R.prop(0), // should only be 1 possible change of this type per location
    )(locationChangeMap),
);

/** Map of changes on location worksheets */
export const locationWorksheetChangeMap = createSelector(
  [planAppId, changeMap],
  (planAppId, changeMap) =>
    R.pickBy(
      R.where({
        applicationId: R.equals(planAppId),
        context: R.equals('land'),
        type: R.contains(R.__, ['worksheet_answer', 'matrix_row', 'matrix_row_answer']),
      }),
    )(changeMap),
);

export const locationWorksheetChangeList = createSelector(
  locationWorksheetChangeMap,
  R.values,
);

/** Returns location details either from the base location object or from the 'new' change value if needed */
export const locationDetails = createSelector(
  [locationId, planAppLocation, locationChange],
  (locationId, planAppLocation, locationChange) => {
    if (!planAppLocation && !locationChange) return undefined;
    if (!locationChange) return planAppLocation;

    const action = R.path(['action'], locationChange);
    if (action === 'added' || action === 'updated') {
      return R.pipe(
        R.pathOr({}, ['new']),
        R.assoc('id', locationId), // Add the id into the 'fake' object so it looks like a location
      )(locationChange);
    } else if (action === 'deleted') {
      return R.pathOr({}, ['old'], locationChange);
    }

    return null;
  },
);

/** Lists most current worksheet ids for a given location, accounting for a change request */
export const locationQualifiedWorksheetIds = createSelector(
  [planAppLocation, locationChange],
  (loc, locChange) => {
    // There should either be a real location or a change.new location
    if (!loc && !locChange) return [];

    const locationWSIds = R.propOr([], 'worksheetIds', loc);

    if (!locChange) {
      return locationWSIds;
    }

    const changeAction = R.prop('action', locChange);
    const changeState = R.prop('state', locChange);
    const changeWSIds = R.pathOr([], ['new', 'worksheetIds'], locChange);

    // Deletion changes don't have anything in `new`
    if (changeAction === 'deleted') {
      return locationWSIds;
    }

    switch (changeState) {
      case 'open':
      case 'accepted':
      case 'applied':
        return changeWSIds;

      case 'rejected':
      case 'not_applied':
      default:
        return locationWSIds;
    }
  },
);

/** List of qualified worksheets for a given location */
export const locationQualifiedWorksheets = createSelector(
  [worksheetEntity, locationQualifiedWorksheetIds],
  (allWorksheets, qualifiedIds) => {
    return R.pipe(
      R.values,
      R.filter(
        R.where({
          uuid: R.contains(R.__, qualifiedIds),
        }),
      ),
    )(allWorksheets);
  },
);

export const locationWorksheet = createSelector(
  [worksheetId, worksheetEntity],
  R.prop,
);

export const locationAnswers = createSelector(
  [locationId, locationAnswersEntity],
  R.propOr({}),
);

export const locationWorksheetAnswers = createSelector(
  [locationId, worksheetId, locationAnswersEntity],
  (locationId, worksheetId, locationAnswersEntity) =>
    R.pathOr(
      {},
      [locationId, 'answers', 'worksheets', worksheetId],
      locationAnswersEntity,
    ),
);

/** Map of questions for a given location worksheet id */
export const locationWorksheetQuestions = createSelector(
  locationWorksheet,
  R.propOr([], 'questions'),
);

/** Returns uuids for all questions on all worksheets for a location */
export const allLocationQuestionIds = createSelector(
  [locationQualifiedWorksheets],
  R.pipe(
    R.pluck('questions'),
    R.flatten,
  ),
);

/** Deletion change for a given location id */
export const locationDeletionChange = createSelector(
  [locationId, locationChangeMap],
  (locationId, changeMap) =>
    R.pipe(
      R.values,
      R.find(
        R.whereEq({
          action: 'deleted',
          landId: locationId,
        }),
      ),
    )(changeMap),
);

/** Returns true if the location's deletion change is accepted or applied */
export const isLocationDeleted = createSelector(
  [locationDeletionChange],
  R.allPass([
    R.complement(R.isNil),
    R.pipe(
      R.prop('state'),
      R.contains(R.__, ['accepted', 'applied']),
    ),
  ]),
);

/** Returns true if the location has a deletion change that is accepted or applied */
export const isDeletedLocation = createSelector(
  [locationDeletionChange],
  change => {
    if (!change) return false;

    switch (change.state) {
      case 'accepted':
      case 'applied':
        return true;

      case 'open':
      case 'rejected':
      case 'not_applied':
      default:
        return false;
    }
  },
);

export const locationIsReadyForReview = createSelector(
  locationAnswers,
  R.propOr(false, 'readyForReview'),
);

// --
// -- Files
// --
// TODO Clean this up after release

export const allLocationWorksheetAnswers = createSelector(
  [locationList, locationWorksheetAnswerEntity],
  (locationList, locationWorksheetAnswerEntity) => {
    const locationIds = R.map(R.prop('id'), locationList);

    const isLocationAnswer = (val, key) => {
      const keyLocationId = R.pipe(
        R.split('::'),
        R.head,
        id => ~~id,
      )(key);
      return R.contains(keyLocationId, locationIds);
    };

    return R.pickBy(isLocationAnswer, locationWorksheetAnswerEntity);
  },
);

//
//
export const allLocationWorksheetAnswersFiles = createSelector(
  [allLocationWorksheetAnswers],
  wsAnswers => {
    const values = R.pipe(
      R.values,
      R.chain(
        R.pipe(
          R.path(['answers', 'answers', 'values']),
          R.values, // strip worksheet id
        ),
      ),
    )(wsAnswers);

    const matrixRowValues = R.pipe(
      R.values,
      R.chain(
        R.pipe(
          R.path(['answers', 'answers', 'matrixRows']),
          R.values, // strip worksheet id
          R.chain(
            R.pipe(
              R.path(['values']),
              R.values,
            ),
          ),
        ),
      ),
    )(wsAnswers);

    const allValues = R.concat(values, matrixRowValues);

    const files = R.pipe(
      R.filter(_isArray),
      R.flatten,
      R.filter(
        R.where({
          id: R.complement(R.isNil),
          name: R.complement(R.isNil),
        }),
      ),
    )(allValues);

    return files;
  },
);

export const locationIsExcluded = createSelector(
  locationDetails,
  R.propOr(false, 'excludedFromPlan'),
);

/**
 * Returns true if new location added by change is discarded
 */
export const isNewLocationDiscarded = createSelector(
  [locationChange],
  R.pipe(
    R.defaultTo({}),
    R.whereEq({
      action: 'added',
      state: 'rejected',
    }),
  ),
);
