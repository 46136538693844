import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

import { planAppWorksheetQuestionMatrixRowIdType } from 'sow/types';
import Button from 'sow/components/atoms/Button';
import WorksheetQuestionChange from 'sow/containers/planApp/WorksheetQuestionChange';

class WorksheetMatrixAnswerModal extends Component {
  state = {
    showModal: this.props.showModal,
    matrixRowId: this.props.matrixRowId,
  };

  showModal = () => {
    this.setState({
      showModal: true,
      matrixRowId: this.props.matrixRowId || uuid(),
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.props.onHideModal();
  };

  componentDidUpdate() {
    if (!this.state.showModal && this.props.showModal) {
      this.showModal();
    }
  }

  render() {
    const { showModal, matrixRowId } = this.state;
    const {
      title,
      orgId,
      planAppId,
      worksheetId,
      changeRequest,
      showAcaUI,
      questions,
      disabled,
      changeUIDisabled,
      baseAnswersName,
      baseAnswersChangesName,
      locationId,
      children,
    } = this.props;

    return (
      <Fragment>
        {children(this.showModal)}

        <Modal show={showModal} onHide={this.hideModal} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {questions.map(questionId => (
              <WorksheetQuestionChange
                key={questionId}
                worksheetId={worksheetId}
                locationId={locationId}
                questionId={questionId}
                matrixRowId={matrixRowId}
                baseAnswersName={`${baseAnswersName}.matrixRows.${matrixRowId}.values.`}
                baseAnswersChangesName={`${baseAnswersChangesName}.matrixRows.${matrixRowId}.values.`}
                disabled={disabled}
                changeUIDisabled={changeUIDisabled}
              />
            ))}
          </Modal.Body>

          <Modal.Footer>
            <Button primary onClick={this.hideModal}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

WorksheetMatrixAnswerModal.propTypes = {
  matrixRowId: planAppWorksheetQuestionMatrixRowIdType,
  title: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,

  // Whether the modal should trigger an open state, based on clicks elsewhere in the app
  showModal: PropTypes.bool.isRequired,
  // Manages router state when the modal is closed so it doesn't reopen automatically
  onHideModal: PropTypes.func.isRequired,
};

WorksheetMatrixAnswerModal.defaultProps = {
  matrixRowId: null, // Is created by this component if new row
  disabled: false,
};

export default WorksheetMatrixAnswerModal;
