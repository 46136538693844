import React from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Panel from 'sow/components/molecules/Panel';
import Block from 'sow/components/atoms/Block';
import Spinner from 'sow/components/atoms/Spinner';
import AcaActivityLogFilters from 'sow/components/organisms/AcaActivityLogFilters';
import AcaActivityTable from 'sow/components/organisms/AcaActivityTable';
import ExportActiveUsersButton from 'sow/components/organisms/ExportActiveUsersButton';

const AcaActivityDashboardPage = ({
  acaId,
  logs,
  filters,
  errors,
  onFilterChange,
  isFetching,
  onResetFilters,
}) => {
  return (
    <Panel
      title="Activity Log"
      heading={
        <Block pullRight>
          <ExportActiveUsersButton acaId={acaId} />
        </Block>
      }
    >
      <AcaActivityLogFilters
        errors={errors}
        filters={filters}
        onChange={onFilterChange}
        onResetFilters={onResetFilters}
      />

      {isFetching ? <Spinner /> : 'Placeholder for activity table'}
      {isFetching ? <Spinner /> : <AcaActivityTable logs={logs} />}
    </Panel>
  );
};

AcaActivityDashboardPage.propTypes = {
  acaId: sowTypes.acaIdType.isRequired,
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onResetFilters: PropTypes.func.isRequired,
};

export default AcaActivityDashboardPage;
