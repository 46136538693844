import * as R from 'ramda';
import _S from 'underscore.string.fp';

export const wordsToInitials = R.map(
  R.compose(
    R.toUpper,
    R.head,
  ),
);

export const fullNameToInitials = R.compose(
  R.join(''),
  wordsToInitials,
  _S.words,
);

export const initialize = (name, defaultInitials = 'N/A') => {
  if (!R.is(String, name) || _S.isBlank(name)) {
    return defaultInitials;
  }

  return fullNameToInitials(name);
};
