import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import LocationWorksheetQuestion from 'sow/containers/planApp/LocationWorksheetQuestion';
import LocationWorksheetMatrix from 'sow/containers/planApp/LocationWorksheetMatrix';

const PlanAppLocationWorksheet = ({ worksheetId, worksheet, questions, values }) => {
  const isMatrix = R.prop('matrix', worksheet);

  return (
    <Widget>
      <WidgetHeading>{worksheet.name}</WidgetHeading>
      <WidgetBody>
        {isMatrix && (
          <LocationWorksheetMatrix worksheetId={worksheetId} values={values} />
        )}
        {!isMatrix &&
          questions.map(questionId => (
            <LocationWorksheetQuestion
              key={questionId}
              worksheetId={worksheetId}
              questionId={questionId}
            />
          ))}
      </WidgetBody>
    </Widget>
  );
};

PlanAppLocationWorksheet.propTypes = {
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  values: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(sowTypes.worksheetQuestionIdType).isRequired,
};

export default PlanAppLocationWorksheet;
