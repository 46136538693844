import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

class RouterWillLeaveBlock extends Component {
  componentDidUpdate() {
    if (this.props.blocked) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  render() {
    const { blocked, message } = this.props;
    return <Prompt when={blocked} message={() => message} />;
  }
}

RouterWillLeaveBlock.propTypes = {
  blocked: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

RouterWillLeaveBlock.defaultProps = {
  message: 'You have unsaved changes. Are you sure you would like to leave this page?',
};

export default RouterWillLeaveBlock;
