import React, { Fragment } from 'react';
import * as R from 'ramda';
import { Glyphicon, MenuItem, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentOspDef from 'sow/selectors/currentOspDef';
import FormGroup from 'sow/components/organisms/FormGroup';
import Spinner from 'sow/components/atoms/Spinner';
import { denormalizeData } from 'sow/components/old/OspDefEditor/OspDefEditor';
import { createOspDefForAca, loadOspDefForAca } from 'sow/actions/ospDefinition';

const mapDispatchToProps = { createOspDefForAca, loadOspDefForAca };

const mapStateToProps = (state, props) => ({
  isLoading: currentOspDef.isLoadingOspDef(state, props),
});

export class CopyOspDefModal extends React.Component {
  state = {
    show: false,
    name: '',
  };

  handleHide = () => {
    this.setState({
      show: false,
      name: '',
    });
  };

  handleShow = event => {
    const { loadOspDefForAca, acaId, ospDef } = this.props;

    this.setState({
      show: true,
      name: `${ospDef.name} (Copy)`,
    });

    if (acaId && !ospDef.definition) {
      loadOspDefForAca(acaId, ospDef.id);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const { acaId, ospDef, ospDefId, ospQualificationQuestions } = this.props;
    const { createOspDefForAca, sections, worksheets, worksheetQuestions } = this.props;
    const { name } = this.state;
    const rawObj = {
      ospDef: R.merge(ospDef, { name, published: false }),
      ospSections: sections,
      worksheets,
      worksheetQuestions,
      ospQualificationQuestions,
    };
    const ospDefDenormalized = denormalizeData(rawObj);
    console.log({ rawObj, ospDefDenormalized });
    createOspDefForAca(acaId, ospDefDenormalized.ospDef, this.handleHide);
  };

  render() {
    const { isLoading, ospDef } = this.props;
    const { show, name } = this.state;
    const errors = {};

    if (isLoading || !ospDef) return <Spinner />;

    return (
      <Fragment>
        <MenuItem id="setOspDefNameButton" onClick={this.handleShow}>
          <Glyphicon glyph="duplicate" /> Copy (create new)
        </MenuItem>
        <Modal show={show} onHide={this.handleHide}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Copy Plan Definition (create new)</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormGroup
                fieldName="name"
                errors={errors}
                label="New Plan Definition's Name"
                required
              >
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                  className="form-control"
                  required
                />
              </FormGroup>
            </Modal.Body>

            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Done
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CopyOspDefModal);
