import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import HeaderBar from 'sow/components/organisms/HeaderBar';
import Sidebar from 'sow/containers/Sidebar';
import Block from 'sow/components/atoms/Block';
import Container from 'sow/components/atoms/Container';
import Helmet from 'sow/components/atoms/Helmet';
import ErrorBoundary from 'sow/components/ErrorBoundary';
import BranchRibbon from 'sow/components/organisms/BranchRibbon';

const StandardLayoutMainContentFallback = () => (
  <div>
    <h1>Whoops Something Went Wrong!</h1>
    <p>We could put general text here.</p>
  </div>
);

class StandardLayout extends Component {
  state = {
    navbarToggled: false,
  };

  handleNavbarToggle = () =>
    this.setState(prevState => ({ navbarToggled: !prevState.navbarToggled }));

  render() {
    const { children, title, breadcrumb } = this.props;
    const { navbarToggled } = this.state;

    const sideBodyClasses = classnames('side-body', navbarToggled ? 'body-slide-in' : '');

    return (
      <Fragment>
        <Helmet title={title} />
        <BranchRibbon />

        <Sidebar navbarToggled={navbarToggled} onNavbarToggle={this.handleNavbarToggle} />

        <Container fluid>
          <Block className={sideBodyClasses}>
            <HeaderBar title={title} breadcrumb={breadcrumb} />

            <ErrorBoundary FallbackComponent={StandardLayoutMainContentFallback}>
              {children}
            </ErrorBoundary>
          </Block>
        </Container>
      </Fragment>
    );
  }
}

StandardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(PropTypes.array),
};

StandardLayout.defaultProps = {
  title: 'Sow Organic',
  fatalError: null,
};

export default StandardLayout;
