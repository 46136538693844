import { normalize } from 'normalizr';

import actions from 'sow/actions/pure';
import * as acaApi from 'sow/api/aca';
import * as schema from 'sow/schema';
import { fetchActivityLogAsync } from 'sow/services/aca';

const mark = action => `soworganic/aca/${action}`;

export const LOAD_ALL_REQUEST = mark('LOAD_ALL_REQUEST');
export const LOAD_ALL_SUCCESS = mark('LOAD_ALL_SUCCESS');
export const LOAD_ALL_FAILURE = mark('LOAD_ALL_FAILURE');

export const loadAll = () => dispatch => {
  dispatch({ type: LOAD_ALL_REQUEST });

  acaApi
    .fetchAll()
    .done(res => {
      const data = normalize(res.data, [schema.aca]);
      dispatch({ type: LOAD_ALL_SUCCESS, data });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ALL_FAILURE,
        data: { err },
      });
    });
};

export const loadAllRegister = () => dispatch => {
  dispatch({ type: LOAD_ALL_REQUEST });

  acaApi
    .fetchAllRegister()
    .done(res => {
      const data = normalize(res.data, [schema.aca]);
      dispatch({ type: LOAD_ALL_SUCCESS, payload: data });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ALL_FAILURE,
        payload: { err },
      });
    });
};

export const FETCH_PENDING_REQUEST = mark('FETCH_PENDING_REQUEST');
export const FETCH_PENDING_SUCCESS = mark('FETCH_PENDING_SUCCESS');
export const FETCH_PENDING_FAILURE = mark('FETCH_PENDING_FAILURE');

export const fetchPending = acaId => dispatch => {
  dispatch({ type: FETCH_PENDING_REQUEST });

  acaApi
    .fetchPendingList(acaId)
    .done(res => {
      dispatch({
        type: FETCH_PENDING_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching aca pending:', err.responseJSON);
      dispatch({ type: FETCH_PENDING_FAILURE });
    });
};

export const FETCH_ACA_ACTIVITY_LOG = mark('FETCH_ACA_ACTIVITY_LOG');
export const FETCH_ACA_ACTIVITY_LOG_SUCCESS = mark('FETCH_ACA_ACTIVITY_LOG_SUCCESS');
export const FETCH_ACA_ACTIVITY_LOG_FAILED = mark('FETCH_ACA_ACTIVITY_LOG_FAILED');
export const RESET_ACA_ACTIVITY_LOG = mark('RESET_ACA_ACTIVITY_LOG');

export const resetActivityLog = () => dispatch =>
  dispatch({ type: RESET_ACA_ACTIVITY_LOG });

export const fetchActivityLog = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_ACA_ACTIVITY_LOG });

  fetchActivityLogAsync(acaId, filters)
    .done(res => {
      dispatch({
        type: FETCH_ACA_ACTIVITY_LOG_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching aca activity log:', err.responseJSON);
      dispatch({ type: FETCH_ACA_ACTIVITY_LOG_FAILED });
    });
};

export const FETCH_TIMEZONE_LIST = mark('FETCH_TIMEZONE_LIST');
export const FETCH_TIMEZONE_LIST_SUCCESS = mark('FETCH_TIMEZONE_LIST_SUCCESS');
export const FETCH_TIMEZONE_LIST_FAILED = mark('FETCH_TIMEZONE_LIST_FAILED');

export const fetchTimezoneList = () => dispatch => {
  dispatch({ type: FETCH_TIMEZONE_LIST });

  acaApi
    .fetchTimezoneList()
    .done(res => {
      dispatch({
        type: FETCH_TIMEZONE_LIST_SUCCESS,
        data: { items: res.data },
      });
    })
    .fail(err => {
      dispatch({
        type: FETCH_TIMEZONE_LIST_FAILED,
        data: err.responseJSON,
      });
    });
};

export const UPDATE_ONE_REQUEST = mark('UPDATE_ONE_REQUEST');
export const UPDATE_ONE_SUCCESS = mark('UPDATE_ONE_SUCCESS');
export const UPDATE_ONE_FAILURE = mark('UPDATE_ONE_FAILURE');

export const updateOne = (acaId, data) => dispatch => {
  dispatch({ type: UPDATE_ONE_REQUEST });

  acaApi
    .updateOne(acaId, data)
    .done(res => {
      dispatch({
        type: UPDATE_ONE_SUCCESS,
        data: res.data,
      });

      dispatch(
        actions.shell.toast(
          'success',
          'ACA updated. You may need to reload the page to see all changes.',
        ),
      );
    })
    .fail(err => {
      dispatch({
        type: UPDATE_ONE_FAILURE,
        data: err.responseJSON,
      });
    });
};

export const LOAD_NEW_REGISTRATIONS_LIST = mark('LOAD_NEW_REGISTRATIONS_LIST');
export const LOAD_NEW_REGISTRATIONS_LIST_DONE = mark('LOAD_NEW_REGISTRATIONS_LIST_DONE');
export const LOAD_NEW_REGISTRATIONS_LIST_FAIL = mark('LOAD_NEW_REGISTRATIONS_LIST_FAIL');

export const fetchNewRegistrations = acaId => dispatch => {
  dispatch({
    type: LOAD_NEW_REGISTRATIONS_LIST,
  });

  acaApi
    .fetchNewRegistrations(acaId)
    .done(res =>
      dispatch({
        type: LOAD_NEW_REGISTRATIONS_LIST_DONE,
        payload: res.data,
      }),
    )
    .fail(err =>
      dispatch({
        type: LOAD_NEW_REGISTRATIONS_LIST_FAIL,
        payload: err,
      }),
    );
};

export const LOAD_IN_PROGRESS_LIST = mark('LOAD_IN_PROGRESS_LIST');
export const LOAD_IN_PROGRESS_LIST_DONE = mark('LOAD_IN_PROGRESS_LIST_DONE');
export const LOAD_IN_PROGRESS_LIST_FAIL = mark('LOAD_IN_PROGRESS_LIST_FAIL');

export const fetchInProgress = acaId => dispatch => {
  dispatch({ type: LOAD_IN_PROGRESS_LIST });

  acaApi
    .fetchInProgress(acaId)
    .done(res =>
      dispatch({
        type: LOAD_IN_PROGRESS_LIST_DONE,
        payload: res.data,
      }),
    )
    .fail(err =>
      dispatch({
        type: LOAD_IN_PROGRESS_LIST_FAIL,
        payload: err,
      }),
    );
};
