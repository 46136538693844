import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { ResourceDetailLoader } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import OrgPlanAppLocationPage from 'sow/components/pages/OrgPlanAppLocationPage';
import {
  PlanAppLoader,
  PlanAppLocationListLoader,
  PlanAppWorksheetNotesLoader,
  PlanAppChangeRequestLoader,
  PlanAppChangeLoader,
} from 'sow/store/containers';
import { locationOverviewRoute } from 'sow/routes';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  planAppLocation: fromPlanApp.planAppLocation(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isPlanAppOpen: fromPlanApp.isPlanAppOpen(state, props),
  showOrgUI: currentUser.showOrgUI(state, props),
  editLocationByOrgAllowed: currentOrg.configPlanEditLocationByOrgAllowed(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
});

const fakeStatus = status => ({
  ...status,
  error: false,
  done: true,
});

const OrgPlanAppLocationPageContainer = ({
  orgId,
  planAppId,
  locationId,
  planAppLocation,
  locationDetails,
  changeRequestId,
  isStateInitialApplication,
  isPlanAppOpen,
  showOrgUI,
  editLocationByOrgAllowed,
}) => {
  // Loading location is only needed when id is positive, i.e. not a temp id created by change
  const autoloadLocation = locationId > 0;
  const isEditAllowed = editLocationByOrgAllowed || !showOrgUI;

  return (
    <OrgPlanAppPageTemplate
      title={R.pathOr('', ['name'], locationDetails)}
      breadcrumb={[['Locations', locationOverviewRoute(orgId, planAppId)]]}
    >
      <PlanAppLoader autoLoad>
        {({ status: planAppStatus }) => (
          <PlanAppChangeRequestLoader autoLoad>
            {({ status: crStatus }) => (
              <PlanAppChangeLoader
                requestParams={{ land_ids: [locationId] }}
                autoLoad={!R.isNil(changeRequestId)}
              >
                {({ status: changesStatus }) => {
                  const areChangesLoaded = changeRequestId && !changesStatus.initial;
                  return (
                    <ResourceLoaderStatusRender
                      statusObjects={[
                        areChangesLoaded ? changesStatus : fakeStatus(changesStatus),
                      ]}
                      renderAllDone={() => (
                        <PlanAppLocationListLoader autoLoad>
                          {({ status: locationListStatus }) => (
                            <ResourceDetailLoader
                              resource={`org/${orgId}/application/${planAppId}/land/${locationId}`}
                              entityType="planAppLocation"
                              autoLoad={autoloadLocation}
                            >
                              {({ status: locationStatus }) => (
                                <ResourceDetailLoader
                                  resource={`org/${orgId}/application/${planAppId}/land/${locationId}/answers`}
                                  entityType="locationAnswers"
                                  autoLoad
                                >
                                  {({ status: answersStatus }) => (
                                    <ResourceLoaderStatusRender
                                      statusObjects={[
                                        planAppStatus,
                                        crStatus,
                                        locationListStatus,
                                        answersStatus,
                                        autoloadLocation
                                          ? locationStatus
                                          : fakeStatus(locationStatus),
                                      ]}
                                      renderAllDone={() => (
                                        <PlanAppWorksheetNotesLoader autoLoad>
                                          {() => (
                                            <OrgPlanAppLocationPage
                                              orgId={orgId}
                                              planAppId={planAppId}
                                              locationId={locationId}
                                              planAppLocation={planAppLocation}
                                              changeRequestId={changeRequestId}
                                              isStateInitialApplication={
                                                isStateInitialApplication
                                              }
                                              isPlanAppOpen={isPlanAppOpen}
                                              isEditAllowed={isEditAllowed}
                                            />
                                          )}
                                        </PlanAppWorksheetNotesLoader>
                                      )}
                                    />
                                  )}
                                </ResourceDetailLoader>
                              )}
                            </ResourceDetailLoader>
                          )}
                        </PlanAppLocationListLoader>
                      )}
                    />
                  );
                }}
              </PlanAppChangeLoader>
            )}
          </PlanAppChangeRequestLoader>
        )}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppLocationPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  planAppLocation: sowTypes.planAppLocationType,
  locationDetails: sowTypes.planAppLocationType,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isPlanAppOpen: PropTypes.bool.isRequired,
  showOrgUI: PropTypes.bool.isRequired,
  editLocationByOrgAllowed: PropTypes.bool.isRequired,
};

OrgPlanAppLocationPageContainer.defaultProps = {
  planApp: null,
  planAppLocation: null,
  changeRequestId: null,
  locationchange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgPlanAppLocationPageContainer);
