import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pending, rejected } from 'redux-saga-thunk';
import PropTypes from 'prop-types';

import { fromEntities, fromResource } from 'sow/store/selectors';
import actions from 'sow/actions/pure';
import InspectorDashboardPage from 'sow/components/pages/InspectorDashboardPage';

const mapStateToProps = state => ({
  orgList: fromEntities.getList(state, 'org', fromResource.getList(state, 'org')),
  orgListFailed: rejected(state, 'orgListRead'),
  orgListLoading: pending(state, 'orgListRead'),
});

const mapDispatchToProps = {
  loadOrgs: actions.org.loadList,
};

class InspectorDashboardPageContainer extends Component {
  componentDidMount() {
    if (!this.props.orgListLoading) this.props.loadOrgs();
  }

  render() {
    const { loadOrgs, ...componentProps } = this.props;
    return <InspectorDashboardPage {...componentProps} />;
  }
}

InspectorDashboardPageContainer.propTypes = {
  // Connected props
  orgList: PropTypes.array.isRequired,
  orgListFailed: PropTypes.bool.isRequired,
  orgListLoading: PropTypes.bool.isRequired,
  loadOrgs: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(InspectorDashboardPageContainer);
