import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Form } from 'formik';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Spacer from 'sow/components/atoms/Spacer';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import LocationWorksheet from 'sow/containers/planApp/LocationWorksheet';
import WorksheetBaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';
import Button from 'sow/components/atoms/Button';
import Tooltip from 'sow/components/molecules/Tooltip';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import ActionBar from 'sow/components/molecules/ActionBar';
import { locationOverviewRoute } from 'sow/routes';
import ScrollToHashTargetOnMount from 'sow/components/atoms/ScrollToHashTargetOnMount';
import RouterWillLeaveBlock from 'sow/components/atoms/RouterWillLeaveBlock';

const PlanAppLocationWorksheetsForm = ({
  orgId,
  planAppId,
  worksheets,
  values,
  isPlanAppLocked,
  isStateInitialApplication,
  changeRequestIsOpen,
  onSubmit,
  onSubmitRedirect,
  isSubmitting,
  isDeleted,
  isDirty
}) => {
  const saveable = isStateInitialApplication || changeRequestIsOpen;

  const showDeletedMessage = isDeleted && !changeRequestIsOpen;

  return (
    <Form>
      <RouterWillLeaveBlock
        blocked={isDirty}
        message="This worksheet contains unsaved changes, are you sure you would like to navigate away?"
      />

      <ScrollToHashTargetOnMount />

      {worksheets.map(ws => {
        const wsId = R.prop('uuid', ws);
        return (
          <Fragment key={wsId}>
            <Spacer vertical={20} />
            <LocationWorksheet worksheetId={wsId} values={values} />
          </Fragment>
        );
      })}

      {showDeletedMessage && (
        <Fragment>
          <Spacer vertical={20} />
          <InfoPanel text="This location does not have any associated worksheets due to being deleted." />
        </Fragment>
      )}

      {/* Note: this won't always appear due to there being worksheet ids in the system without a worksheet */}
      {R.isEmpty(worksheets) && !showDeletedMessage && (
        <Fragment>
          <Spacer vertical={20} />
          <InfoPanel text="This location's type/scope combination does not require any worksheets to be filled out." />
        </Fragment>
      )}

      {!isPlanAppLocked && (
        <Block style={{ marginTop: '10px' }}>
          <WorksheetBaseQuestion
            name="readyForReview"
            text="Are you finished with this location?"
            fieldType="bool"
            answer={R.pathOr(false, ['readyForReview'], values)}
            disabled={isSubmitting}
          />
        </Block>
      )}
      <ActionBar>
        {saveable && (
          // Only render save bar if the answers/changes can be saved
          <Fragment>
            <Tooltip overlay="Save this page and return to the location overview">
              <Button primary onClick={onSubmitRedirect} disabled={isSubmitting}>
                <FontAwesome icon="undo-alt" /> Save and Return
              </Button>
            </Tooltip>

            <Button type="submit" primary onClick={onSubmit} disabled={isSubmitting}>
              <FontAwesome icon="cloud-upload-alt" /> Save
            </Button>
          </Fragment>
        )}

        {!saveable && (
          <Link to={locationOverviewRoute(orgId, planAppId)} btn primary>
            <FontAwesome icon="undo-alt" /> Return to Locations
          </Link>
        )}
      </ActionBar>
    </Form>
  );
};

PlanAppLocationWorksheetsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitRedirect: PropTypes.func.isRequired,
  worksheets: PropTypes.arrayOf(sowTypes.planAppWorksheetType).isRequired,
  values: sowTypes.planAppLocationAnswersType.isRequired,
  isPlanAppLocked: PropTypes.bool.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default PlanAppLocationWorksheetsForm;
