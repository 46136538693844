import * as R from 'ramda';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push as routerPush } from 'connected-react-router';

import actions from 'sow/actions/pure';
import { REGISTER_SUCCESS } from 'sow/actions/orgRegistration';
import { consoleErrorRecovery, safeSaga } from 'sow/sagas/helpers';
import { isDevEnv } from 'sow/config';
import { loginRoute } from 'sow/routes';
import { setAuthToken, removeAuthToken } from 'sow/utils/authToken';

export function* ecertLogin(api, credentials) {
  try {
    const response = yield call(api.post, '/auth/ecert_login', credentials);

    console.log({ response });
    yield put(routerPush('/'));
    yield call(setAuthToken, response.data.token);
    yield put(actions.auth.ecertLoginDone(response.data));
  } catch (error) {
    yield put(actions.api.requestError(error));
    yield put(actions.auth.ecertLoginFail());
  }
}

export function* login(api, credentials) {
  try {
    const response = yield call(api.post, '/auth/login', credentials);
    yield call(setAuthToken, response.data.token);
    yield put(actions.auth.loginDone(response.data));
  } catch (e) {
    const error = R.path(['response', 'data', 'error'], e);

    let toastMessage = 'There was a problem logging you in. Please try again.';
    if (error && error.type === 'CANNOT_AUTHENTICATE') {
      switch (error.reason) {
        case 'username_or_password_missing':
        case 'username_or_password_incorrect':
          toastMessage =
            'We were unable to authenticate you with those credentials. ' +
            'Please check your username and password and try again.';
          break;

        case 'user_not_enabled':
          toastMessage =
            'You must confirm your email address before you can log in. ' +
            'To confirm, please click the link in the confirmation email we sent you. ' +
            'If you can not find the email in your inbox, please check your spam folder.';
          break;
      }
    }

    yield put(actions.shell.toast('danger', toastMessage));
    yield put(actions.auth.loginFail(error));
  }
}

export function* logout(api, { successMsg }) {
  try {
    // remove auth token from localStorage
    yield call(removeAuthToken);

    try {
      // clear cookies
      // TODO: consider adding saga for logout apiUtil call and fork to it
      yield call(api.post, '/auth/logout');
    } catch (error) {
      yield put(actions.auth.logoutFail());
    }

    // redirect before state reset to prevent errors from UI reading empty state
    yield put(actions.core.state.reset());
    yield put(actions.auth.logoutDone());
    yield put(routerPush({ pathname: loginRoute(), state: null }));

    if (successMsg) {
      yield put(actions.shell.toast('success', successMsg));
    }
  } catch (error) {
    yield put(actions.auth.logoutFail());
  }
}

export function* validateToken(api) {
  try {
    const response = yield call(api.get, '/auth/validate_token');
    yield put(actions.auth.validateTokenDone(response.data));
  } catch (err) {
    yield put(actions.auth.validateTokenFail());

    // Log out if the error code was anything other than a server error
    if (err.response.status < 500 || err.response.status > 599) {
      if (isDevEnv) {
        // alert user in local dev
        yield call(logout, api, {
          successMsg: 'DEV MODE: Logged out from error!',
        });
      } else {
        yield call(logout, api, {});
      }
    }
  }
}

export function* watchEcertLogin(api, { payload }) {
  const { credentials } = payload;
  yield call(ecertLogin, api, credentials);
}

export function* watchLogin(api, { payload }) {
  const { credentials } = payload;
  yield call(login, api, credentials);
}

export function* watchLogout(api, action) {
  yield call(logout, api, {
    successMsg: 'You have been logged out.',
  });
}

export function* watchLogoutOrgRegistration(api) {
  yield call(logout, api, {
    successMsg:
      'You have successfully registered. Please log back in to start your application.',
  });
}

export function* watchValidateToken(api) {
  yield call(validateToken, api);
}

export default function* authRootSaga({ api }) {
  const safe = safeSaga(consoleErrorRecovery);

  try {
    yield all([
      takeEvery(actions.auth.login, safe(watchLogin, api)),
      takeEvery(actions.auth.ecertLogin, safe(watchEcertLogin, api)),
      takeEvery(actions.auth.validateToken, safe(watchValidateToken, api)),
      takeEvery(actions.auth.logout, safe(watchLogout, api)),
      takeEvery(REGISTER_SUCCESS, safe(watchLogoutOrgRegistration, api)),
    ]);
  } catch (error) {
    console.error('Unhandled error in sagas/auth/rootSaga!', error);
  }
}
