import * as R from 'ramda';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push as routerPush } from 'connected-react-router';

import { register } from 'sow/actions/pure/registration';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import GoogleRecaptcha from 'sow/components/old/GoogleRecaptcha';
import RegistrationForm from 'sow/components/old/user/RegistrationForm';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import { loginRoute, registerRoute, registerDoneRoute } from 'sow/routes';

export const setUserSettingAcaFilterId = (user, acaId) =>
  R.assocPath(['settings', 'aca_filter', 'id'], acaId, user);

export const defaultUser = (acaId, isInspector) => {
  const user = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    inspector: isInspector,
  };

  if (!acaId) return user;

  return setUserSettingAcaFilterId(user, acaId);
};

const mapStateToProps = state => ({
  isRegistering: state.registration.isRegistering,
  errors: state.registration.errors,
});

const mapDispatchToProps = {
  register,
  redirect: routerPush,
};

class Register extends Component {
  static propTypes = {
    isInspector: PropTypes.bool,
  };

  static defaultProps = {
    isInspector: false,
  };

  state = {
    user: defaultUser(this.props.acaId, this.props.isInspector),
    recaptchaVerified: false,
  };

  handleChange = (field, value) => {
    this.setState(R.assocPath(['user', field], value));
  };

  handleRecaptchaVerifiedChange = verified => {
    this.setState(R.assocPath(['recaptchaVerified'], verified));
  };

  handleRegisterSuccess = () => {
    this.props.redirect(registerDoneRoute());
  };

  handleSubmit = event => {
    event.preventDefault();

    if (this.isSubmitEnabled()) {
      this.props.register(this.state.user, this.handleRegisterSuccess);
    }
  };

  isSubmitEnabled = () => {
    return !this.props.isRegistering && this.state.recaptchaVerified;
  };

  render() {
    const { errors, isInspector } = this.props;
    const { user } = this.state;

    const disableSubmit = !this.isSubmitEnabled();

    return (
      <CenteredLayout>
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-left">
            <Widget className="widget-primary">
              <WidgetHeading
                style={
                  isInspector
                    ? {
                        fontSize: '14pt',
                        background: '#337ab7',
                        color: 'white',
                      }
                    : undefined
                }
              >
                {isInspector ? (
                  <span>
                    Create an <b>Inspector</b> account with Sow Organic
                  </span>
                ) : (
                  'Create an account with Sow Organic'
                )}
              </WidgetHeading>

              <form onSubmit={this.handleSubmit}>
                <WidgetBody>
                  <RegistrationForm
                    user={user}
                    handleChange={this.handleChange}
                    errors={errors}
                  />

                  <hr />

                  <GoogleRecaptcha
                    onVerifiedChange={this.handleRecaptchaVerifiedChange}
                  />
                </WidgetBody>

                <WidgetFooter className="text-right">
                  <Link to={loginRoute()} className="btn btn-danger">
                    Cancel
                  </Link>{' '}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={disableSubmit}
                  >
                    Register
                  </button>
                </WidgetFooter>
              </form>
            </Widget>

            <div className="well well-sm text-center" style={{ marginTop: '5px' }}>
              Already have an account? <Link to={loginRoute()}>Sign in here.</Link>
            </div>

            {isInspector ? (
              <div
                className="alert alert-warning text-danger text-center"
                style={{ marginTop: '5px' }}
              >
                Not an organic inspector? <Link to={registerRoute()}>Sign up here.</Link>
              </div>
            ) : (
              <div
                className="alert alert-success text-center"
                style={{ marginTop: '5px' }}
              >
                Are you an organic inspector? <Link to="/inspector">Sign up here.</Link>
              </div>
            )}
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Register);
