import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { orgIdType, planAppIdType, planAppStateType } from 'sow/types';
import { resourceUpdate } from 'sow/store/helpers';
import { isStateOpen } from 'sow/utils/planAppState';

import PlanAppEditStateModal from 'sow/components/organisms/OrgPlanAppTable/PlanAppEditStateModal';

const getUpdateStateResource = (orgId, planAppId) =>
  resourceUpdate(`org/${orgId}/application/${planAppId}/review_state`, 'planAppResp');

const mapStateToProps = (state, props) => {
  const { selectors } = getUpdateStateResource(props.orgId, props.planAppId);

  return {
    isUpdating: selectors.pending(state, props),
  };
};

const mapDispatchToProps = dispatch => ({
  updateState: (orgId, planAppId) => {
    const { action } = getUpdateStateResource(orgId, planAppId);
    return state => dispatch(action(null, { osp_application: { state } }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { orgId, planAppId } = ownProps;

  return {
    ...ownProps,
    onSubmit: dispatchProps.updateState(orgId, planAppId),
  };
};

const PlanAppEditStateModalContainer = props => {
  if (!isStateOpen({ state: props.appState })) return null;
  return <PlanAppEditStateModal {...props} />;
};

PlanAppEditStateModalContainer.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  appState: planAppStateType.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(PlanAppEditStateModalContainer);
