import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { getMessagingState } from 'sow/reducers/messaging';
import { hideConfirm } from 'sow/actions/pure/messaging';
import Button from 'sow/components/atoms/Button';

const mapStateToProps = (state, props) => ({
  show: getMessagingState(state, props).showConfirm,
  options: getMessagingState(state, props).confirmOptions,
});

const mapDispatchToProps = {
  hideConfirm,
};

/** Singleton confirmation modal mounted at app-level that reads from redux state */
class ConfirmModalContainer extends Component {
  handleConfirm = () => {
    const { options, hideConfirm } = this.props;

    if (options.onConfirm) options.onConfirm();
    hideConfirm();
  };

  handleCancel = () => {
    const { options, hideConfirm } = this.props;
    if (options.onCancel) options.onCancel();
    hideConfirm();
  };

  render() {
    const { show, options } = this.props;

    return (
      <Modal show={show} onHide={this.handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{options.title || 'Sow Organic'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{options.message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCancel}>
            {options.cancelButtonText || 'Cancel'}
          </Button>

          <Button primary onClick={this.handleConfirm}>
            {options.confirmButtonText || 'Ok'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  options: PropTypes.shape({
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    /** Message to be displayed in confirmation modal.
     * Do not use JSX nodes here -- if needed, use the Confirm standalone component
     */
    message: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
  }),
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ConfirmModalContainer);
