import React from 'react';
import { DropdownButton, MenuItem, Glyphicon } from 'react-bootstrap';

const callOnEvent = callFn => event => {
  event.preventDefault();
  callFn();
};

export const ButtonMoveUp = ({ btnColor = 'warning', className, onClick, style }) => (
  <button
    type="button"
    className={`btn btn-${btnColor} ${className}`}
    onClick={onClick}
    style={style}
  >
    <Glyphicon glyph="arrow-up" />
  </button>
);

export const ButtonMoveDown = ({ btnColor = 'warning', className, onClick, style }) => (
  <button
    type="button"
    className={`btn btn-${btnColor} ${className}`}
    onClick={onClick}
    style={style}
  >
    <Glyphicon glyph="arrow-down" />
  </button>
);

const ButtonDelete = ({ onClick, className, style }) => (
  <button
    type="button"
    className={`btn btn-danger btn-xs ${className}`}
    onClick={onClick}
    style={style}
  >
    Delete
  </button>
);

const FormPanel = ({ children, className, style }) => (
  <div className={`panel panel-default ${className}`} style={style}>
    {children}
  </div>
);

const Heading = ({ children, className, style }) => (
  <div
    className={`panel-heading ${className}`}
    // fix striped table row background order (remove header bottom border)
    style={{ borderBottom: 'none', ...style }}
  >
    <div className="panel-title">{children}</div>
  </div>
);

const defaultOptionsForHeadingDropdown = [];
const HeadingDropdown = ({
  children,
  bsSize = 'xs',
  className = 'pull-right',
  icon = 'cog',
  // options = [/*{ key, icon, text, onClick}*/],
  options = defaultOptionsForHeadingDropdown,
}) => (
  <div className={className}>
    <DropdownButton
      id="FormPanel-HeadingDropdown"
      title={<Glyphicon glyph={icon} />}
      bsSize={bsSize}
      pullRight
    >
      {children}
    </DropdownButton>
  </div>
);

const Body = ({ children, className, style }) => (
  <table className={`table table-bordered table-striped ${className}`} style={style}>
    <tbody>
      {/* fix striped table row background order (make first row light) */}
      <tr style={{ display: 'none' }} />

      {children}
    </tbody>
  </table>
);

const BodyRow = ({ children, className, style, onDelete, onMoveDown, onMoveUp }) => (
  <tr className={className} style={style}>
    <td>{children}</td>
    {(onDelete || onMoveDown || onMoveUp) && (
      <td className="col-xs-1" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        {onMoveUp && <ButtonMoveUp className="btn-block" onClick={onMoveUp} />}
        {onMoveDown && <ButtonMoveDown className="btn-block" onClick={onMoveDown} />}
        {onDelete && (
          <div>
            <hr />
            <ButtonDelete onClick={onDelete} />
          </div>
        )}
      </td>
    )}
  </tr>
);

const BodyShortRow = ({
  children,
  className,
  style,
  handlerId,
  onDelete,
  onMoveDown,
  onMoveUp,
  customControl,
  stackMoveButtons,
  canEditOspDef,
}) => (
  <tr className={className} style={style}>
    <td>{children}</td>
    {canEditOspDef && !stackMoveButtons && onMoveUp && (
      <td className="col-xs-1" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <ButtonMoveUp className="btn-block" onClick={onMoveUp(handlerId)} />
      </td>
    )}
    {canEditOspDef && !stackMoveButtons && onMoveDown && (
      <td className="col-xs-1" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <ButtonMoveDown className="btn-block" onClick={onMoveDown(handlerId)} />
      </td>
    )}
    {canEditOspDef && stackMoveButtons && (onMoveUp || onMoveDown) && (
      <td className="col-xs-1" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        {onMoveUp && (
          <ButtonMoveUp
            className="btn-xs"
            onClick={onMoveUp(handlerId)}
            style={{ margin: '1px' }}
          />
        )}
        {onMoveDown && (
          <ButtonMoveDown
            className="btn-xs"
            onClick={onMoveDown(handlerId)}
            style={{ margin: '1px' }}
          />
        )}
      </td>
    )}
    {canEditOspDef && onDelete && (
      <td className="col-xs-1" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <ButtonDelete onClick={onDelete(handlerId)} />
      </td>
    )}
    {customControl && (
      <td className="col-xs-1" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        {customControl}
      </td>
    )}
  </tr>
);

FormPanel.Heading = Heading;
FormPanel.Heading.Dropdown = HeadingDropdown;
FormPanel.Body = Body;
FormPanel.Body.Row = BodyRow;
FormPanel.Body.ShortRow = BodyShortRow;
FormPanel.ButtonMoveUp = ButtonMoveUp;
FormPanel.ButtonMoveDown = ButtonMoveDown;
FormPanel.ButtonDelete = ButtonDelete;

export default FormPanel;
