import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ospDefType,
  acaIdType,
  ospDefIdType,
  ospQualificationQuestionType,
  ospSectionType,
  worksheetQuestionType,
  worksheetType,
  ospDefUserType,
} from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import CreateEmptyOspDefModal from 'sow/components/old/aca/osp/CreateEmptyOspDefModal';
import OspDefState from 'sow/components/old/aca/osp/OspDefState';
import OspDefAdminActionsButton from 'sow/components/organisms/OspDefAdminActionsButton';

const NoOspDefsRow = () => (
  <TableRow>
    <TableCell colSpan={4} textCenter>
      No Plan Definitions
    </TableCell>
  </TableRow>
);

export const OspDefTableCellUpdated = ({ user, ospDef }) => (
  <TableCell textCenter valignMiddle>
    {moment(ospDef.updated_at).format('L')}
  </TableCell>
);

OspDefTableCellUpdated.propTypes = {
  user: ospDefUserType,
  ospDef: ospDefType.isRequired,
};

export const OspDefTableCellId = ({ user, ospDef }) => (
  <TableCell textCenter valignMiddle>
    {ospDef.id}
  </TableCell>
);

OspDefTableCellId.propTypes = {
  user: ospDefUserType,
  ospDef: ospDefType.isRequired,
};

export const OspDefTableCellName = ({ user, ospDef }) => (
  <TableCell textCenter valignMiddle>
    {ospDef.name}
  </TableCell>
);

OspDefTableCellName.propTypes = {
  user: ospDefUserType,
  ospDef: ospDefType.isRequired,
};

const ospDefStatePubCur = () => (
  <OspDefState stateName="Published - Current" colorContext="success" />
);

const ospDefStatePubOld = () => (
  <OspDefState stateName="Published - Old" colorContext="danger" />
);

const ospDefStateNotPub = () => (
  <OspDefState stateName="Unpublished - Draft" colorContext="warning" />
);

const getOspDefStateHtml = currentOspDefId =>
  R.cond([
    [R.propEq('id', currentOspDefId), ospDefStatePubCur],
    [R.propEq('published', true), ospDefStatePubOld],
    [R.T, ospDefStateNotPub],
  ]);

export const OspDefTableCellState = ({ ospDef, currentOspDefId }) => (
  <TableCell textCenter valignMiddle>
    {getOspDefStateHtml(currentOspDefId)(ospDef)}
  </TableCell>
);

OspDefTableCellState.propTypes = {
  ospDef: ospDefType.isRequired,
  currentOspDefId: ospDefIdType.isRequired,
};

export const OspDefTableRow = ({
  user,
  acaId,
  ospDef,
  currentOspDefId,
  sections,
  worksheets,
  worksheetQuestions,
  ospQualificationQuestions,
  confirmPublish,
  confirmUnpublish,
}) => (
  <TableRow>
    <OspDefTableCellId user={user} ospDef={ospDef} />
    <OspDefTableCellName user={user} ospDef={ospDef} />
    <OspDefTableCellUpdated user={user} ospDef={ospDef} />
    <OspDefTableCellState
      user={user}
      acaId={acaId}
      ospDef={ospDef}
      currentOspDefId={currentOspDefId}
    />
    {(user.isSuperAdmin || user.isAcaAdmin) && (
      <TableCell xs={1} textRight>
        <OspDefAdminActionsButton
          user={user}
          acaId={acaId}
          ospDef={ospDef}
          sections={sections}
          worksheets={worksheets}
          worksheetQuestions={worksheetQuestions}
          ospQualificationQuestions={ospQualificationQuestions}
          confirmPublish={confirmPublish}
          confirmUnpublish={confirmUnpublish}
        />
      </TableCell>
    )}
  </TableRow>
);

OspDefTableRow.propTypes = {
  ospDef: ospDefType.isRequired,
  user: ospDefUserType.isRequired,
  acaId: acaIdType.isRequired,
  currentOspDefId: ospDefIdType,
  ospQualificationQuestions: PropTypes.objectOf(ospQualificationQuestionType),
  sections: PropTypes.objectOf(ospSectionType),
  worksheetQuestions: PropTypes.objectOf(worksheetQuestionType),
  worksheets: PropTypes.objectOf(worksheetType),
  confirmPublish: PropTypes.func.isRequired,
  confirmUnpublish: PropTypes.func.isRequired,
};

const AcaPlanDashboardPage = ({
  user,
  acaId,
  currentOspDefId,
  ospDefList,
  sections,
  worksheets,
  worksheetQuestions,
  ospQualificationQuestions,
  confirmPublish,
  confirmUnpublish,
}) => {
  return (
    <Widget>
      <WidgetHeading>
        Plan Definitions
        <Block pullRight>
          <ButtonToolbar>
            <DropdownButton
              id="landRowActionsButton"
              bsSize="xs"
              title={<Glyphicon glyph="cog" />}
              pullRight
            >
              <MenuItem header>Admin Actions</MenuItem>
              <CreateEmptyOspDefModal acaId={acaId} />
            </DropdownButton>
          </ButtonToolbar>
        </Block>
      </WidgetHeading>

      <WidgetBody>
        <Table
          bordered
          striped
          head={
            <TableRow>
              <TableCell heading textCenter>
                ID
              </TableCell>
              <TableCell heading textCenter>
                Name
              </TableCell>
              <TableCell heading textCenter>
                Updated
              </TableCell>
              <TableCell heading textCenter>
                State
              </TableCell>
              <TableCell heading />
            </TableRow>
          }
        >
          {/* FIX FIRST ROW BG WHITE */}
          <TableRow style={{ display: 'none' }}>
            <TableCell colSpan={4}>&nbsp;</TableCell>
          </TableRow>

          {ospDefList &&
            ospDefList.map(ospDef => (
              <OspDefTableRow
                key={ospDef.id}
                user={user}
                acaId={acaId}
                ospDef={ospDef}
                sections={sections}
                worksheets={worksheets}
                worksheetQuestions={worksheetQuestions}
                ospQualificationQuestions={ospQualificationQuestions}
                currentOspDefId={currentOspDefId}
                confirmPublish={confirmPublish}
                confirmUnpublish={confirmUnpublish}
              />
            ))}
          {!ospDefList || (!ospDefList.length && <NoOspDefsRow />)}
        </Table>
      </WidgetBody>
    </Widget>
  );
};

AcaPlanDashboardPage.propTypes = {
  ospDefList: PropTypes.arrayOf(ospDefType),
  user: ospDefUserType.isRequired,
  acaId: acaIdType.isRequired,
  currentOspDefId: ospDefIdType,
  ospQualificationQuestions: PropTypes.objectOf(ospQualificationQuestionType),
  sections: PropTypes.objectOf(ospSectionType),
  worksheetQuestions: PropTypes.objectOf(worksheetQuestionType),
  worksheets: PropTypes.objectOf(worksheetType),
  confirmPublish: PropTypes.func.isRequired,
  confirmUnpublish: PropTypes.func.isRequired,
};

export default AcaPlanDashboardPage;
