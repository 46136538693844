import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppExportContentWorksheetMatrixRow from 'sow/components/organisms/PlanAppExportContent/WorksheetMatrixRow';

const mapStateToProps = (state, props) => ({
  matrixRowAnswerMap: fromPlanApp.exportMatrixRowAnswerMap(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  changeList: fromPlanApp.exportMatrixRowChangeList(state, props),
  questionList: fromPlanApp.worksheetQuestionList(state, props),
  commentList: fromPlanApp.exportMatrixRowCommentList(state, props),
  noteList: fromPlanApp.notes(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  addNotes: fromPlanApp.exportConfigAddNotes(state, props),
  addComments: fromPlanApp.exportConfigAddComments(state, props),
});

const PlanAppExportContentWorksheetMatrixRowContainer = ({ ...props }) => (
  <PlanAppExportContentWorksheetMatrixRow {...props} />
);

PlanAppExportContentWorksheetMatrixRowContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType.isRequired,
  showMainPlan: PropTypes.bool,
  showLocations: PropTypes.bool,
  exportConfig: sowTypes.planAppExportConfigType,

  // Connected props
  matrixRowAnswerMap: PropTypes.object,
  questionList: sowTypes.planAppWorksheetQuestionListType.isRequired,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  changeList: sowTypes.planAppChangeListType.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  noteList: sowTypes.planAppNoteListType.isRequired,
  commentList: sowTypes.planAppChangeCommentListType.isRequired,
  addNotes: PropTypes.bool,
  addComments: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportContentWorksheetMatrixRowContainer);
