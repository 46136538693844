import React from 'react';

import AttachmentForm from './AttachmentForm';

function prettyStatus(status) {
  switch (status) {
    case 'pending':
      return 'Pending';

    case 'uploading':
      return (
        <span className="text-muted">
          <i>Uploading...</i>
        </span>
      );

    case 'success':
      return (
        <span className="text-success">
          <span className="glyphicon glyphicon-ok" /> Success
        </span>
      );

    case 'failed':
      return (
        <span className="text-danger">
          <span className="glyphicon glyphicon-remove" /> Failed
        </span>
      );

    default:
      return null;
  }
}

const QueueItem = ({ item, isAdmin, onChange }) => (
  <div className="panel panel-default">
    <div className="panel-body">
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <AttachmentForm isAdmin={isAdmin} attachment={item} handleChange={onChange} />
        </div>

        <div className="col-xs-12 col-sm-4">
          <div className="form-group">
            <label className="pull-left control-label">{item.file.name}</label>
            <div className="pull-right">{prettyStatus(item.status)}</div>
            <div className="clearfix" />
            <div className="progress" style={{ height: '30px' }}>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow={item.progress}
                style={{ width: `${item.progress}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default QueueItem;
