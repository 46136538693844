import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import { InfoTooltipWrapper } from 'sow/components/organisms/InfoTooltip';
import Button from 'sow/components/atoms/Button';
import WorksheetMatrixAnswerModal from 'sow/components/organisms/WorksheetMatrixAnswerModal';
import MatrixRow from 'sow/containers/planApp/MatrixRow';
import QuestionDetails from 'sow/containers/planApp/QuestionDetails';

const WorksheetMatrix = ({
  questions,
  values,
  orgId,
  planAppId,
  worksheetId,
  locationId,
  changeRequest,
  showAcaUI,
  isPlanAppLocked,
  isPlanAppClosed,
  isWorksheetLocked,
  changeRequestIsOpen,
  changeUIDisabled,
  baseAnswersName,
  baseAnswersChangesName,
}) => {
  const answersPath = locationId
    ? ['answers', 'worksheets', worksheetId, 'answers', 'matrixRows']
    : ['worksheetAnswers', 'answers', 'matrixRows'];
  const answersChangesPath = locationId
    ? ['answersChanges', 'worksheets', worksheetId, 'answers', 'matrixRows']
    : ['worksheetAnswersChanges', 'matrixRows'];

  const answers = R.path(answersPath, values);
  const answersChanges = R.path(answersChangesPath, values);
  const answersRowUuids = R.keys(answers);
  const answersChangesRowUuids = R.keys(answersChanges);
  const rowUuids = R.union(answersRowUuids, answersChangesRowUuids);

  const controlsDisabled =
    (isPlanAppLocked || isPlanAppClosed) && !(changeRequest && changeRequestIsOpen);

  return (
    <Widget>
      <WidgetBody>
        <Table
          condensed
          head={
            <TableRow>
              {/* <TableCell /> */}
              {questions.map(questionId => (
                <QuestionDetails questionId={questionId} key={questionId}>
                  {({ name, tooltip }) => (
                    <TableCell heading>
                      <InfoTooltipWrapper tooltipText={tooltip}>
                        {name}
                      </InfoTooltipWrapper>
                    </TableCell>
                  )}
                </QuestionDetails>
              ))}
              <TableCell heading xs={1} />
            </TableRow>
          }
          foot={
            !controlsDisabled && (
              <TableRow>
                <TableCell colSpan={questions.length + 2}>
                  <WorksheetMatrixAnswerModal
                    title="Add Answer"
                    orgId={orgId}
                    planAppId={planAppId}
                    worksheetId={worksheetId}
                    locationId={locationId}
                    changeRequest={changeRequest}
                    showAcaUI={showAcaUI}
                    questions={questions}
                    changeUIDisabled={changeUIDisabled}
                    disabled={isWorksheetLocked}
                    showModal={false}
                    onHideModal={() => {}}
                    baseAnswersName={baseAnswersName}
                    baseAnswersChangesName={baseAnswersChangesName}
                  >
                    {onClick => (
                      <Button success onClick={onClick} disabled={changeUIDisabled}>
                        Add Answer
                      </Button>
                    )}
                  </WorksheetMatrixAnswerModal>
                </TableCell>
              </TableRow>
            )
          }
        >
          {rowUuids.map(matrixRowId => (
            <MatrixRow
              key={matrixRowId}
              matrixRowId={matrixRowId}
              questions={questions}
              orgId={orgId}
              planAppId={planAppId}
              worksheetId={worksheetId}
              locationId={locationId}
              changeRequest={changeRequest}
              showAcaUI={showAcaUI}
              controlsDisabled={controlsDisabled}
              isWorksheetLocked={isWorksheetLocked}
              changeRequestIsOpen={changeRequestIsOpen}
              changeUIDisabled={changeUIDisabled}
              baseAnswersName={baseAnswersName}
              baseAnswersChangesName={baseAnswersChangesName}
            />
          ))}
        </Table>
      </WidgetBody>
    </Widget>
  );
};

WorksheetMatrix.propTypes = {
  // Uncategorized props
  questions: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionIdType).isRequired,
  values: PropTypes.object,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  changeRequest: sowTypes.planAppChangeRequestType,
  showAcaUI: PropTypes.bool,
  isPlanAppLocked: PropTypes.bool,
  isPlanAppClosed: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
  isWorksheetLocked: PropTypes.bool,
  changeUIDisabled: PropTypes.bool.isRequired,

  // Passed props
  baseAnswersName: PropTypes.string,
  baseAnswersChangesName: PropTypes.string,

  // Connected props
  locationId: sowTypes.planAppLocationIdType,
};

WorksheetMatrix.defaultProps = {
  baseAnswersName: 'worksheetAnswers.answers',
  baseAnswersChangesName: 'worksheetAnswersChanges',
  locationId: null,
};

export default WorksheetMatrix;
