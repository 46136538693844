import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { orgRoute } from 'sow/routes';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import Heading from 'sow/components/atoms/Heading';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import Spinner from 'sow/components/atoms/Spinner';
import Small from 'sow/components/atoms/Small';

const OrgSwitcherModal = ({
  showModal,
  onShowModal,
  onHideModal,
  currentOrgId,
  switchableOrgs,
  orgListPending,
}) => (
  <Atom>
    <Block
      className="org-home-button"
      style={{ cursor: 'pointer', marginRight: '15px' }}
      onClick={onShowModal}
    >
      <FontAwesome icon="home" />
    </Block>

    <Modal show={showModal} onHide={onHideModal}>
      <Modal.Header closeButton>Switch Organization</Modal.Header>
      <Modal.Body>
        <Spinner show={orgListPending} />

        {switchableOrgs.map(org => (
          <Block key={org.id} style={{ marginBottom: '10px' }}>
            <Link
              to={orgRoute(org.id)}
              style={{ width: '100%', height: '100%' }}
              onClick={onHideModal}
              disabled={org.id === currentOrgId}
            >
              <Heading level={3} style={{ marginTop: 0 }}>
                {org.name}
                {org.id === currentOrgId && (
                  <Atom>
                    {' '}
                    <Small>(current)</Small>
                  </Atom>
                )}
              </Heading>
              <Block style={{ color: '#555', marginTop: '-10px' }}>{org.aca.name}</Block>
            </Link>
          </Block>
        ))}
      </Modal.Body>
    </Modal>
  </Atom>
);

OrgSwitcherModal.propTypes = {
  showModal: PropTypes.bool,
  onShowModal: PropTypes.func,
  onHideModal: PropTypes.func,
  currentOrgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  switchableOrgs: PropTypes.array,
  orgListPending: PropTypes.bool,
};

export default OrgSwitcherModal;
