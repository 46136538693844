const mark = action => `RESET_PASSWORD/${action}`;

export const REQUEST_PASSWORD_RESET = mark('REQUEST_PASSWORD_RESET');
export const REQUEST_PASSWORD_RESET_DONE = mark('REQUEST_PASSWORD_RESET_DONE');
export const REQUEST_PASSWORD_RESET_FAIL = mark('REQUEST_PASSWORD_RESET_FAIL');

export const requestPasswordReset = (email, onSuccess) => ({
  type: REQUEST_PASSWORD_RESET,
  payload: { email },
  meta: { onSuccess },
});

export const requestPasswordResetDone = () => ({
  type: REQUEST_PASSWORD_RESET_DONE,
});

export const requestPasswordResetFail = error => ({
  type: REQUEST_PASSWORD_RESET_FAIL,
  payload: { error },
});

export const RESET_PASSWORD = mark('RESET_PASSWORD');
export const RESET_PASSWORD_DONE = mark('RESET_PASSWORD_DONE');
export const RESET_PASSWORD_FAIL = mark('RESET_PASSWORD_FAIL');

export const resetPassword = (payload, onSuccess) => ({
  type: RESET_PASSWORD,
  payload,
  meta: { onSuccess },
});

export const resetPasswordDone = () => ({
  type: RESET_PASSWORD_DONE,
});

export const resetPasswordFail = error => ({
  type: RESET_PASSWORD_FAIL,
  payload: { error },
});
