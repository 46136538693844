import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as ospDefReducer from 'sow/reducers/ospDefinitions';
// NOTE: In core selectors we only import other core selectors that have a parent
//       relationship to the core selector you're importing to. This is to stop
//       circular dependencies. For example ospApplication can import
//       organization core selectors but not worksheets/worksheetAnswers core
//       selectors. Instead we would import ospApplication core selectors into
//       worksheet/worksheetAnswers core selectors and compose selectors there.
//       For higher level selectors that compose multiple core selectors you
//       should create them in files directly inside soworganic/selectors/
import * as acaSelect from 'sow/selectors/core/aca';

export const ospDefState = state => state.ospDefinitions;

export const ospDefIdProp = (state, props) => R.path(['ospDefId'], props);

export const ospDefIdRouteParam = (state, props) => {
  const paramId = R.path(['match', 'params', 'ospDefId'], props);
  return paramId && parseInt(paramId);
};

export const isLoadingListForCurrentAca = createSelector(
  [ospDefState, acaSelect.currentAcaId],
  (state, acaId) => {
    return ospDefReducer.getIsLoadingForAca(state, acaId);
  },
);

export const isLoadingOneForCurrentAca = createSelector(
  [ospDefState, acaSelect.currentAcaId],
  (state, acaId) => {
    return ospDefReducer.getIsLoadingOneForAca(state, acaId);
  },
);

export const ospDefListForCurrentAca = createSelector(
  [ospDefState, acaSelect.currentAcaId],
  (state, acaId) => {
    return ospDefReducer.getOspDefsForAca(state, acaId);
  },
);

export const currentPublishedOspDefIdForCurrentAca = createSelector(
  [ospDefListForCurrentAca],
  ospDefReducer.currentPublishedOspDefId,
);

export const currentOspDefId = createSelector(
  [ospDefIdProp, ospDefIdRouteParam, currentPublishedOspDefIdForCurrentAca],
  (ospDefIdFromProp, ospDefIdFromRoute, ospDefIdForAca) => {
    return ospDefIdFromProp || ospDefIdFromRoute || ospDefIdForAca;
  },
);

export const currentOspDef = createSelector(
  [ospDefState, currentOspDefId],
  (state, ospDefId) => {
    return ospDefReducer.getOspDef(state, ospDefId);
  },
);

export const currentOspDefSectionIds = createSelector(
  [currentOspDef],
  ospDef => {
    return R.pathOr([], ['definition', 'sections'], ospDef);
  },
);

export const currentOspDefMiniOspSectionIds = createSelector(
  [currentOspDef],
  ospDef => {
    return R.reject(R.isNil, [
      R.view(ospDefReducer.landFacilitySectionLens, ospDef),
      R.view(ospDefReducer.landOfficeSectionLens, ospDef),
      R.view(ospDefReducer.landParcelSectionLens, ospDef),
      R.view(ospDefReducer.landPastureSectionLens, ospDef),
      R.view(ospDefReducer.productSectionLens, ospDef),
    ]);
  },
);
