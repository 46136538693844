import React from 'react';
import PropTypes from 'prop-types';

import {
  acaRoute,
  acaActivityRoute,
  acaCertificationDocsRoute,
  acaOspBaseRoute,
  acaOrgBaseRoute,
  acaSettingsRoute,
} from 'sow/routes';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import SidebarOrg from 'sow/components/organisms/SidebarOrg';
import IconWithText from 'sow/components/molecules/IconWithText';
import SidebarLink from 'sow/components/molecules/SidebarLink';

const SidebarAcaAdmin = ({
  aca,
  org,
  routerPush,
  isAcaOwner,
  isSuperAdmin,
  navClasses,
}) => (
  <Block className="sidebar-aca-admin">
    <List className={navClasses}>
      <ListItem className="sidebar-heading">
        <Atom style={{ cursor: 'pointer' }} onClick={() => routerPush(acaRoute(aca.id))}>
          {aca.name}
        </Atom>
      </ListItem>

      <SidebarLink to={acaRoute(aca.id)} exact>
        <IconWithText icon={<FontAwesome icon="star" />} text="Dashboard" />
      </SidebarLink>

      <SidebarLink to={acaOspBaseRoute(aca.id)}>
        <IconWithText icon={<FontAwesome icon="list-alt" />} text="Plan" />
      </SidebarLink>

      <SidebarLink to={acaCertificationDocsRoute(aca.id)}>
        <IconWithText
          icon={<FontAwesome variant="far" icon="file-alt" />}
          text="Certification Docs"
        />
      </SidebarLink>

      <SidebarLink to={acaOrgBaseRoute(aca.id)}>
        <IconWithText icon={<FontAwesome icon="users" />} text="Organizations" />
      </SidebarLink>
    </List>

    {org && <SidebarOrg aca={aca} org={org} navClasses={navClasses} />}

    <List className={navClasses}>
      <SidebarLink to={acaActivityRoute(aca.id)}>
        <IconWithText icon={<FontAwesome icon="list" />} text="Activity Log" />
      </SidebarLink>

      {(isSuperAdmin || isAcaOwner) && (
        <SidebarLink to={acaSettingsRoute(aca.id)}>
          <IconWithText icon={<FontAwesome icon="cog" />} text="Settings" />
        </SidebarLink>
      )}
    </List>
  </Block>
);

SidebarAcaAdmin.propTypes = {
  aca: PropTypes.object,
  org: PropTypes.object,
  routerPush: PropTypes.func.isRequired,
  isAcaOwner: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  navClasses: PropTypes.string,
};

export default SidebarAcaAdmin;
