import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentAca from 'sow/selectors/currentAca';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import {
  PlanAppSummaryLoader,
  PlanAppChangeRequestOverviewLoader,
} from 'sow/store/containers';
import Sidebar from 'sow/components/organisms/Sidebar';

const mapStateToProps = (state, props) => {
  const showAcaUI = currentUser.showAcaUI(state, props);
  const showInspectorUI = currentUser.showInspectorUI(state, props);
  const org = currentOrg.org(state, props);

  return {
    aca: currentAca.aca(state, props),
    showAdminUI: currentUser.showAdminUI(state, props),
    showOrgUI: currentUser.showOrgUI(state, props),
    showAcaUI: !showInspectorUI && showAcaUI,
    showInspectorUI: showInspectorUI,
    isCcof: currentAca.isCcof(state, props),

    // TODO organize this to more standard convention
    org,
    loadPlanAppSummary: !!org,
  };
};

const SidebarContainer = props => (
  <Fragment>
    <PlanAppChangeRequestOverviewLoader autoLoad={props.loadPlanAppSummary} />
    <PlanAppSummaryLoader autoLoad={props.loadPlanAppSummary} />

    <Sidebar
      aca={props.aca}
      org={props.org}
      showAcaUI={props.showAcaUI}
      showAdminUI={props.showAdminUI}
      showOrgUI={props.showOrgUI}
      showInspectorUI={props.showInspectorUI}
      isCcof={props.isCcof}
      navbarToggled={props.navbarToggled}
      onNavbarToggle={props.onNavbarToggle}
    />
  </Fragment>
);

SidebarContainer.propTypes = {
  // Adding the routes triggers a re-render of the sidebar when the route changes
  routes: PropTypes.array,
  aca: PropTypes.object,
  org: PropTypes.object,
  showAdminUI: PropTypes.bool,
  showOrgUI: PropTypes.bool,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  isCcof: PropTypes.bool,
  navbarToggled: PropTypes.bool,
  onNavbarToggle: PropTypes.func,
  loadPlanAppSummary: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(SidebarContainer);
