import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import bugsnagReact from '@bugsnag/plugin-react';
import { bugsnagClient } from 'sow/services/bugsnag';

bugsnagClient.use(bugsnagReact, React);

const BugsnagErrorBoundary = bugsnagClient.getPlugin('react');

/**
 * ErrorBoundryTestTrigger<Component>
 *
 * @desc Component for testing ErrorBoundary components with global
 *       `triggerErrorBoundary` function.
 **
 * HOW TO USE:
 * 1. Add this component wherever you want to triggr an error handled by an
 *    Error Boundary component higher up in the component tree.
 * 2. From the browser's console call: `window.triggerErrorBoundary()`
 */
export class ErrorBoundryTestTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showErrorBoundary: false };
    window.triggerErrorBoundary = this.windowTriggerErrorBoundary;
  }

  windowTriggerErrorBoundary = () => {
    this.setState({ showErrorBoundary: true });
  };

  render() {
    if (this.state.showErrorBoundary == true) {
      throw new Error('window.triggerErrorBoundary()');
    }

    return null;
  }
}

/**
 * DefaultFallbackComponent<Component>
 *
 * @desc Default component used for ErrorBoundary
 */
export const DefaultFallbackComponent = ({ error, info }) => (
  <div style={{ margin: 50 }}>
    <h2>Oops!!! Something went wrong.</h2>

    <p style={{ marginTop: 25 }}>
      <label>The error:</label>
      <code
        style={{
          fontSize: '1.3em',
          padding: 5,
          border: '1px solid',
          marginLeft: 3,
        }}
      >
        {error.toString()}
      </code>
    </p>

    <label>Where it occured:</label>
    <pre>{info.componentStack}</pre>
  </div>
);

DefaultFallbackComponent.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

/**
 * ErrorBoundary<Component>
 */
const ErrorBoundary = ({
  bugsnagBeforeSend,
  children,
  fallbackComponent = DefaultFallbackComponent,
  testTrigger = false,
}) => (
  <BugsnagErrorBoundary
    FallbackComponent={fallbackComponent}
    beforeSend={bugsnagBeforeSend}
  >
    <Fragment>
      {children}
      {testTrigger && <ErrorBoundryTestTrigger />}
    </Fragment>
  </BugsnagErrorBoundary>
);

ErrorBoundary.defaultProps = {
  testTrigger: false,
};

ErrorBoundary.propTypes = {
  /* Function called as Bugsnag notify's `beforeSend` */
  bugsnagBeforeSend: PropTypes.func,
  /* Components to catch errors for */
  children: PropTypes.element,
  /* Component shown on boundary error */
  fallbackComponent: PropTypes.element,
  /* If truthy, adds <ErrorBoundryTestTrigger /> for testing */
  testTrigger: PropTypes.bool,
};

// --
// -- Examples & Extra Info
// --

// const FallbackComponent = ({ error, info }) => <div>An error has occurred</div>;
//
// const SafeSomeComponent = ((
//   <ErrorBoundary FallbackComponent={FallbackComponent}>
//     <SomeComponent />
//   </ErrorBoundary>
// )

// For example how to handle non-render (async/etc) w/ ErrorBoundary
// Blog Post: https://tamouse.github.io/swaac/2018/03/30/til-react-error-boundary-workarounds/
// Example Cases
//  * Event handlers
//  * Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
//  * Server side rendering
//  * Errors thrown in the error boundary itself (rather than its children)

export default ErrorBoundary;
