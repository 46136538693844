import * as R from 'ramda';
import React from 'react';

export const generalFieldTypes = [
  ['text', 'Text'],
  ['number', 'Number'],
  ['checkbox_list', 'Checkbox List'],
  ['select', 'Select'],
  ['bool', 'Bool'],
  ['files', 'Files'],
  ['textarea', 'Textarea'],
  ['date', 'Date'],
  ['descriptive_text', 'Descriptive Text'],
];

export const sectionTypeFieldMap = {
  product: [['product_ingredient', 'Product Ingredient']],
};

export const sectionTypeFields = R.compose(
  R.concat(generalFieldTypes),
  R.propOr([], R.__, sectionTypeFieldMap),
);

export const renderOptions = ({ sectionType }) =>
  sectionTypeFields(sectionType).map(([type, label]) => (
    <option key={type} value={type}>
      {label}
    </option>
  ));

export const FieldTypeSelector = ({
  id,
  value,
  handleChange,
  className,
  sectionType,
}) => (
  <select
    id={id}
    value={value}
    onChange={handleChange}
    className={className}
    required={true}
  >
    {renderOptions({ sectionType })}
  </select>
);

export default FieldTypeSelector;
