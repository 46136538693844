import React from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import {
  orgSettingsAcaRoute,
  orgSettingsGeneralRoute,
  orgSettingsInspectorRoute,
  orgSettingsMigrateRoute,
} from 'sow/routes';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const OrgSettingsPageTemplate = ({
  orgId,
  showAcaUI,
  showAdminUI,
  isInspector,
  children,
}) => {
  const showInspectorPanel = showAcaUI && !isInspector;

  return (
    <OrgPageTemplate title="Organization Settings">
      <Nav tabs>
        {showAcaUI && <NavLink to={orgSettingsAcaRoute(orgId)}>ACA</NavLink>}
        <NavLink to={orgSettingsGeneralRoute(orgId)}>General</NavLink>
        {showInspectorPanel && (
          <NavLink to={orgSettingsInspectorRoute(orgId)}>Inspectors</NavLink>
        )}
        {showAdminUI && (
          <NavLink to={orgSettingsMigrateRoute(orgId)}>Migrate Organization</NavLink>
        )}
      </Nav>

      {children}
    </OrgPageTemplate>
  );
};

OrgSettingsPageTemplate.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  showAdminUI: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default OrgSettingsPageTemplate;
