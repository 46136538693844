import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { activityLogType } from 'sow/types';
import Table from 'sow/components/molecules/Table';
import PreformattedText from 'sow/components/atoms/PreformattedText';
import Atom from 'sow/components/atoms/Atom';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const isChangeAnswers = (val, key) => key !== 'answers';

const getLogChanges = R.compose(
  // remove answers because they are not supported
  R.pickBy(isChangeAnswers),
  R.path(['data', 'changes']),
);

// Needed until we create support for worksheet answer changes
const filterWorksheetAnswersKey = key => key != 'answers';

const ValueText = ({ value }) => {
  if (typeof value === 'object') {
    return <PreformattedText>{JSON.stringify(value)}</PreformattedText>;
  }

  return <Atom>{value}</Atom>;
};

ValueText.propTypes = {
  value: PropTypes.any,
};

const popover = changes => (
  <Popover id="activityLogChanges" style={{ maxWidth: 'none' }}>
    <Table
      condensed
      style={{ marginBottom: 0 }}
      head={
        <TableRow>
          <TableCell heading>Field</TableCell>
          <TableCell heading>Value</TableCell>
        </TableRow>
      }
    >
      {Object.keys(changes)
        .filter(filterWorksheetAnswersKey)
        .map(key => (
          <TableRow key={key}>
            <TableCell>{key}</TableCell>
            <TableCell style={{ maxWidth: '400px' }}>
              <ValueText value={changes[key].new_value} />
            </TableCell>
          </TableRow>
        ))}
    </Table>
  </Popover>
);

const LogChangesOverlayButton = ({ log }) => {
  if (!log) return null;
  const changes = getLogChanges(log);
  if (R.isEmpty(changes)) return null;

  return (
    <OverlayTrigger trigger="click" rootClose placement="left" overlay={popover(changes)}>
      <Button sm>
        <FontAwesome icon="bolt" />
      </Button>
    </OverlayTrigger>
  );
};

LogChangesOverlayButton.propTypes = {
  log: activityLogType.isRequired,
};

export default LogChangesOverlayButton;
