import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as sowTypes from 'sow/types';
import { planAppStateText } from 'sow/constants/planApp';
import {
  historicalPlansRoute,
  checklistRoute,
  worksheetOverviewRoute,
  locationOverviewRoute,
} from 'sow/routes';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import Clearfix from 'sow/components/atoms/Clearfix';
import Heading from 'sow/components/atoms/Heading';
import Spacer from 'sow/components/atoms/Spacer';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ProgressBar from 'sow/components/organisms/PlanAppProgressBar';
import PlanAppWhatsNext from 'sow/components/organisms/PlanAppWhatsNext';
import OrgWithdrawModal from 'sow/components/organisms/OrgWithdrawModal';
import OrgDashboardAcaOptions from 'sow/containers/planApp/OrgDashboardAcaOptions';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';
import CompletedPlanLink from 'sow/containers/planApp/CompletedPlanLink';

const CompletionWrapper = styled(Block)`
  float: right;
  text-align: right;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 20pt;
  font-weight: bold;
`;

const CompletedText = styled(Block)`
  margin-top: 0.4em;
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: normal;
`;

const SectionHeading = styled(Block)`
  font-size: 20pt;
  font-weight: bold;
  margin-bottom: 0.6em;
`;

const DescriptionText = styled(Block)`
  font-size: 11pt;
  line-height: 18pt;
`;

const PlanStateText = styled(Block)`
  font-size: 24pt;
  line-height: 28pt;
  padding: 5px;
  margin: 5px;
  color: ${({ theme, state }) => theme.planState[state]};
`;

const CompletionStatus = ({ status }) => (
  <CompletionWrapper>
    {status.completed}/{status.total}
    <CompletedText>completed</CompletedText>
  </CompletionWrapper>
);

CompletionStatus.propTypes = {
  status: PropTypes.shape({
    completed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

const OrgDashboardPage = ({
  orgId,
  planAppId,
  planApp,
  isInitial,
  isComplete,
  initialSummary,
  configPlanMinNumLocations,
  showAcaUI,
  planAppSummaryList,
}) => {
  const minLocationsText =
    configPlanMinNumLocations > 0
      ? `Your certifier requires that you create at least ${configPlanMinNumLocations} location${
          configPlanMinNumLocations === 1 ? '' : 's'
        }, but you may add as many as needed.`
      : 'You may add as many locations as needed.';

  const hasWorksheets =
    !isInitial ||
    R.pipe(
      R.pathOr(0, ['worksheets', 'total']),
      R.gt(R.__, 0),
    )(initialSummary);

  return (
    <Fragment>
      <Spacer vertical={30} />
      <Heading pageTitle noMargin>
        My Plan
      </Heading>
      <Spacer vertical={10} />

      <Row>
        <Column md={8}>
          {isInitial && (
            <Fragment>
              <Widget>
                <WidgetBody>
                  <ProgressBar
                    percentageComplete={R.prop('percentageComplete', planApp)}
                  />
                </WidgetBody>
              </Widget>
              <Spacer vertical={10} />
            </Fragment>
          )}

          <Widget>
            <WidgetBody>
              {isInitial && <CompletionStatus status={initialSummary.worksheets} />}
              <SectionHeading>Worksheets</SectionHeading>

              <DescriptionText>
                The main worksheets that must be completed as part of your
                organization&apos;s Plan. Based on your answers to the Activities
                Checklist, you will be shown a set of worksheets that are relevant to your
                operation.
              </DescriptionText>

              <Spacer vertical={10} />

              <Link to={checklistRoute(orgId, planAppId)} btn lg primary>
                <FontAwesome icon="tasks" /> Activity Checklist
              </Link>
              <Spacer horizontal={10} />
              <Link
                to={worksheetOverviewRoute(orgId, planAppId)}
                btn
                lg
                success
                disabled={!hasWorksheets}
              >
                <FontAwesome icon="wpforms" variant="fab" /> Plan Worksheets
              </Link>

              <Clearfix />
            </WidgetBody>
          </Widget>

          <Spacer vertical={10} />

          <Widget>
            <WidgetBody>
              {isInitial && <CompletionStatus status={initialSummary.lands} />}
              <SectionHeading>Locations</SectionHeading>

              <DescriptionText>
                Locations that are part of your organization&apos;s operation.{' '}
                {minLocationsText} Each location has its own set of worksheets that must
                be completed, depending on what kind of location it is.
              </DescriptionText>

              <Spacer vertical={10} />

              <Link to={locationOverviewRoute(orgId, planAppId)} btn lg success>
                <FontAwesome icon="map-marker-alt" /> Manage Locations
              </Link>
            </WidgetBody>
          </Widget>

          <Spacer vertical={20} />

          {!isInitial && (
            <ChangeRequestManager
              planAppId={planAppId}
              showPlanChecklist
              showPlanWorksheets
              showLocations
            />
          )}

          <Spacer vertical={20} />

          {planAppSummaryList.length > 1 && (
            // Show link to historical plans if they have more than 1
            <Fragment>
              <Link to={historicalPlansRoute(orgId)}>View previous plans</Link>
              <Spacer vertical={10} />
            </Fragment>
          )}

          <OrgWithdrawModal>
            {showModal => <Link onClick={showModal}>Need to withdraw your plan?</Link>}
          </OrgWithdrawModal>
        </Column>

        <Column md={4}>
          <Block textCenter>
            <Heading level={4} noMargin textMuted>
              Current Status
            </Heading>
            <PlanStateText state={planApp.state}>
              {planAppStateText[planApp.state]}
            </PlanStateText>
          </Block>

          <Spacer vertical={10} />
          <PlanAppWhatsNext planApp={planApp} hasWorksheets={hasWorksheets} />
          <Spacer vertical={20} />

          {showAcaUI && !isComplete && (
            <Fragment>
              <OrgDashboardAcaOptions planAppId={planAppId} planApp={planApp} />
              <Spacer vertical={20} />
            </Fragment>
          )}

          <Block textCenter>{!isComplete && <CompletedPlanLink />}</Block>
        </Column>
      </Row>
    </Fragment>
  );
};

OrgDashboardPage.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
  isInitial: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  initialSummary: sowTypes.planAppInitialSummaryType,
  configPlanMinNumLocations: PropTypes.number.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  planAppSummaryList: PropTypes.arrayOf(sowTypes.planAppSummaryType).isRequired,
};

OrgDashboardPage.defaultProps = {
  initialSummary: null,
};

export default OrgDashboardPage;
