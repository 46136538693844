import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as selectUtils from 'sow/utils/selectors';
import {
  landFacilitySectionLens,
  landOfficeSectionLens,
  landParcelSectionLens,
  landPastureSectionLens,
  productSectionLens,
  // qualQuestionsSectionLens,
} from 'sow/reducers/ospDefinitions';

export const baseState = state => state.OspDefEditor;

export const editor = createSelector(
  [baseState],
  R.path(['OspDefEditor']),
);

export const editorIsDirty = createSelector(
  [editor],
  R.path(['isDirty']),
);

export const ospDef = createSelector(
  [baseState],
  R.path(['ospDef']),
);

export const ospQualificationQuestions = createSelector(
  [baseState],
  R.path(['ospQualificationQuestions']),
);

export const ospSections = createSelector(
  [baseState],
  R.path(['ospSections']),
);

export const staticWorksheetIds = createSelector(
  [ospDef],
  ospDef => {
    return R.reject(R.isNil, [
      R.view(landFacilitySectionLens, ospDef),
      R.view(landOfficeSectionLens, ospDef),
      R.view(landParcelSectionLens, ospDef),
      R.view(landPastureSectionLens, ospDef),
      R.view(productSectionLens, ospDef),
    ]);
  },
);

export const worksheets = createSelector(
  [baseState],
  R.path(['worksheets']),
);

export const worksheetQuestions = createSelector(
  [baseState],
  R.path(['worksheetQuestions']),
);

export const scopeWorksheetsEditOspSectionId = createSelector(
  [baseState],
  R.path(['ospSections', 'scopeWorksheetsEdit']),
);

// Selector Factories
export const makeOspQQSelector = () => {
  return selectUtils.itemByIdPropFactory(
    ospQualificationQuestions,
    'ospQualificationQuestionId',
  );
};

export const makeOspSectionSelector = () => {
  return selectUtils.itemByIdPropFactory(ospSections, 'ospSectionId');
};

export const makeWorksheetSelector = () => {
  return selectUtils.itemByIdPropFactory(worksheets, 'worksheetId');
};

export const makeWorksheetQuestionSelector = () => {
  return selectUtils.itemByIdPropFactory(worksheetQuestions, 'questionId');
};

export const makeWorksheetQuestionListSelector = () => {
  return selectUtils.itemListByIdListPropFactory(worksheetQuestions, 'wsQuestionIds');
};
