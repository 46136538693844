import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { push as routerPush } from 'connected-react-router';

import * as schema from 'sow/schema';
import actions from 'sow/actions/pure';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';
import { confirmSaga } from 'sow/sagas/shell';
import { resourceListReadRequest } from 'sow/store/actions';

export function* loadList() {
  try {
    yield put.resolve(resourceListReadRequest('org', {}, 'org'));
  } catch (error) {
    yield put(actions.org.loadListFail(error));
  }
}

export function* loadListForAca(acaId) {
  try {
    const { response, error } = yield call(apiGet, `/org`, { aca_id: acaId });

    if (response) {
      const { data } = response;
      const entity = yield call(normalize, data, [schema.organization]);
      yield put(actions.org.loadAcaListDone(entity));
    }

    if (error) {
      yield put(actions.org.loadAcaListFail(error));
    }
  } catch (error) {
    yield put(actions.org.loadAcaListFail(error));
  }
}

export function* activateOrg(orgId) {
  try {
    const { response, error } = yield call(apiPost, `/org/${orgId}/activate`);

    if (response) {
      const entity = yield call(normalize, response.data, schema.organization);
      yield put(actions.org.loadOneDone(entity));
    }
  } catch (error) {
    yield put(actions.org.loadOneFail(orgId, error));
  }
}

export function* deactivateOrg(orgId) {
  try {
    const { response, error } = yield call(apiPost, `/org/${orgId}/deactivate`);

    if (response) {
      const entity = yield call(normalize, response.data, schema.organization);
      yield put(actions.org.loadOneDone(entity));
    }
  } catch (error) {
    yield put(actions.org.loadOneFail(orgId, error));
  }
}

export function* loadOrg(orgId) {
  try {
    const { response, error } = yield call(apiGet, `/org/${orgId}`);

    if (response) {
      const entity = yield call(normalize, response.data, schema.organization);
      yield put(actions.org.loadOneDone(entity));
    }

    if (error) {
      yield put(actions.org.loadOneFail(orgId, error));
    }
  } catch (error) {
    yield put(actions.org.loadOneFail(orgId, error));
  }
}

export function* updateOrg(org) {
  try {
    const { response, error } = yield call(apiPut, `/org/${org.id}`, {
      organization: org,
    });

    if (response) {
      const entity = yield call(normalize, response.data, schema.organization);
      yield put(actions.org.updateOneDone(entity));
    }

    if (error) {
      yield put(actions.org.updateOneFail(error));
    }
  } catch (error) {
    yield put(actions.org.updateOneFail(error));
  }
}

export function* watchLoadList() {
  try {
    yield call(loadList);
  } catch (error) {
    console.error(error);
  }
}

export function* watchLoadListForAca({ payload }) {
  const { acaId } = payload;
  try {
    yield call(loadListForAca, acaId);
  } catch (error) {
    console.error(error);
  }
}

export function* watchActivateOrg({ payload }) {
  const { orgId } = payload;

  const confirmed = yield call(confirmSaga, {
    title: 'Activate Organization',
    message: 'Are you sure you want to activate this organization?',
    yesButtonText: 'Activate',
  });

  if (confirmed) {
    yield call(activateOrg, orgId);
  }
}

export function* watchDeactivateOrg({ payload }) {
  const { orgId } = payload;

  const confirmed = yield call(confirmSaga, {
    title: 'Deactivate Organization',
    message: 'Are you sure you want to deactivate this organization?',
    yesButtonText: 'Deactivate',
  });

  if (confirmed) {
    yield call(deactivateOrg, orgId);
  }
}

export function* watchLoadOrg({ payload }) {
  const { orgId } = payload;

  try {
    yield call(loadOrg, orgId);
  } catch (error) {
    console.error(error);
  }
}

export function* watchLoadOrgFail({ payload }) {
  try {
    const statusCode = R.path(['status'], payload);

    if (statusCode == 403) {
      console.warn('Redirecting to root path after 403 on org load');
      yield put(routerPush('/'));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchUpdateOrg({ meta, payload }) {
  try {
    yield call(updateOrg, payload.org);

    if (meta && typeof meta.onSuccess === 'function') {
      yield call(meta.onSuccess);
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  try {
    yield takeEvery(actions.org.activate, watchActivateOrg);
    yield takeEvery(actions.org.deactivate, watchDeactivateOrg);
    yield takeEvery(actions.org.loadOne, watchLoadOrg);
    yield takeEvery(actions.org.loadOneFail, watchLoadOrgFail);
    yield takeEvery(actions.org.updateOne, watchUpdateOrg);
    yield takeLatest(actions.org.loadAcaList, watchLoadListForAca);
    yield takeEvery(actions.org.loadList, watchLoadList);
  } catch (error) {
    console.error(error);
  }
}
