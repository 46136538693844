import { all, call, put, takeEvery } from 'redux-saga/effects';
import { consoleErrorRecovery, safeSaga } from 'sow/sagas/helpers';
import actions from 'sow/actions/pure';
import { confirmSaga } from 'sow/sagas/shell';
import {
  resourceCreateRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
} from 'sow/store/actions';

// -- worker sagas

export function* assignAccess(orgId, inspectorId) {
  yield put.resolve(
    resourceCreateRequest(
      `org/${orgId}/inspector_access`,
      {
        inspector_access: {
          inspector_id: ~~inspectorId,
          complete: false,
        },
      },
      'inspectorAccess',
    ),
  );
}

export function* loadAccessList(orgId) {
  yield put.resolve(
    resourceListReadRequest(`org/${orgId}/inspector_access`, {}, 'inspectorAccess'),
  );
}

export function* loadList() {
  yield put.resolve(resourceListReadRequest('inspector', {}, 'inspector'));
}

export function* revokeAccess(orgId, inspectorId) {
  yield put.resolve(
    resourceUpdateRequest(
      `org/${orgId}/inspector_access`,
      inspectorId,
      { inspector_access: { complete: true } },
      'inspectorAccess',
    ),
  );
}

// -- WATCHER SAGAS

export function* watchAssignAccess({ payload }) {
  const { orgId, inspectorId } = payload;

  const confirmed = yield call(confirmSaga, {
    title: 'Assign Inspector to Organization',
    message: 'Are you sure you want to assign inspector?',
    yesButtonText: 'Assign',
  });

  if (confirmed) {
    yield call(assignAccess, orgId, inspectorId);
  }
}

export function* watchLoadAccessList({ payload }) {
  const { orgId } = payload;

  yield call(loadAccessList, orgId);
}

export function* watchLoadList() {
  yield call(loadList);
}

export function* watchRevokeAccess({ payload }) {
  const { orgId, inspectorId } = payload;

  const confirmed = yield call(confirmSaga, {
    title: 'Remove Inspector Access to Organization',
    message: 'Are you sure you want to remove inspector?',
    yesButtonText: 'Remove',
  });

  if (confirmed) {
    yield call(revokeAccess, orgId, inspectorId);
  }
}

// -- ROOT SAGA

export default function* rootSaga() {
  const safe = safeSaga(consoleErrorRecovery);

  try {
    yield all([
      takeEvery(actions.inspector.assignAccess, safe(watchAssignAccess)),
      takeEvery(actions.inspector.loadAccessList, safe(watchLoadAccessList)),
      takeEvery(actions.inspector.loadList, safe(watchLoadList)),
      takeEvery(actions.inspector.revokeAccess, safe(watchRevokeAccess)),
    ]);
  } catch (error) {
    console.error('Unhandled error in sagas/inspector/rootSaga!', error);
  }
}
