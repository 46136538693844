import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userIdType } from 'sow/types';
import actions from 'sow/actions/pure';
import { getProfileState } from 'sow/reducers/profile';
import ProfilePageTemplate from 'sow/components/templates/ProfilePageTemplate';
import ProfileMyProfilePage from 'sow/components/pages/ProfileMyProfilePage';

const mapStateToProps = state => ({
  user: state.auth.metadata,
  userId: state.auth.user_id,
  isUpdating: getProfileState(state).isUpdating,
  updateErrors: getProfileState(state).updateErrors,
});

const mapDispatchToProps = {
  updateUser: actions.profile.updateUser,
};

function ProfileMyProfilePageContainer({
  userId,
  user,
  updateUser,
  isUpdating,
  updateErrors,
}) {
  function handleSubmit(user, onUpdateSuccess) {
    const newValues = {
      name: user.name,
      email: user.username,
    };

    // Only include password if something was entered
    if (user.password) {
      newValues.old_password = user.old_password;
      newValues.password = user.password;
    }

    updateUser(userId, newValues, onUpdateSuccess);
  }

  return (
    <ProfilePageTemplate>
      <ProfileMyProfilePage
        user={user}
        onSubmit={handleSubmit}
        isUpdating={isUpdating}
        updateErrors={updateErrors}
      />
    </ProfilePageTemplate>
  );
}

ProfileMyProfilePageContainer.propTypes = {
  user: PropTypes.object.isRequired,
  userId: userIdType.isRequired,
  updateUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  updateErrors: PropTypes.object,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProfileMyProfilePageContainer);
