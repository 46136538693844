import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FileUploadQueueItem from 'sow/components/old/fileUpload/FileUploadQueueItem';
import { getFileUploadState } from 'sow/reducers/fileUpload';
import {
  addToFileQueue,
  uploadFileQueue,
  removeFromFileQueue,
} from 'sow/actions/fileUpload';
import {
  hasValidFilename,
  formatItemForQueue,
  formatBadItemForQueue,
} from 'sow/utils/fileUpload';

const mapStateToProps = state => ({
  queue: getFileUploadState(state).fileQueue,
});

const mapDispatchToProps = {
  addToFileQueue,
  uploadFileQueue,
  removeFromFileQueue,
};

class FileUpload extends Component {
  static propTypes = {
    addToFileQueue: PropTypes.func,
    onUploadStart: PropTypes.func,
    onUploadSuccess: PropTypes.func,
    queue: PropTypes.array,
    removeFromFileQueue: PropTypes.func,
    uploadFileQueue: PropTypes.func,
    uploadUrl: PropTypes.string,
  };

  handleDrop = files => {
    const { addToFileQueue, uploadUrl, autoUpload } = this.props;

    files.forEach(file => {
      if (hasValidFilename(file)) {
        addToFileQueue(formatItemForQueue(file, uploadUrl));
      } else {
        // Add a failed item to the queue for rendering the errors -- this won't upload
        addToFileQueue(formatBadItemForQueue(file));
      }
    });
  };

  handleSubmit = event => {
    if (event) event.preventDefault();

    const { onUploadStart, onUploadSuccess, queue, uploadFileQueue } = this.props;

    if (typeof onUploadStart === 'function') {
      onUploadStart();
    }

    uploadFileQueue(queue, onUploadSuccess);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.autoUpload &&
      this.canUpload(this.props.queue) &&
      !this.canUpload(prevProps.queue)
    ) {
      this.handleSubmit();
    }
  }

  canUpload = queue => {
    return queue.filter(i => i.status === 'pending').length > 0;
  };

  hanleRemoveFromQueue = item => {
    const { removeFromFileQueue } = this.props;
    const { guid } = item;

    return event => {
      removeFromFileQueue(guid);
    };
  };

  render() {
    const { queue, autoUpload } = this.props;

    return (
      <div>
        <Dropzone onDrop={this.handleDrop} className="drop-zone">
          <p>Drop files here or click to browse.</p>
        </Dropzone>

        <form onSubmit={this.handleSubmit}>
          {queue.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>File:</th>
                  <th>Progress:</th>
                </tr>
              </thead>
              <tbody>
                {queue.map(item => (
                  <FileUploadQueueItem
                    key={item.guid}
                    item={item}
                    onRemoveFromQueue={this.hanleRemoveFromQueue(item)}
                  />
                ))}
              </tbody>
            </table>
          )}

          {!autoUpload && (
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!this.canUpload(queue)}
              >
                Upload
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileUpload);
