import PropTypes from 'prop-types';

import { planAppStates } from 'sow/constants/planApp';

export const datetimeType = PropTypes.string;
export const emailType = PropTypes.string;
export const phoneNumberType = PropTypes.string;
export const timezoneType = PropTypes.string;
export const uuidType = PropTypes.string;

// react-router: https://reacttraining.com/react-router/web/api/Link/to-string
export const linkToType = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

// --
// -- User Types
// --

export const userIdType = PropTypes.number;

// userType represents the object(s) returned from the /org/:org_id/users API endpoint
export const userType = PropTypes.shape({
  id: userIdType.isRequired,
  email: emailType.required,
  name: PropTypes.string,
  isAccountLocked: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  ecert_pk: PropTypes.string,
  settings: PropTypes.object,
});

// userLimitedType represents the object(s) returned to ORG USERS hitting the /org/:org_id/users API endpoint
export const userLimitedType = PropTypes.shape({
  id: userIdType.isRequired,
  email: emailType.required,
  name: PropTypes.string,
  isAccountLocked: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
});

export const userLimitedListType = PropTypes.arrayOf(userLimitedType);

// authUserType represents the user object return in header for authorization purposes
export const authUserType = PropTypes.shape({
  id: userIdType.isRequired,
  username: emailType.required,
  name: PropTypes.string,
  acas: PropTypes.arrayOf(acaIdType),
  orgs: PropTypes.arrayOf(orgIdType),
  orgs_v2: PropTypes.array,
  settings: PropTypes.object,
});

export const locationIdType = PropTypes.number;
export const locationType = PropTypes.shape({
  id: locationIdType,
  // Older locations may have the entire location set as single string in `addressText`
  addressText: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
});

// --
// -- File Types
// --

export const fileIdType = PropTypes.number;

export const fileIdListType = PropTypes.arrayOf(fileIdType);

// --
// -- Worksheet Question Types
// --
export const worksheetQuestionIdType = uuidType;
export const planAppWorksheetQuestionMatrixRowIdType = uuidType;
export const planAppWorksheetQuestionMatrixRowIdTListType = PropTypes.arrayOf(
  planAppWorksheetQuestionMatrixRowIdType,
);

export const worksheetQuestionConditionTypeBasicType = PropTypes.shape({
  condition_type: PropTypes.oneOf(['basic']),
  condition: PropTypes.shape({
    question_uuid: worksheetQuestionIdType.isRequired,
    answer_value: PropTypes.any.isRequired,
  }),
});

export const worksheetQuestionConditionTypes = PropTypes.oneOfType([
  worksheetQuestionConditionTypeBasicType,
]);

export const worksheetQuestionFieldTypeType = PropTypes.oneOf([
  'text',
  'number',
  'checkbox_list',
  'select',
  'checkbox',
  'bool',
  'files',
  'textarea',
  'date',
  'descriptive_text',
]);

export const worksheetQuestionType = PropTypes.shape({
  uuid: worksheetQuestionIdType,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  conditions: PropTypes.arrayOf(worksheetQuestionConditionTypes),
  field_type: worksheetQuestionFieldTypeType,
  name: PropTypes.string,
  notes: PropTypes.string,
  scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
  tooltip: PropTypes.string,
});

// --
// -- Worksheet Types
// --
export const worksheetIdType = uuidType;
// TODO add worksheet type

// --
// -- OSP Application Types (DEPRECIATED once change request refactor released)
// --
export const ospAppIdType = PropTypes.number;
export const ospAppStateType = PropTypes.oneOf(Object.values(planAppStates));

// --
// -- Plan Types
// --
export const planAppIdType = PropTypes.number;
export const planAppStateType = PropTypes.oneOf(Object.values(planAppStates));

export const planAppType = PropTypes.shape({
  id: planAppIdType,
  state: planAppStateType.isRequired,
  // TODO fill these in
});

export const planAppListType = PropTypes.arrayOf(planAppType);

export const planAppQualificationQuestionIdType = PropTypes.string; // uuid
const qualificationQuestionShape = {
  uuid: planAppQualificationQuestionIdType,
  question: PropTypes.string,
  references: PropTypes.arrayOf(planAppQualificationQuestionIdType),
  worksheets: PropTypes.arrayOf(worksheetIdType),
};
export const planAppQualificationQuestionType = PropTypes.shape({
  ...qualificationQuestionShape,
  subQuestions: PropTypes.arrayOf(PropTypes.shape(qualificationQuestionShape)),
});
export const planAppQualificationAnswerType = planAppQualificationQuestionIdType;

export const planAppWorksheetQuestionIdType = PropTypes.string; // uuid

export const planAppWorksheetQuestionType = PropTypes.shape({
  uuid: planAppWorksheetQuestionIdType,
  fieldType: PropTypes.string,
  name: PropTypes.string,
  notes: PropTypes.string,
  scopes: PropTypes.arrayOf(PropTypes.string), // todo: confirm it's an array of strings
  tooltip: PropTypes.string,
});

export const planAppWorksheetQuestionListType = PropTypes.arrayOf(
  planAppWorksheetQuestionType,
);

export const planAppWorksheetIdType = PropTypes.string; // uuid
export const planAppWorksheetType = PropTypes.shape({
  uuid: planAppWorksheetIdType,
  header: PropTypes.string,
  matrix: PropTypes.bool,
  name: PropTypes.string,
  notApplicable: PropTypes.bool,
  regulations: PropTypes.arrayOf(PropTypes.object),
  questions: PropTypes.oneOfType([
    // Questions may now be normalized so will be a string of question uuids
    PropTypes.arrayOf(planAppWorksheetQuestionType),
    PropTypes.arrayOf(planAppWorksheetQuestionIdType),
  ]),
});

export const planAppWorksheetSectionIdType = PropTypes.string; // uuid
export const planAppWorksheetSectionType = PropTypes.shape({
  uuid: planAppWorksheetSectionIdType,
  name: PropTypes.string,
  // Worksheets may be normalized now, so may be a string of question uuids
  worksheets: PropTypes.oneOfType([
    PropTypes.arrayOf(planAppWorksheetType),
    PropTypes.arrayOf(planAppWorksheetIdType),
  ]),
});

export const planAppWorksheetAnswersType = PropTypes.shape({
  worksheetUuid: planAppWorksheetIdType,
  ospApplicationId: planAppIdType,
  reviewForRenewal: PropTypes.bool,
  readyForReview: PropTypes.bool,
  isNotApplicable: PropTypes.bool,
  answers: PropTypes.shape({
    values: PropTypes.object,
    matrixRows: PropTypes.object,
  }),
});

export const planAppWorksheetAnswersListType = PropTypes.arrayOf(
  planAppWorksheetAnswersType,
);

export const planAppWorksheetAnswersMapType = PropTypes.objectOf(
  planAppWorksheetAnswersType,
);

// --
// -- Plan Location Types
// --

export const planAppLocationGeomCoordinateType = PropTypes.number;
export const planAppLocationGeomType = PropTypes.shape({
  lat: planAppLocationGeomCoordinateType,
  lng: planAppLocationGeomCoordinateType,
});

export const planAppLocationIdType = PropTypes.number;
export const planAppLocationIdListType = PropTypes.arrayOf(planAppLocationIdType);
export const planAppLocationType = PropTypes.shape({
  id: planAppLocationIdType,
  applicationId: planAppIdType,
  geom: planAppLocationGeomType,
  name: PropTypes.string,
  location: locationType,
  type: PropTypes.oneOf(['Parcel', 'Office', 'Facility', 'Pasture']),
  scope: PropTypes.string,
  excludedFromPlan: PropTypes.bool,
});

// Location Answers
export const planAppLocationAnswersType = PropTypes.shape({
  answers: PropTypes.shape({
    worksheets: planAppWorksheetAnswersMapType,
  }),
  applicationId: planAppIdType,
  id: PropTypes.number,
  insertedAt: datetimeType,
  organizationId: orgIdType,
  parentId: planAppLocationIdType,
  readyForReview: PropTypes.bool,
  reviewForRenewal: PropTypes.bool,
  updatedAt: datetimeType,
});

// --
// -- Plan Product Types
// --

export const planAppProductIdType = PropTypes.number;
export const planAppProductType = PropTypes.shape({
  id: planAppProductIdType,
  applicationId: planAppIdType,
  name: PropTypes.string,
  category: PropTypes.string,
  type: PropTypes.string,
  lands: planAppLocationType,
});

// --
// -- ACA Types
// --
export const acaIdType = PropTypes.number;
export const acaConfigType = PropTypes.shape({
  notifications: PropTypes.shape({
    email_targets: PropTypes.arrayOf(emailType),
    notify_on: PropTypes.shape({
      application_state: PropTypes.bool,
      mini_osp: PropTypes.bool,
      notes: PropTypes.bool,
      organization: PropTypes.bool,
      worksheet_updates: PropTypes.bool,
    }),
  }),
  // scope settings
  facility_scopes: PropTypes.arrayOf(PropTypes.string),
  office_scopes: PropTypes.arrayOf(PropTypes.string),
  parcel_scopes: PropTypes.arrayOf(PropTypes.string),
  pasture_scopes: PropTypes.arrayOf(PropTypes.string),
  visible_types: PropTypes.arrayOf(PropTypes.string),
});

export const acaSettingsType = PropTypes.shape({});

export const acaType = PropTypes.shape({
  id: acaIdType,
  hidden: PropTypes.bool,
  configuration: acaConfigType,
  logo_url: PropTypes.string,
  name: PropTypes.string,
  settings: acaSettingsType,
  timezone: timezoneType,
});

export const acaAdminType = PropTypes.shape({
  id: userIdType.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
});

// --
// -- Organization Types
// --
export const orgSettingsType = PropTypes.shape({
  aca_byid: PropTypes.object,
});

export const orgIdType = PropTypes.number;
export const orgType = PropTypes.shape({
  id: orgIdType,
  aca_id: acaIdType,
  aca: acaType,
  active: PropTypes.bool.isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      id: ospAppIdType.isRequired,
      renewal_date: datetimeType,
      state: ospAppStateType.isRequired,
    }),
  ),
  contact_name: PropTypes.string,
  contact_phone: phoneNumberType,
  ecert_pk: PropTypes.string,
  location: locationType,
  name: PropTypes.string,
  acaSettings: acaConfigType,
  configuration: PropTypes.object,
  settings: orgSettingsType,
});

// --
// -- Activity Log Types
// --
export const activityLogIdType = PropTypes.number;
export const activityLogType = PropTypes.shape({
  id: activityLogIdType.isRequired,
  user: PropTypes.shape({
    id: userIdType.required,
    name: PropTypes.string.required,
    email: emailType.required,
  }),
  organization: PropTypes.shape({
    id: userIdType.required,
    name: PropTypes.string.required,
  }),
  data: PropTypes.shape({
    worksheet_id: worksheetIdType,
    url: PropTypes.string,
    changes: PropTypes.shape({
      answers: PropTypes.shape({
        old_value: PropTypes.object,
        new_value: PropTypes.object,
      }),
    }),
  }),
  log_type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  inserted_at: datetimeType.isRequired,
});

// --
// -- Auth Types
// --
export const authCredentialsType = PropTypes.shape({
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  remember_me: PropTypes.bool.isRequired,
});

// --
// -- OSP Definition Types
// --

export const ospDefIdType = PropTypes.number;
// todo: shape these objects
export const ospDefType = PropTypes.object;
export const ospQualificationQuestionType = PropTypes.object;
export const ospSectionType = PropTypes.object;
export const worksheetType = PropTypes.object;
export const ospDefUserType = PropTypes.shape({
  isSuperAdmin: PropTypes.bool,
  isAcaAdmin: PropTypes.bool,
  isAdminXrayMode: PropTypes.bool,
});

// --
// -- Change Request Types
// --

export const planAppChangeRequestIdType = PropTypes.number;
export const planAppChangeIdType = PropTypes.number;
export const planAppChangeCommentIdType = PropTypes.number;
export const planAppChangeCommentUserIdType = PropTypes.number;

export const planAppChangeRequestStateType = PropTypes.oneOf([
  'unsubmitted',
  'submitted',
  'applied',
  'withdrawn',
]);

export const planAppChangeActionType = PropTypes.oneOf(['added', 'updated', 'deleted']);
export const planAppChangeContextType = PropTypes.oneOf(['main', 'product', 'land']);
export const planAppChangeStateType = PropTypes.oneOf([
  'open',
  'accepted',
  'rejected',
  'applied',
  'not_applied',
]);
export const planAppChangeTypeType = PropTypes.oneOf([
  'self',
  'plan',
  'worksheet',
  'worksheet_answer',
  'matrix_row',
  'matrix_row_answer',
]);

export const planAppChangeRequestType = PropTypes.shape({
  id: planAppChangeRequestIdType,
  acaId: acaIdType,
  applicationId: planAppIdType,
  organizationId: orgIdType,
  state: planAppChangeRequestStateType,
});

export const planAppChangeCommentUserType = PropTypes.shape({
  id: planAppChangeCommentUserIdType,
  name: PropTypes.string,
});

export const planAppChangeCommentType = PropTypes.shape({
  id: planAppChangeCommentIdType,
  changeId: planAppChangeIdType,
  user: planAppChangeCommentUserType,
  text: PropTypes.string,
  isDeleted: PropTypes.bool,
  notifyAca: PropTypes.bool.isRequired,
  notifyOrg: PropTypes.bool.isRequired,
});

export const planAppChangeCommentListType = PropTypes.arrayOf(planAppChangeCommentType);
export const planAppChangeCommentIdListType = PropTypes.arrayOf(
  planAppChangeCommentIdType,
);

export const planAppChangeType = PropTypes.shape({
  id: planAppChangeIdType,
  changeRequestId: planAppChangeRequestIdType,
  applicationId: planAppIdType,
  productId: planAppProductIdType,
  landId: planAppLocationIdType,
  worksheetId: planAppWorksheetIdType,
  matrixRowId: planAppWorksheetQuestionMatrixRowIdType,
  questionId: worksheetQuestionIdType,

  action: planAppChangeActionType.isRequired,
  context: planAppChangeContextType.isRequired,
  state: planAppChangeStateType.isRequired,
  type: planAppChangeTypeType.isRequired,

  old: PropTypes.any,
  new: PropTypes.any,

  comments: PropTypes.arrayOf(planAppChangeCommentIdType),
});

export const planAppChangeListType = PropTypes.arrayOf(planAppChangeType);

export const planAppSummaryType = PropTypes.shape({
  id: planAppIdType,
  organizationId: orgIdType,
  changeRequest: planAppChangeRequestIdType,
  ospDefinition: ospDefType,
  state: planAppStateType,
  renewalDate: PropTypes.string,
  insertedAt: PropTypes.string,
});

export const planAppSummaryListType = PropTypes.arrayOf(planAppSummaryType);

export const planAppNoteIdType = PropTypes.number;
export const planAppNoteType = PropTypes.shape({
  id: planAppNoteIdType.isRequired,
  uuid: uuidType.isRequired,
  subUuid: uuidType, // Used by matrix rows
  authorId: userIdType.isReuired,
  landId: planAppLocationIdType,
  productId: planAppProductIdType,
  author: PropTypes.string.isRequired,
  text: PropTypes.string,
  isAcaNote: PropTypes.bool,
  isAdminNote: PropTypes.bool,
  isDeleted: PropTypes.bool,
  public: PropTypes.bool,
  createdAt: datetimeType,
  insertedAt: datetimeType,
  updatedAt: datetimeType,
});

export const planAppNoteListType = PropTypes.arrayOf(planAppNoteType);

// --
// -- Plan App Export Types
// --

export const planAppExportConfigType = PropTypes.shape({
  orgId: orgIdType.isRequired,
  planAppId: PropTypes.number,
  showMainPlan: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
  addNotes: PropTypes.bool.isRequired,
  addComments: PropTypes.bool.isRequired,
  addFiles: PropTypes.bool.isRequired,
});

export const planAppInitialSummaryType = PropTypes.shape({});
