import * as R from 'ramda';
import { createSelector } from 'reselect';

export const authState = state => state.auth;

export const isAuthenticating = createSelector(
  [authState],
  R.prop('isAuthenticating'),
);

export const isValidating = createSelector(
  [authState],
  R.prop('isValidating'),
);

export const metadata = createSelector(
  [authState],
  R.prop('metadata'),
);

export const roles = createSelector(
  [authState],
  R.prop('roles'),
);

export const userId = createSelector(
  [authState],
  R.prop('user_id'),
);

// metadata based
export const acaIds = createSelector(
  [metadata],
  R.propOr([], 'acas'),
);

export const acaId = createSelector(
  [acaIds],
  R.head,
);

export const orgIds = createSelector(
  [metadata],
  R.propOr([], 'orgs'),
);

export const orgId = createSelector(
  [orgIds],
  R.head,
);

export const name = createSelector(
  [metadata],
  R.prop('name'),
);

export const username = createSelector(
  [metadata],
  R.prop('username'),
);

export const settings = createSelector(
  [metadata],
  R.prop('settings'),
);
