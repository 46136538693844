import React from 'react';
import ReactDatetime from 'react-datetime';
import PropTypes from 'prop-types';

import Form from 'sow/components/atoms/Form';
import FormGroup from 'sow/components/organisms/FormGroup';
import Button from 'sow/components/atoms/Button';
import CurrentAcaOrgSelector from 'sow/components/organisms/CurrentAcaOrgSelector';
import ActivityLogActionTypeSelector from 'sow/components/organisms/ActivityLogActionTypeSelector';

const noop = () => { };

const AcaActivityLogFilters = ({ filters, errors, onChange, onResetFilters }) => (
  <Form inline onSubmit={noop}>
    <FormGroup fieldName="start_datetime" errors={errors} label="From Date">
      <ReactDatetime
        id="start_datetime"
        value={filters.start_datetime}
        onChange={onChange('start_datetime')}
        closeOnSelect={true}
      />
    </FormGroup>{' '}
    <FormGroup fieldName="end_datetime" errors={errors} label="To Date">
      <ReactDatetime
        id="end_datetime"
        value={filters.end_datetime}
        onChange={onChange('end_datetime')}
        closeOnSelect={true}
      />
    </FormGroup>{' '}
    <FormGroup fieldName="organization_ids" errors={errors} label="Organization">
      <br />
      <CurrentAcaOrgSelector
        id="organization_ids"
        value={filters.organization_ids.length ? filters.organization_ids[0] : ''}
        onChange={onChange('organization_ids')}
      />
    </FormGroup>{' '}
    <FormGroup fieldName="action" errors={errors} label="Action Type">
      <br />
      <ActivityLogActionTypeSelector
        value={filters.action}
        onChange={event => onChange('action')(event.target.value)}
      />
    </FormGroup>{' '}
    <Button sm onClick={onResetFilters} style={{ marginTop: '23px' }}>
      Reset Filters
    </Button>
  </Form>
);

AcaActivityLogFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default AcaActivityLogFilters;
