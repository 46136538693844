import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentOrg from 'sow/selectors/currentOrg';
import { resourceListRead } from 'sow/store/helpers';
import actions from 'sow/actions/pure';
import OrgSettingsInspectors from 'sow/components/organisms/OrgSettingsInspectors';

const inspectorAccessListUrl = orgId => `org/${orgId}/inspector_access`;

const inspectorListResource = resourceListRead('inspector', 'inspector');
const inspectorAccessListResource = orgId =>
  resourceListRead(inspectorAccessListUrl(orgId), 'inspectorAccess');

const mapStateToProps = (state, props) => {
  const currentOrgId = currentOrg.orgId(state, props);

  const { selectors: inspectorListSelectors } = inspectorListResource;
  const inspectorListResult = inspectorListSelectors.result(state);

  const { selectors: inspectorAccessListSelectors } = inspectorAccessListResource(
    currentOrgId,
  );
  const inspectorAccessListResult = inspectorAccessListSelectors.result(state);

  const notCompleteInspectorAccessList = R.filter(
    R.pathEq(['complete'], false),
    inspectorAccessListResult,
  );

  const isInspectorAssigned = R.contains(
    R.__,
    R.map(R.path(['inspector', 'id']), notCompleteInspectorAccessList),
  );

  return {
    orgId: currentOrgId,
    inspectorAccessList: notCompleteInspectorAccessList,
    inspectorAccessListPending: inspectorAccessListSelectors.pending(state),
    inspectorAccessListDone: inspectorAccessListSelectors.done(state),
    inspectorAccessListFailed: inspectorAccessListSelectors.rejected(state),
    inspectorListPending: inspectorListSelectors.pending(state),
    inspectorListDone: inspectorListSelectors.done(state),
    inspectorListFailed: inspectorListSelectors.rejected(state),
    inspectorList: R.reject(
      inspector => isInspectorAssigned(inspector.id),
      inspectorListResult,
    ),
  };
};

const mapDispatchToProps = {
  loadInspectorList: inspectorListResource.action,
  loadInspectorAccessList: orgId => inspectorAccessListResource(orgId).action(),
  revokeInspectorAccess: actions.inspector.revokeAccess,
};

const mergeProps = (stateProps, dispatchProps) => {
  const { orgId } = stateProps;
  const { revokeInspectorAccess } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    handleRevokeInspectorAccess: inspectorId => () =>
      revokeInspectorAccess(orgId, inspectorId),
  };
};

class OrgSettingsInspectorsContainer extends React.Component {
  componentDidMount() {
    const {
      orgId,
      loadInspectorList,
      loadInspectorAccessList,
      inspectorAccessListDone,
      inspectorAccessListPending,
      inspectorListDone,
      inspectorListPending,
    } = this.props;

    if (!inspectorListDone && !inspectorListPending) {
      loadInspectorList();
    }

    if (!inspectorAccessListDone && !inspectorAccessListPending) {
      loadInspectorAccessList(orgId);
    }
  }

  render() {
    return <OrgSettingsInspectors {...this.props} />;
  }
}

OrgSettingsInspectorsContainer.propTypes = {
  orgId: PropTypes.number,
  inspectorAccessList: PropTypes.array,
  inspectorAccessListDone: PropTypes.bool,
  inspectorAccessListFailed: PropTypes.bool,
  inspectorAccessListPending: PropTypes.bool,
  inspectorList: PropTypes.array,
  inspectorListDone: PropTypes.bool,
  inspectorListFailed: PropTypes.bool,
  inspectorListPending: PropTypes.bool,
  loadInspectorAccessList: PropTypes.func,
  loadInspectorList: PropTypes.func,
  // revokeInspectorAccess: PropTypes.func,
  handleRevokeInspectorAccess: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(OrgSettingsInspectorsContainer);
