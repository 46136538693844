import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import theme from 'sow/theme';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import TableCell from 'sow/components/atoms/TableCell';
import Tooltip from 'sow/components/molecules/Tooltip';
import WorksheetMatrixCellAnswer from './WorksheetMatrixCellAnswer';
import ChangeUnreadNotificationsIcon from 'sow/containers/planApp/ChangeUnreadNotificationsIcon';

function cellBgColor(theme, changeState) {
  switch (changeState) {
    case 'accepted':
    case 'open':
      return rgba(theme.cr[changeState], 0.2);
    case 'rejected':
      return rgba(theme.cr[changeState], 0.5);

    // Change is indicated by icon instead of background color once CR is complete
    case 'applied':
    case 'not_applied':
    // return rgba(255, 255, 255, 0.25);
    default:
      return null;
  }
}

const ChangeIndicatorWrapper = styled(Block)`
  color: ${({ changeState, theme }) => {
    if (changeState === 'not_applied') return theme.cr.rejected;
    if (changeState === 'applied') return theme.cr.accepted;
  }};
`;

const ChangeIndicator = ({ changeState }) => {
  // Change already being indicated by the cell's bg color
  if (R.contains(changeState, ['accepted', 'open', 'rejected'])) return null;

  return (
    <ChangeIndicatorWrapper pullRight changeState={changeState}>
      <Tooltip
        overlay={
          changeState === 'applied'
            ? 'This answer was changed'
            : 'This answer had a change discarded'
        }
      >
        <FontAwesome icon="asterisk" />
      </Tooltip>
    </ChangeIndicatorWrapper>
  );
};

ChangeIndicator.propTypes = {
  changeState: sowTypes.planAppChangeStateType.isRequired,
};

const AnswerWrapper = styled(TableCell)`
  background: ${({ isRowDeleted, theme }) =>
    isRowDeleted &&
    `linear-gradient(
        transparent calc(50% - 1px),
        ${theme.cr.rejected},
        transparent calc(50% + 1px)
    )`};
  background-color: ${({ changeState, changeIgnored }) =>
    changeIgnored ? rgba(0, 0, 0, 0.1) : cellBgColor(theme, changeState)};
`;

const IgnoredChangeTooltip = ({ changeIgnored, children }) =>
  changeIgnored ? (
    <Tooltip overlay="This answer's change is ignored due to the row being marked for removal">
      <Block>{children}</Block>
    </Tooltip>
  ) : (
    children
  );
IgnoredChangeTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  changeIgnored: PropTypes.bool.isRequired,
};

const NoteIndicator = () => (
  <Block inline style={{ margin: '0 2px 0 2px' }}>
    <Tooltip overlay="Question has notes">
      <FontAwesome icon="sticky-note" variant="fas" />
    </Tooltip>
  </Block>
);

const WorksheetMatrixCell = ({
  questionId,
  matrixRowId,
  question,
  change,
  isRowDeleted,
  hasNotes,
  baseAnswersName,
  baseAnswersChangesName,
}) => {
  const changeState = R.propOr(null, 'state', change);
  const changeIgnored = R.propOr(false, 'ignored', change);

  let answerPath;
  switch (changeState) {
    // If the change is pending or accepted, show the new change answer
    case 'accepted':
    case 'open':
    case 'applied':
      answerPath = `${baseAnswersChangesName}.matrixRows.${matrixRowId}.values.${questionId}`;
      break;

    // If the change was rejected or does not exist, show the original answer
    case 'rejected':
    case 'not_applied':
    default:
      answerPath = `${baseAnswersName}.matrixRows.${matrixRowId}.values.${questionId}`;
  }

  return (
    <AnswerWrapper
      changeState={changeState}
      changeIgnored={changeIgnored}
      isRowDeleted={isRowDeleted}
      valignMiddle
    >
      <Block>
        {changeState && <ChangeIndicator changeState={changeState} inline />}
        {hasNotes && <NoteIndicator />}
        {change && <ChangeUnreadNotificationsIcon changeId={change.id} />}
      </Block>

      <IgnoredChangeTooltip changeIgnored={changeIgnored}>
        <Field
          name={answerPath}
          component={WorksheetMatrixCellAnswer}
          question={question}
        />
      </IgnoredChangeTooltip>
    </AnswerWrapper>
  );
};

WorksheetMatrixCell.propTypes = {
  questionId: sowTypes.planAppWorksheetQuestionIdType.isRequired,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType.isRequired,
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  change: sowTypes.planAppChangeType,
  isRowDeleted: PropTypes.bool.isRequired,
  hasNotes: PropTypes.bool.isRequired,
  baseAnswersName: PropTypes.string.isRequired,
  baseAnswersChangesName: PropTypes.string.isRequired,
};

WorksheetMatrixCell.defaultProps = {
  change: null,
};

export default WorksheetMatrixCell;
