import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { orgRoute } from 'sow/routes';
import Link from 'sow/components/atoms/Link';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const formatDate = date => moment(date).format('L');

const RegistrationListItem = ({ registration }) => (
  <TableRow>
    <TableCell textCenter valignMiddle>
      {formatDate(registration.inserted_at)}
    </TableCell>
    <TableCell textCenter valignMiddle>
      <Link to={orgRoute(registration.organization.id)}>
        {registration.organization.name}
      </Link>
    </TableCell>
  </TableRow>
);

RegistrationListItem.propTypes = {
  registration: PropTypes.object,
};

const NoItemsRow = () => (
  <TableRow>
    <TableCell colSpan={3} textCenter textInfo>
      No recent registrations
    </TableCell>
  </TableRow>
);

const OspAppNewRegistrationsList = ({ registrationsList }) => (
  <Table
    condensed
    bordered
    striped
    head={
      <TableRow>
        <TableCell heading textCenter xs={3}>
          Registered
        </TableCell>
        <TableCell heading textCenter xs={7}>
          Organization
        </TableCell>
      </TableRow>
    }
  >
    {registrationsList.map(reg => (
      <RegistrationListItem key={reg.id} registration={reg} />
    ))}
    {!registrationsList.length && <NoItemsRow />}
  </Table>
);

OspAppNewRegistrationsList.propTypes = {
  registrationsList: PropTypes.arrayOf(PropTypes.object),
};

export default OspAppNewRegistrationsList;
