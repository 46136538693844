import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import Button from 'sow/components/atoms/Button';
import Select from 'sow/components/molecules/Select';
import FormikField from 'sow/components/organisms/FormikField';

const formStyles = {
  width: '100%',
  boxSizing: 'border-box',
  padding: '1rem',
};

const InspectorAssignForm = ({ inspectorList, onSubmit }) => (
  <Widget>
    <WidgetHeading>Assign an Inspector</WidgetHeading>
    <Formik initialValues={{ inspectorId: '' }} onSubmit={onSubmit}>
      {({ values, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} style={formStyles}>
          <WidgetBody>
            <Field
              name="inspectorId"
              component={FormikField}
              type={Select}
              options={inspectorList.map(inspector => ({
                value: inspector.id,
                text: `${inspector.name} - ${inspector.email}`,
              }))}
            />
          </WidgetBody>
          <WidgetFooter textRight>
            <Button type="submit" primary disabled={isSubmitting || !values.inspectorId}>
              Assign
            </Button>
          </WidgetFooter>
        </Form>
      )}
    </Formik>
  </Widget>
);

InspectorAssignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  inspectorList: PropTypes.array,
};

InspectorAssignForm.defaultProps = {
  inspectorList: [],
};

export default InspectorAssignForm;
