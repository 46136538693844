import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import * as currentUser from 'sow/selectors/currentUser';
import * as currentAca from 'sow/selectors/currentAca';
import SidebarAcaAdmin from 'sow/components/organisms/SidebarAcaAdmin';

const mapStateToProps = (state, props) => ({
  isAcaOwner: currentUser.isAcaOwner(state, props),
  isSuperAdmin: currentUser.isSuperAdmin(state, props),
  loadFailed: currentAca.isLoadFail(state, props),
  loadingAca: currentAca.isLoading(state, props),
  shouldLoad: currentAca.shouldLoad(state, props),
  acaId: currentAca.acaId(state, props),
  aca: currentAca.aca(state, props),
});

const mapDispatchToProps = {
  loadAca: actions.aca.loadOne,
  routerPush,
};

class SidebarAcaAdminContainer extends Component {
  componentDidMount() {
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps) {
    this.loadData(this.props);

    if (!prevProps.loadFailed && this.props.loadFailed) {
      throw new Error('Error loading ACA.');
    }
  }

  loadData = ({ acaId, shouldLoad, loadAca }) => {
    if (shouldLoad && acaId) loadAca(acaId);
  };

  render() {
    const { isAcaOwner, isSuperAdmin, routerPush, aca, org, navClasses } = this.props;

    if (!aca) return null; // While loading

    return (
      <SidebarAcaAdmin
        isAcaOwner={isAcaOwner}
        isSuperAdmin={isSuperAdmin}
        routerPush={routerPush}
        navClasses={navClasses}
        aca={aca}
        org={org}
      />
    );
  }
}

SidebarAcaAdminContainer.propTypes = {
  // Passed props
  org: sowTypes.orgType,
  navClasses: PropTypes.string,

  // Connected props
  acaId: sowTypes.acaIdType,
  aca: sowTypes.acaType,
  isAcaOwner: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  routerPush: PropTypes.func.isRequired,
  loadAca: PropTypes.func.isRequired,
  shouldLoad: PropTypes.bool.isRequired,
  loadFailed: PropTypes.bool.isRequired,
};

SidebarAcaAdmin.defaultProps = {
  aca: null,
  acaId: null,
  // Only present when looking at an org
  org: null,
  navClasses: null,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SidebarAcaAdminContainer);
