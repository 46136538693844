import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import FormField from 'sow/components/organisms/FormField';

const FormikField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue, setValues }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type,
  ...props
}) => {
  const namePath = R.path(field.name.split('.'));
  const fieldErrors = namePath(errors);
  const fieldTouched = namePath(touched);

  const fieldProps = {
    ...props,
    ...field,
    invalid: fieldTouched && !!fieldErrors,
    error: fieldErrors,
    type,
    setFieldValue,
    setValues,
    updateValues: updateFields => setValues({ ...values, ...updateFields }),
  };

  if (type === 'checkbox') {
    return <FormField {...fieldProps} checked={values[field.name]} />;
  }

  return <FormField {...fieldProps} />;
};

FormikField.propTypes = {
  form: PropTypes.shape({
    touched: PropTypes.object,
    error: PropTypes.object,
    values: PropTypes.object,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default FormikField;
