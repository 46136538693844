import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetQuestionChange from 'sow/components/organisms/WorksheetQuestionChange';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
  question: fromPlanApp.question(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  change: R.isNil(props.locationId)
    ? fromPlanApp.questionChange(state, props)
    : fromPlanApp.locationQuestionChange(state, props),
  changeId: R.isNil(props.locationId)
    ? fromPlanApp.questionChangeId(state, props)
    : fromPlanApp.locationQuestionChangeId(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

const WorksheetQuestionChangeContainer = props => {
  return <WorksheetQuestionChange {...props} />;
};

WorksheetQuestionChangeContainer.propTypes = {
  // Passed props
  locationId: sowTypes.planAppLocationIdType,
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  /** formik field name for the answer */
  baseAnswersName: PropTypes.string.isRequired,
  /** formik field name for the change answer */
  baseAnswersChangesName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  changeUIDisabled: PropTypes.bool.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  changeId: sowTypes.planAppChangeIdType,
  change: sowTypes.planAppChangeType,
  showAcaUI: PropTypes.bool.isRequired,
};

WorksheetQuestionChangeContainer.defaultProps = {
  locationId: null,
  matrixRowId: null,
  changeRequest: null,
  disabled: false,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(WorksheetQuestionChangeContainer);
