import { all } from 'redux-saga/effects';

import aca from 'sow/sagas/aca';
import acaAdmin from 'sow/sagas/acaAdmin';
import analytics from 'sow/sagas/analytics';
import api from 'sow/sagas/api';
import auth from 'sow/sagas/auth';
import certificationDocs from 'sow/sagas/certificationDocs';
// import orgExportFiles from 'sow/sagas/orgExportFiles';
import gis from 'sow/sagas/gis';
import orgRegistration from 'sow/sagas/orgRegistration';
import organization from 'sow/sagas/org';
import ospApplication from 'sow/sagas/ospApplication';
import ospDefinition from 'sow/sagas/ospDefinition';
import profile from 'sow/sagas/profile';
import registration from 'sow/sagas/registration';
import resetPassword from 'sow/sagas/resetPassword';
import shell from 'sow/sagas/shell';

// redux-modules
import { sagas as resource } from 'sow/store/modules/resource';
import bulkDownload from 'sow/store/modules/bulkDownload/sagas';

// sagas using resource/entities redux-modules
import inspector from 'sow/sagas/inspector';

// single entry point to start all Sagas at once
export default function*(services = {}) {
  try {
    yield all([
      aca(),
      api(),
      shell(),
      acaAdmin(),
      analytics(),
      certificationDocs(),
      // orgExportFiles(),
      orgRegistration(),
      organization(),
      ospApplication(),
      ospDefinition(),
      registration(),
      resetPassword(),
      gis(),
      profile(),

      // using new redux-modules and services
      auth(services),
      inspector(services),

      // redux-modules
      resource(services),
      bulkDownload(services),
    ]);
  } catch (error) {
    console.error('ROOT SAGA ERROR!!!', error);
    console.trace();
  }
}
