import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import { PLAN_APP_STATES_CR_CREATABLE } from 'sow/constants/planApp';
import { PlanAppChangeRequestLoader } from 'sow/store/containers';
import PlanAppChangeRequestCreatePanel from 'sow/components/organisms/PlanAppChangeRequestCreatePanel';
import PlanAppChangeRequestManager from 'sow/components/organisms/PlanAppChangeRequestManager';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  planAppState: fromPlanApp.planAppState(state, props),
  locationId: fromPlanApp.locationId(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  changeList: fromPlanApp.crOverviewChangeList(state, props),
  worksheetsWithChanges: fromPlanApp.worksheetsWithChanges(state, props),
  locationsWithChanges: fromPlanApp.locationsWithChanges(state, props),
});

const mapDispatchToProps = dispatch => ({
  toastCreateSuccess: () =>
    dispatch(
      actions.shell.toast(
        'success',
        'A Change Request has been created. Changes may now be made throughout this plan.',
      ),
    ),
});

class PlanAppChangeRequestManagerContainer extends Component {
  state = {
    filter: {
      open: true,
      accepted: false,
      rejected: false,
    },
  };

  toggleVisibilityFilter = filter => {
    const path = R.lensPath(['filter', filter]);
    this.setState(R.over(path, R.not));
  };

  getCurrentFilters = () =>
    R.pipe(
      R.filter(R.equals(true)),
      R.keys,
    )(this.state.filter);

  getFilteredChanges = () => {
    const { changeList } = this.props;
    return R.filter(
      R.pipe(
        R.prop('state'),
        R.contains(R.__, this.getCurrentFilters()),
      ),
    )(changeList);
  };

  getChangeCounts = () => {
    const { changeList } = this.props;

    const numChangesForState = state =>
      R.pipe(
        R.filter(
          R.where({
            state: R.equals(state),
          }),
        ),
        R.length,
      )(changeList);

    return {
      open: numChangesForState('open'),
      accepted: numChangesForState('accepted'),
      rejected: numChangesForState('rejected'),
    };
  };

  render() {
    const {
      title,
      orgId,
      planAppId,
      worksheetId,
      locationId,
      changeList,
      changeRequest,
      changeRequestIsOpen,
      planAppState,
      showAcaUI,
      worksheetsWithChanges,
      locationsWithChanges,
      showPlanChecklist,
      showPlanWorksheets,
      showLocations,
      toastCreateSuccess,
    } = this.props;
    const { filter } = this.state;

    if (changeRequest && R.isEmpty(changeList)) return null;

    // Show CR UI when CR exists and is open
    if (changeRequest && changeRequestIsOpen) {
      return (
        <PlanAppChangeRequestManager
          title={title}
          orgId={orgId}
          planAppId={planAppId}
          worksheetId={worksheetId}
          locationId={locationId}
          changeList={this.getFilteredChanges()}
          showAcaUI={showAcaUI}
          filter={filter}
          changeCounts={this.getChangeCounts()}
          onToggleFilter={this.toggleVisibilityFilter}
          worksheets={worksheetsWithChanges}
          locations={locationsWithChanges}
          showPlanChecklist={showPlanChecklist}
          showPlanWorksheets={showPlanWorksheets}
          showLocations={showLocations}
        />
      );
    }

    // For older plans that didn't have CR created automatically:
    // Show UI to create CR when app is in a valid state
    if (R.contains(planAppState, PLAN_APP_STATES_CR_CREATABLE)) {
      return (
        <PlanAppChangeRequestLoader>
          {({ status: createStatus, createResource: createChangeRequest }) => (
            <PlanAppChangeRequestCreatePanel
              onCreate={e => {
                e.preventDefault();
                createChangeRequest().then(toastCreateSuccess);
              }}
              showAcaUI={showAcaUI}
              disableCreate={createStatus.loading}
            />
          )}
        </PlanAppChangeRequestLoader>
      );
    }

    // There isn't a CR or CR cannot be created in current state, show nothing
    return null;
  }
}

PlanAppChangeRequestManagerContainer.propTypes = {
  // Passed props
  title: PropTypes.node,
  worksheetId: sowTypes.planAppWorksheetIdType, // Only passed when looking at a worksheet
  showPlanChecklist: PropTypes.bool,
  showPlanWorksheets: PropTypes.bool,
  showLocations: PropTypes.bool,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired, // Only when looking at worksheet page
  locationId: sowTypes.planAppLocationIdType, // Only when looking at location page
  changeRequest: sowTypes.planAppChangeRequestType,
  changeRequestIsOpen: PropTypes.bool,
  planAppState: sowTypes.planAppStateType.isRequired,
  showAcaUI: PropTypes.bool,
  canApply: PropTypes.bool,
  changeList: sowTypes.planAppChangeListType,
  worksheetsWithChanges: PropTypes.arrayOf(sowTypes.planAppWorksheetType).isRequired,
  locationsWithChanges: PropTypes.arrayOf(sowTypes.planAppLocationIdType).isRequired,
  toastCreateSuccess: PropTypes.func.isRequired,
};

PlanAppChangeRequestManagerContainer.defaultProps = {
  title: 'Changes',
  worksheetId: null,
  locationId: null,
  showPlanChecklist: false,
  showPlanWorksheets: false,
  showLocations: false,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PlanAppChangeRequestManagerContainer);
