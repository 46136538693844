import * as R from 'ramda';
import {
  // createAttachmentAsync,
  deleteAttachmentAsync,
  fetchAttachmentListAsync,
  fetchAttachmentOspListAsync,
  updateAttachmentAsync,
} from 'sow/services/attachment';

const mark = action => `soworganic/attachment/${action}`;

export const FETCH_ATTACHMENT_LIST = mark('FETCH_ATTACHMENT_LIST');
export const FETCH_ATTACHMENT_LIST_SUCCESS = mark('FETCH_ATTACHMENT_LIST_SUCCESS');
export const FETCH_ATTACHMENT_LIST_FAILED = mark('FETCH_ATTACHMENT_LIST_FAILED');

export const fetchAttachmentList = orgId => dispatch => {
  dispatch({ type: FETCH_ATTACHMENT_LIST });

  fetchAttachmentListAsync(orgId)
    .done(res => {
      dispatch({
        type: FETCH_ATTACHMENT_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching attachment list:', err.responseJSON);
      dispatch({ type: FETCH_ATTACHMENT_LIST_FAILED });
    });
};

export const fetchAttachmentOspList = (orgId, ospAppId) => dispatch => {
  dispatch({ type: FETCH_ATTACHMENT_LIST });

  fetchAttachmentOspListAsync(orgId, ospAppId)
    .done(res => {
      const files = res.data;
      const fauxAttachments = R.map(
        file => ({
          category: null,
          id: file.id,
          name: file.name,
          file: {
            ...file,
            public: true,
          },
        }),
        files,
      );

      dispatch({
        type: FETCH_ATTACHMENT_LIST_SUCCESS,
        data: fauxAttachments, // files,
      });
    })
    .fail(err => {
      console.error('Error fetching Plan attachment list:', err.responseJSON);
      dispatch({ type: FETCH_ATTACHMENT_LIST_FAILED });
    });
};

export const UPDATE_ATTACHMENT = mark('UPDATE_ATTACHMENT');
export const UPDATE_ATTACHMENT_SUCCESS = mark('UPDATE_ATTACHMENT_SUCCESS');
export const UPDATE_ATTACHMENT_FAILED = mark('UPDATE_ATTACHMENT_FAILED');

export const updateAttachment = (orgId, attachment, success) => dispatch => {
  dispatch({ type: UPDATE_ATTACHMENT });

  updateAttachmentAsync(orgId, attachment)
    .done(res => {
      dispatch({
        type: UPDATE_ATTACHMENT_SUCCESS,
        data: res.data,

        // TODO remove this when Oliver fixes backend
        meta: {
          orgId,
          attachment,
        },
      });

      if (success) success();
    })
    .fail(err => {
      dispatch({
        type: UPDATE_ATTACHMENT_FAILED,
        data: err.responseJSON,
      });
    });
};

export const DELETE_ATTACHMENT = mark('DELETE_ATTACHMENT');
export const DELETE_ATTACHMENT_SUCCESS = mark('DELETE_ATTACHMENT_SUCCESS');
export const DELETE_ATTACHMENT_FAILED = mark('DELETE_ATTACHMENT_FAILED');

export const deleteAttachment = (orgId, attachmentId, success) => dispatch => {
  dispatch({ type: DELETE_ATTACHMENT });

  deleteAttachmentAsync(orgId, attachmentId)
    .done(() => {
      dispatch({
        type: DELETE_ATTACHMENT_SUCCESS,
        data: { attachmentId },
      });

      if (success) success();
    })
    .fail(err => {
      dispatch({
        type: DELETE_ATTACHMENT_FAILED,
        data: err.responseJSON,
      });
    });
};
