import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';

const BodyWrapper = styled(Block)`
  padding: 20px;
  text-align: center;
`;

class OrgWithdrawModal extends Component {
  state = {
    showModal: false,
  };

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  render() {
    const { showModal } = this.state;
    const { children } = this.props;

    return (
      <Fragment>
        {children(this.showModal)}

        <Modal show={showModal} onHide={this.hideModal}>
          <Modal.Body>
            <BodyWrapper>
              Please contact your Certifier to withdraw this plan.
            </BodyWrapper>
            <Block textCenter>
              <Button onClick={this.hideModal}>Close</Button>
            </Block>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

OrgWithdrawModal.propTypes = {
  children: PropTypes.func.isRequired,
};

export default OrgWithdrawModal;
