import React from 'react';
import PropTypes from 'prop-types';

import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Tooltip from 'sow/components/molecules/Tooltip';

const DeleteRowButton = ({ onDelete, disabled }) => (
  <Tooltip overlay="Remove Row">
    <Button sm onClick={onDelete} disabled={disabled}>
      <FontAwesome icon="trash" />
    </Button>
  </Tooltip>
);

DeleteRowButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DeleteRowButton;
