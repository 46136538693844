import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { NoteRow } from './Note';
import { CommentRow } from './Comment';
import WorksheetAnswer from './WorksheetAnswer';

const PlanAppExportContentWorksheetQuestion = ({
  question,
  value,
  noteList,
  commentList,
  addNotes,
  addComments,
}) => {
  return (
    <Fragment>
      <tr>
        <td>{question.name}</td>
        <td>
          <WorksheetAnswer value={value} question={question} />
        </td>
      </tr>
      {addNotes && noteList.map(note => <NoteRow key={note.id} note={note} />)}
      {addComments &&
        commentList.map(comment => <CommentRow key={comment.id} comment={comment} />)}
    </Fragment>
  );
};

PlanAppExportContentWorksheetQuestion.propTypes = {
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  value: PropTypes.any,
  commentList: sowTypes.planAppChangeCommentListType.isRequired,
  noteList: sowTypes.planAppNoteListType.isRequired,
  addComments: PropTypes.bool,
  addNotes: PropTypes.bool,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
};

export default PlanAppExportContentWorksheetQuestion;
