import { schema } from 'normalizr';

export const aca = new schema.Entity('acas', {}, { idAttribute: 'id' });

export const organization = new schema.Entity('organizations', {}, { idAttribute: 'id' });

// NOTE (orther) ignoring recursive normalizr schema for now so using verbose monster below instead
export const ospQualificationQuestionSubQuestion = new schema.Entity(
  'ospQualificationQuestionSubQuestions',
  {},
  { idAttribute: 'uuid' },
);

export const ospQualificationQuestion = new schema.Entity(
  'ospQualificationQuestions',
  {
    sub_questions: [ospQualificationQuestionSubQuestion],
  },
  { idAttribute: 'uuid' },
);

export const ingredient = new schema.Entity('ingredients', {}, { idAttribute: 'id' });

export const land = new schema.Entity('lands', {}, { idAttribute: 'id' });

export const note = new schema.Entity('notes', {}, { idAttribute: 'id' });

export const product = new schema.Entity(
  'products',
  {
    lands: [land],
  },
  { idAttribute: 'id' },
);

export const record = new schema.Entity('records', {}, { idAttribute: 'id' });

export const worksheetAnswersValue = new schema.Entity(
  'worksheetAnswersValues',
  {},
  {
    idAttribute: entity =>
      `${entity.worksheet_answers_id}.${entity.worksheet_question_uuid}`,
  },
);

export const worksheetAnswersMatrixRow = new schema.Entity(
  'worksheetAnswersMatrixRows',
  {},
  {
    idAttribute: 'uuid',
  },
);

export const worksheetAnswers = new schema.Entity(
  'worksheetAnswers',
  {},
  {
    idAttribute: 'id',
  },
);

export const worksheetQuestion = new schema.Entity(
  'worksheetQuestions',
  {},
  {
    idAttribute: 'uuid',
  },
);

export const worksheet = new schema.Entity(
  'worksheets',
  {
    questions: [worksheetQuestion],
  },
  { idAttribute: 'uuid' },
);

export const ospSection = new schema.Entity(
  'ospSections',
  {
    worksheets: [worksheet],
  },
  { idAttribute: 'uuid' },
);

export const landAnswers = new schema.Entity(
  'landAnswers',
  {
    answers: {
      worksheets: [worksheetAnswers],
    },
  },
  { idAttribute: 'id' },
);

export const productAnswers = new schema.Entity(
  'productAnswers',
  {
    answers: {
      worksheets: [worksheetAnswers],
    },
  },
  { idAttribute: 'id' },
);

export const ospApp = new schema.Entity(
  'ospApps',
  {
    definition: {
      mini_osp: {
        land: {
          facility: {
            section: ospSection,
          },
          office: {
            section: ospSection,
          },
          pasture: {
            section: ospSection,
          },
          parcel: {
            section: ospSection,
          },
        },
        product: {
          section: ospSection,
        },
      },
      // TODO (orther) remove this, they are only for reference
      land: {
        facility: worksheet,
        office: worksheet,
        parcel: worksheet,
        pasture: worksheet,
      },
      product: worksheet,
      // TODO --^^
      qualifications: {
        questions: [ospQualificationQuestion],
      },
      sections: [ospSection],
    },
  },
  { idAttribute: 'id' },
);

export const ospDef = new schema.Entity(
  'ospDefs',
  {
    definition: {
      mini_osp: {
        land: {
          facility: {
            section: ospSection,
          },
          office: {
            section: ospSection,
          },
          pasture: {
            section: ospSection,
          },
          parcel: {
            section: ospSection,
          },
        },
        product: {
          section: ospSection,
        },
      },
      qualifications: {
        questions: [ospQualificationQuestion],
      },
      sections: [ospSection],
    },
  },
  { idAttribute: 'id' },
);
