import * as R from 'ramda';
import {
  FETCH_ATTACHMENT_LIST,
  FETCH_ATTACHMENT_LIST_SUCCESS,
  FETCH_ATTACHMENT_LIST_FAILED,
  UPDATE_ATTACHMENT,
  UPDATE_ATTACHMENT_SUCCESS,
  UPDATE_ATTACHMENT_FAILED,
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_FAILED,
} from 'sow/actions/attachment';

const initialState = {
  items: [],
  isFetching: false,
  isCreating: false,
  updateErrors: null,
  deleteErrors: null,
};

const updateAttachment = (items, attachment) => {
  const index = items.map(i => i.id).indexOf(attachment.id);

  if (index > -1) {
    items[index] = attachment;
  }

  return items;
};

const removeAttachment = (items, attachmentId) => {
  const index = items.map(i => i.id).indexOf(attachmentId);

  if (index > -1) {
    items.splice(index, 1);
  }

  return items;
};

export default function attachment(state = initialState, action) {
  switch (action.type) {
    case FETCH_ATTACHMENT_LIST:
      return { ...initialState, isFetching: true };

    case FETCH_ATTACHMENT_LIST_SUCCESS:
      return { ...state, isFetching: false, items: [...action.data] };

    case FETCH_ATTACHMENT_LIST_FAILED:
      return { ...state, isFetching: false };

    case UPDATE_ATTACHMENT:
      return { ...state, isCreating: true, updateErrors: null };

    case UPDATE_ATTACHMENT_SUCCESS:
      const tempFixData = R.compose(
        R.assocPath(['file', 'public'], action.meta.attachment.file.public),
        R.assocPath(['name'], action.meta.attachment.name),
        R.assocPath(['category'], action.meta.attachment.category),
      )(action.data);
      console.log('action', { state, action, tempFixData });

      return {
        ...state,
        isCreating: false,
        // items: updateAttachment([...state.items], action.data),
        items: updateAttachment([...state.items], tempFixData),
      };

    case UPDATE_ATTACHMENT_FAILED:
      return { ...state, isCreating: false, updateErrors: action.data.errors };

    case DELETE_ATTACHMENT:
      return { ...state, isCreating: true, deleteErrors: null };

    case DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        items: removeAttachment([...state.items], action.data.attachmentId),
      };

    case DELETE_ATTACHMENT_FAILED:
      return { ...state, isCreating: false, deleteErrors: action.data.errors };

    default:
      return state;
  }
}
