import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  orgRoute,
  attachmentOspRoute,
  certificationDocsRoute,
  exportRoute,
  orgSettingsRoute,
} from 'sow/routes';
import { fromPlanApp } from 'sow/store/selectors';
import Atom from 'sow/components/atoms/Atom';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import IconWithText from 'sow/components/molecules/IconWithText';
import NavLink from 'sow/components/molecules/NavLink';
import SidebarDropdown from 'sow/components/molecules/SidebarDropdown';
import OrgSwitcherModal from 'sow/containers/OrgSwitcherModal';
// import PlanAppChangeIndicator from 'sow/containers/planApp/ChangeIndicators';

const mapStateToProps = (state, props) => ({
  planAppId: fromPlanApp.planAppId(state, props),
});

const mapDispatchToProps = {
  routerPush,
};

const SidebarOrg = ({ org, routerPush, navClasses }) => (
  <List className={classnames('sidebar-org', navClasses)}>
    <ListItem className="sidebar-heading">
      <OrgSwitcherModal />

      <Atom style={{ cursor: 'pointer' }} onClick={() => routerPush(orgRoute(org.id))}>
        {org.name}
      </Atom>
    </ListItem>

    <NavLink to={orgRoute(org.id)} exact>
      <IconWithText icon={<FontAwesome icon="star" />} text="Dashboard" />
    </NavLink>

    <NavLink to={attachmentOspRoute(org.id)}>
      <IconWithText icon={<FontAwesome icon="paperclip" />} text="Attachments" />
    </NavLink>

    <NavLink to={certificationDocsRoute(org.id)}>
      <IconWithText
        icon={<FontAwesome variant="far" icon="file-alt" />}
        text="Certification Docs"
      />
    </NavLink>

    <NavLink to={exportRoute(org.id)}>
      <IconWithText icon={<FontAwesome icon="download" />} text="Export" />
    </NavLink>

    <SidebarDropdown
      label={
        <IconWithText icon={<FontAwesome icon="cog" />} text="Organization Settings" />
      }
      to={orgSettingsRoute(org.id)}
    />
  </List>
);

SidebarOrg.propTypes = {
  org: PropTypes.object,
  router: PropTypes.object,
  navClasses: PropTypes.string,
  routerPush: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SidebarOrg);
