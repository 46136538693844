import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import yup from 'yup';

import { orgIdType, planAppIdType, planAppStateType } from 'sow/types';
import { planAppStateText } from 'sow/constants/planApp';

import Button from 'sow/components/atoms/Button';
import Select from 'sow/components/molecules/Select';
import Tooltip from 'sow/components/molecules/Tooltip';
import FormikField from 'sow/components/organisms/FormikField';
import AppStateText from './AppStateText';

class OrgPlanAppEditStateModal extends Component {
  state = { showModal: false };

  showModal = () => this.setState({ showModal: true });
  hideModal = () => this.setState({ showModal: false });

  handleSubmit = values => {
    this.props.onSubmit(values.state).then(this.hideModal);
  };

  render() {
    const { showModal } = this.state;
    const { children, appState } = this.props;

    const validStates = ['initial_review', 'inspection', 'final_review'];

    const defaultValue = validStates.includes(appState) ? appState : '';

    // Formik setup
    const initialFormValues = { state: defaultValue };
    const validationSchema = yup.object({ state: yup.string().required() });
    const options = validStates.map(s => ({ value: s, text: planAppStateText[s] }));

    return (
      <Fragment>
        <Tooltip overlay="Change this application's state">
          {children(this.showModal)}
        </Tooltip>

        <Modal show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Application State</Modal.Title>
          </Modal.Header>

          <Formik
            onSubmit={this.handleSubmit}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            <Form>
              <Modal.Body>
                {/* Not a field but an easy way to get the formatting the same as the dropdown */}
                <Field
                  name="oldState"
                  label="Current application state:"
                  component={FormikField}
                  type={AppStateText}
                  appState={appState}
                />

                <Field
                  name="state"
                  label="New application state:"
                  component={FormikField}
                  type={Select}
                  options={options}
                />
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={this.hideModal}>Cancel</Button>

                <Button type="submit" primary>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}

OrgPlanAppEditStateModal.propTypes = {
  children: PropTypes.func.isRequired, // Modal toggle
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  appState: planAppStateType.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OrgPlanAppEditStateModal;
