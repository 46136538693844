import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { validateLocation } from 'sow/utils/planAppLocation';
import * as sowTypes from 'sow/types';
import Button from 'sow/components/atoms/Button';
import PlanAppLocationFormFields from 'sow/components/organisms/PlanAppLocationFormFields';

class PlanAppLocationEditModal extends Component {
  state = {
    show: false,
  };

  setModalVisibility = show => this.setState({ show });
  showModal = () => this.setModalVisibility(true);
  hideModal = () => this.setModalVisibility(false);

  handleSubmit = (values, formikBag) => {
    this.props.onSubmit(values).finally(() => {
      this.hideModal();
      formikBag.setSubmitting(false);
    });
  };

  render() {
    const { show } = this.state;
    const {
      children,
      locationDetails,
      planAppId,
      isStateInitialApplication,
    } = this.props;

    const fieldsDisabled = R.isNil(planAppId) || !isStateInitialApplication;

    return (
      <Fragment>
        {children(this.showModal)}

        <Modal show={show} onHide={this.hideModal} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Location Details</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={locationDetails}
            onSubmit={this.handleSubmit}
            validate={validateLocation}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Modal.Body>
                  <PlanAppLocationFormFields
                    planAppLocation={values}
                    disabled={fieldsDisabled}
                  />
                </Modal.Body>
                <Modal.Footer>
                  {!fieldsDisabled && (
                    <Fragment>
                      <Button default onClick={this.hideModal}>
                        Cancel
                      </Button>
                      <Button type="submit" primary disabled={isSubmitting}>
                        Save
                      </Button>
                    </Fragment>
                  )}

                  {fieldsDisabled && (
                    <Button primary onClick={this.hideModal}>
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}

PlanAppLocationEditModal.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  locationDetails: sowTypes.planAppLocationType.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  planAppId: sowTypes.planAppIdType,
};

PlanAppLocationEditModal.defaultProps = {
  planAppId: null,
};

export default PlanAppLocationEditModal;
