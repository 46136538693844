import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetMatrixRow from 'sow/components/organisms/WorksheetMatrix/WorksheetMatrixRow';

const mapStateToProps = (state, props) => ({
  rowDeletionChange: fromPlanApp.matrixRowDeletionChange(state, props),
});

const MatrixRowContainer = ({ rowDeletionChange, ...props }) => {
  const isRowDeleted = R.allPass([
    change => !!change,
    R.pipe(
      R.prop('state'),
      R.contains(R.__, ['accepted', 'applied']),
    ),
  ])(rowDeletionChange);

  return <WorksheetMatrixRow {...props} isRowDeleted={isRowDeleted} />;
};

MatrixRowContainer.propTypes = {
  // Passed props
  baseAnswersName: PropTypes.string.isRequired,
  baseAnswersChangesName: PropTypes.string.isRequired,

  // Connected props
  rowDeletionChange: sowTypes.planAppChangeType,
};

MatrixRowContainer.defaultProps = {
  rowDeletionChange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(MatrixRowContainer);
