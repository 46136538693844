import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import FormLabel from 'sow/components/atoms/FormLabel';
import Strong from 'sow/components/atoms/Strong';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';

const requiredMarker = (
  <Atom className="text-danger" title="Required">
    *
  </Atom>
);

const FormGroup = ({
  fieldName,
  label,
  children,
  errors,
  required,
  tooltip,
  hideErrorLabel,
  disabled,
  ...props
}) => {
  const fieldErrors = errors ? errors[fieldName] || [] : [];
  const hasErrors = fieldErrors.length > 0;

  const errorTag =
    hasErrors &&
    !hideErrorLabel &&
    <Block textDanger>
      <Strong>Error:</Strong> {fieldErrors.join(', ')}
    </Block>;

  const classes = classnames(
    'form-group',
    { 'has-error': hasErrors },
    { disabled: disabled },
  );

  const labelTag =
    label &&
    <FormLabel htmlFor={fieldName}>
      {label} {tooltip && <InfoTooltip overlay={tooltip} />} {required && requiredMarker}
    </FormLabel>;

  return (
    <Block className={classes} {...props}>
      {labelTag}
      {children}
      {errorTag}
    </Block>
  );
};

FormGroup.propTypes = {
  fieldName: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.node,
  children: PropTypes.node,
  tooltip: PropTypes.node,
  hideErrorLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormGroup.defaultProps = {
  errors: {},
  required: false,
  hideErrorLabel: false,
  disabled: false,
};

export default FormGroup;
