import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { PlanAppLoader, PlanAppWorksheetAnswersLoader } from 'sow/store/containers';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgPlanAppWorksheetOverviewPage from 'sow/components/pages/OrgPlanAppWorksheetOverviewPage';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  qualifiedWorksheetIds: fromPlanApp.qualifiedWorksheetIds(state, props),
  worksheetSections: fromPlanApp.qualifiedWorksheetSections(state, props),
});

const OrgPlanAppWorksheetOverviewPageContainer = ({
  orgId,
  planAppId,
  qualifiedWorksheetIds,
  worksheetSections,
}) => {
  return (
    <OrgPlanAppPageTemplate title="Worksheets">
      <PlanAppLoader orgId={orgId} planAppId={planAppId} autoLoad>
        {({ status: planAppStatus }) => (
          <PlanAppWorksheetAnswersLoader autoLoad>
            {({ status: answersStatus }) => (
              <ResourceLoaderStatusRender
                statusObjects={[planAppStatus, answersStatus]}
                renderAllDone={() =>
                  planAppStatus.success && (
                    <OrgPlanAppWorksheetOverviewPage
                      orgId={orgId}
                      planAppId={planAppId}
                      worksheetSections={worksheetSections}
                      qualifiedWorksheetIds={qualifiedWorksheetIds}
                    />
                  )
                }
              />
            )}
          </PlanAppWorksheetAnswersLoader>
        )}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppWorksheetOverviewPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgPlanAppWorksheetOverviewPageContainer);
