import { call, put, takeEvery } from 'redux-saga/effects';

import * as oldOspAppAction from 'sow/actions/ospApplication';
import * as wsaAction from 'sow/actions/worksheetAnswers';
import actions from 'sow/actions/pure';
import { apiGet } from 'sow/sagas/api';

export function* loadOspAppListForOrg(orgId) {
  try {
    const { response, error } = yield call(apiGet, `/org/${orgId}/application`);

    if (response) {
      const { data } = response;
      yield put(actions.ospApp.loadOrgListDone({ orgId, result: data }));
    }

    if (error) {
      yield put(actions.ospApp.loadOrgListFail(error));
    }
  } catch (error) {
    yield put(actions.ospApp.loadOrgListFail(error));
  }
}

export function* watchFullOspAppLoad({ data }) {
  const { orgId, ospAppId } = data; // payload;

  yield put(wsaAction.loadAllWorksheetAnswersForOrgOspApp(orgId, ospAppId));
}

export function* watchLoadOspAppListForOrg({ payload }) {
  try {
    const { orgId } = payload;
    yield call(loadOspAppListForOrg, orgId);
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  try {
    yield takeEvery(actions.ospApp.loadOrgList, watchLoadOspAppListForOrg);

    yield takeEvery(oldOspAppAction.LOAD_ONE_FOR_ORG_SUCCESS, watchFullOspAppLoad);
  } catch (error) {
    console.error(error);
  }
}
