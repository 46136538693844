// TODO (orther) this is temp until we can port old OspAppEditor

import React from 'react';
import * as R from 'ramda';

import * as currentOspApp from 'sow/selectors/currentOspApp';
import * as currentWorksheet from 'sow/selectors/currentWorksheet';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Question from 'sow/components/old/OspApp/Editor/Question';
import InfoTooltipWrapper from 'sow/components/old/OspApp/Editor/Overview/InfoTooltipWrapper';
import Regulations from 'sow/components/old/OspAppEditor/Regulations';
import Worksheet from 'sow/components/old/OspAppEditor/Worksheet';
import WorksheetMatrix from 'sow/components/old/OspAppEditor/WorksheetMatrix';

// This hacks base answers to work w/ regular answer field components
const BaseQuestion = ({ name, text, fieldType, ...props }) => {
  const question = {
    uuid: name,
    name: text,
    field_type: fieldType,
    isBaseQuestion: true,
  };

  const colorContext = props.answer === true ? 'info' : 'default';
  return (
    <div className={`panel panel-${colorContext}`}>
      <div className="panel-heading" style={{ padding: '8px 13px' }}>
        <Question {...props} question={question} />
      </div>
    </div>
  );
};

class WorksheetContainer extends React.Component {
  handleSubmit = event => {
    event.preventDefault();
  };

  handleSaveWorksheet() {}

  handleSaveWorksheetAndContinue(nextWorksheetId) {}

  handleSaveWorksheetAndFinish() {}

  continueToWorksheet(orgId, ospAppId, worksheetId) {}

  handleContinueToWorksheetOverview() {}

  renderWorksheet(worksheet, isOspAppLocked) {
    const worksheetProps = {
      ...this.props,
      disabled: isOspAppLocked || this.props.wsAnswers.isNotApplicable,
    };

    console.log('worksheetProps', worksheetProps);
    if (worksheet.matrix) {
      return <WorksheetMatrix {...worksheetProps} />;
    }

    return <Worksheet {...worksheetProps} />;
  }

  render() {
    const { isLoadingAnswers, orgId, ospAppId } = this.props;
    const { worksheet, questions, wsAnswers } = this.props;
    const { handleChangeBaseAnswer } = this.props;

    if (!worksheet) return <Spinner />;
    if (isLoadingAnswers || !wsAnswers.answers) return <Spinner />;

    const isOspAppLocked = false; //!isStateUnlocked(ospAppInfo);
    const showHeaderText = !!worksheet.header;
    const showNotApplicable = worksheet.not_applicable !== false;
    const worksheetLocked = wsAnswers.isNotApplicable || isOspAppLocked;

    const regulations = worksheet.regulations;

    return (
      <Widget>
        <WidgetHeading>
          {worksheet.name || 'Worksheet'}
          <InfoTooltipWrapper tooltipText={worksheet.tooltip} />
        </WidgetHeading>
        <form onSubmit={this.handleSubmit}>
          <WidgetBody>
            {showHeaderText && <InfoPanel text={worksheet.header} />}

            {showNotApplicable && (
              <BaseQuestion
                name="isNotApplicable"
                text="This worksheet is not applicable."
                fieldType="bool"
                orgId={orgId}
                ospId={ospAppId}
                worksheetId={worksheet.uuid}
                answer={wsAnswers.isNotApplicable}
                handleChange={handleChangeBaseAnswer}
                disabled={isOspAppLocked}
              />
            )}

            <Regulations regulations={worksheet.regulations} />

            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">
                  <span className={worksheetLocked ? 'text-muted' : ''}>
                    Worksheet Questions
                  </span>
                </h3>
              </div>
              {this.renderWorksheet(worksheet, isOspAppLocked)}
            </div>

            <BaseQuestion
              name="readyForReview"
              fieldType="bool"
              orgId={orgId}
              ospId={ospAppId}
              worksheetId={worksheet.uuid}
              text="Are you ready to have this information reviewed?"
              answer={wsAnswers.readyForReview}
              handleChange={handleChangeBaseAnswer}
              disabled={worksheetLocked}
            />
          </WidgetBody>
        </form>
      </Widget>
    );
  }
}

export default WorksheetContainer;
