import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import * as currentOrg from 'sow/selectors/currentOrg';
import { fromPlanApp } from 'sow/store/selectors';
import { ResourceDetailLoader } from 'sow/store/helpers';
import {
  PlanAppSummaryLoader,
  PlanAppLoader,
  PlanAppLocationListLoader,
  PlanAppChangeRequestLoader,
  PlanAppChangeLoader,
} from 'sow/store/containers';
import { isStateInitialApplication, isStateComplete } from 'sow/utils/planAppState';
import * as currentUser from 'sow/selectors/currentUser';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgDashboardPage from 'sow/components/pages/OrgDashboardPage';
import OrgPlanAppCreate from 'sow/containers/planApp/Create';
import { planAppRequest } from 'sow/store/requests';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  org: currentOrg.org(state, props),
  planAppId: fromPlanApp.planAppSummaryIdMostRecent(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  planApp: fromPlanApp.planApp(state, props),
  initialSummary: fromPlanApp.planAppInitialSummary(state, props),
  configPlanMinNumLocations: currentOrg.configPlanMinNumLocations(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  planAppSummaryList: fromPlanApp.planAppSummaryList(state, props),
});

const fakeStatus = status => ({
  ...status,
  error: false,
  done: true,
});

const OrgDashboardPageContainer = props => {
  const { orgId, planAppId, planApp, planAppSummaryList, changeRequestId } = props;

  const isInitial = isStateInitialApplication(planApp);
  const isComplete = isStateComplete(planApp);

  const dashboardProps = {
    ...props,
    isInitial,
    isComplete,
  };

  return (
    <OrgPageTemplate title="Organization Dashboard">
      <PlanAppSummaryLoader orgId={orgId} autoLoad>
        {({ status }) => (
          <ResourceLoaderStatusRender
            statusObjects={[status]}
            renderAllSuccess={() => {
              if (R.isEmpty(planAppSummaryList)) return <OrgPlanAppCreate />;
              return (
                <PlanAppLoader planAppId={planAppId} autoLoad>
                  {({ status }) => (
                    <ResourceLoaderStatusRender
                      statusObjects={[status]}
                      renderAllSuccess={() => (
                        <PlanAppLocationListLoader planAppId={planAppId} autoLoad>
                          {({ status }) => (
                            <ResourceLoaderStatusRender
                              statusObjects={[status]}
                              renderAllSuccess={() => (
                                <ResourceDetailLoader
                                  resource={planAppRequest.initialSummaryUrl(
                                    orgId,
                                    planAppId,
                                  )}
                                  entityType="planAppInitialSummary"
                                  autoLoad={isInitial}
                                >
                                  {({ status }) => {
                                    const initialStatus = isInitial
                                      ? status
                                      : fakeStatus(status);
                                    return (
                                      <ResourceLoaderStatusRender
                                        statusObjects={[initialStatus]}
                                        renderAllDone={() => (
                                          <PlanAppChangeRequestLoader
                                            autoLoad={!R.isNil(changeRequestId)}
                                          >
                                            {({ status: crStatus }) => (
                                              <PlanAppChangeLoader
                                                autoLoad={!R.isNil(changeRequestId)}
                                              >
                                                {({ status: changesStatus }) => {
                                                  const areChangesLoaded =
                                                    changeRequestId &&
                                                    !changesStatus.initial;
                                                  return (
                                                    <ResourceLoaderStatusRender
                                                      statusObjects={[
                                                        areChangesLoaded
                                                          ? changesStatus
                                                          : fakeStatus(changesStatus),
                                                      ]}
                                                      renderAllDone={() => (
                                                        <OrgDashboardPage
                                                          {...dashboardProps}
                                                        />
                                                      )}
                                                    />
                                                  );
                                                }}
                                              </PlanAppChangeLoader>
                                            )}
                                          </PlanAppChangeRequestLoader>
                                        )}
                                      />
                                    );
                                  }}
                                </ResourceDetailLoader>
                              )}
                            />
                          )}
                        </PlanAppLocationListLoader>
                      )}
                    />
                  )}
                </PlanAppLoader>
              );
            }}
          />
        )}
      </PlanAppSummaryLoader>
    </OrgPageTemplate>
  );
};

OrgDashboardPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  planApp: sowTypes.planAppType,
  initialSummary: sowTypes.planAppInitialSummaryType,
  configPlanMinNumLocations: PropTypes.number.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  planAppSummaryList: PropTypes.arrayOf(sowTypes.planAppSummaryType).isRequired,
};

OrgDashboardPageContainer.defaultProps = {
  planAppId: null,
  changeRequestId: null,
  planApp: null,
  initialSummary: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgDashboardPageContainer);
