import React from 'react';
import PropTypes from 'prop-types';

import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Confirm from 'sow/components/molecules/Confirm';

const PlanAppLocationDeleteButton = ({ onClick, disabled }) => (
  <Confirm
    onConfirm={onClick}
    message="The selected location will be deleted from this plan."
  >
    {confirm => (
      <Button sm onClick={confirm} disabled={disabled}>
        <Glyphicon glyph="trash" />
      </Button>
    )}
  </Confirm>
);

PlanAppLocationDeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PlanAppLocationDeleteButton.defaultProps = {
  disabled: false,
};

export default PlanAppLocationDeleteButton;
