import React from 'react';
import PropTypes from 'prop-types';

import { registerRoute } from 'sow/routes';
import { authCredentialsType } from 'sow/types';

import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Form from 'sow/components/atoms/Form';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Link from 'sow/components/atoms/Link';
import Button from 'sow/components/atoms/Button';
import Well from 'sow/components/molecules/Well';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import LoginForm from 'sow/components/organisms/LoginForm';

const LoginPage = ({ credentials, onChange, onSubmit, isAuthenticating }) => (
  <CenteredLayout>
    <Form onSubmit={onSubmit}>
      <Row>
        <Column sm={8} smOffset={2} textLeft>
          <Widget>
            <WidgetHeading>Sign in to your Sow Organic account</WidgetHeading>
            <WidgetBody>
              <LoginForm credentials={credentials} onChange={onChange} />
            </WidgetBody>
            <WidgetFooter textRight>
              <Button type="submit" disabled={isAuthenticating} primary sm>
                Sign In
              </Button>
            </WidgetFooter>
          </Widget>
          <Well
            sm
            style={{
              borderTop: 0,
              borderRadius: 0,
              boxShadow: null,
            }}
          >
            Don’t have an account with Sow Organic yet?{' '}
            <Link to={registerRoute()}>Sign up now!</Link>
          </Well>
        </Column>
      </Row>
    </Form>

    <Row>
      <Column xs={8} xsOffset={2} textLeft />
    </Row>
  </CenteredLayout>
);

LoginPage.propTypes = {
  credentials: authCredentialsType.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

export default LoginPage;
