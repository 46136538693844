import * as R from 'ramda';
import moment from 'moment';

export const utcStrToLocalMoment = utcDateTime => {
  return moment.utc(utcDateTime).local();
};

export const readableDateTime = dateTime => moment(dateTime).format('Y-M-D H:mm');

export const fromNow = dateTime =>
  R.pipe(
    utcStrToLocalMoment,
    dt => moment(dt).fromNow(),
  )(dateTime);
