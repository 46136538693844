import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { MenuItem, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';

import { worksheetQuestionIdType, worksheetQuestionType } from 'sow/types';
import FormGroup from 'sow/components/organisms/FormGroup';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';
import { confirm } from 'sow/actions/pure/messaging';

import * as select from '../selectors';
import FieldTypeSelector from './FieldTypeSelector';
import WorksheetQuestionConditions from './WorksheetQuestionConditions';
import FormPanel from '../FormPanel';
import { actions as wsQuestionAction } from '../reducers/worksheetQuestions';

const makeMapStateToProps = () => (state, props) => ({
  question: select.makeWorksheetQuestionSelector()(state, props),
  worksheetQuestionList: select.makeWorksheetQuestionListSelector()(state, props),
});

const mapDispatchToProps = (dispatch, props) => {
  const questionId = props.questionId;
  return {
    onChangeSetValue: R.curry((field, event) => {
      dispatch(wsQuestionAction.setValue(questionId, field, event.target.value));
    }),

    onAddAnswer: R.curry(event => {
      dispatch(wsQuestionAction.answerAdd(questionId));
    }),
    onDeleteAnswer: R.curry((answerId, event) => {
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this answer?',
          onConfirm: () => dispatch(wsQuestionAction.answerRemove(questionId, answerId)),
        }),
      );
    }),
    onMoveAnswerDown: R.curry((answerId, event) => {
      dispatch(wsQuestionAction.answerMoveDown(questionId, answerId));
    }),
    onMoveAnswerUp: R.curry((answerId, event) => {
      dispatch(wsQuestionAction.answerMoveUp(questionId, answerId));
    }),
    onChangeSetAnswerValue: R.curry((answerId, event) => {
      dispatch(wsQuestionAction.answerSetValue(questionId, answerId, event.target.value));
    }),

    onDeleteCondition: R.curry(conditionIndex => () => {
      dispatch(wsQuestionAction.conditionRemove(questionId, conditionIndex));
    }),
    onSetConditionQuestionId: R.curry((conditionIndex, conditionQuestionId) => {
      dispatch(
        wsQuestionAction.conditionSetQuestionId(
          questionId,
          conditionIndex,
          conditionQuestionId,
        ),
      );
    }),
    onSetConditionAnswerValue: R.curry((conditionIndex, conditionAnswerValue) => {
      dispatch(
        wsQuestionAction.conditionSetAnswerValue(
          questionId,
          conditionIndex,
          conditionAnswerValue,
        ),
      );
    }),
  };
};

// TODO update this to use central definition of types
const isFieldTypeWithAnswers = R.contains(R.__, ['checkbox_list', 'select', 'descriptive_text']);
const showAnswersForQuestion = R.compose(isFieldTypeWithAnswers, R.prop('field_type'));

const WorksheetQuestionAnswers = ({
  question,
  onAddAnswer,
  onDeleteAnswer,
  onChangeSetAnswerValue,
  onMoveAnswerDown,
  onMoveAnswerUp,
}) => {
  if (!showAnswersForQuestion(question)) return null;

  return (
    <FormPanel className="panel-warning">
      <FormPanel.Heading>
        Answers{' '}
        <InfoTooltip overlay="Type in the answer selections you want your clients to choose from when answering this question. " />
        <FormPanel.Heading.Dropdown>
          <MenuItem onClick={onAddAnswer}>
            <Glyphicon glyph="plus" /> Add Answer
          </MenuItem>
        </FormPanel.Heading.Dropdown>
      </FormPanel.Heading>
      <FormPanel.Body>
        {question.answers.map(answer => {
          const answerId = answer.uuid;
          return (
            <FormPanel.Body.ShortRow
              key={answerId}
              handlerId={answerId}
              onDelete={onDeleteAnswer}
              onMoveDown={onMoveAnswerDown}
              onMoveUp={onMoveAnswerUp}
            >
              <input
                type="text"
                id="name"
                className="form-control"
                onChange={onChangeSetAnswerValue(answerId)}
                value={answer.value}
              />
            </FormPanel.Body.ShortRow>
          );
        })}
      </FormPanel.Body>
    </FormPanel>
  );
};

WorksheetQuestionAnswers.propTypes = {
  question: PropTypes.object.isRequired,
  onAddAnswer: PropTypes.func.isRequired,
  onDeleteAnswer: PropTypes.func.isRequired,
  onChangeSetAnswerValue: PropTypes.func.isRequired,
  onMoveAnswerDown: PropTypes.func.isRequired,
  onMoveAnswerUp: PropTypes.func.isRequired,
};

export const WorksheetQuestion = ({
  questionId,
  question,
  worksheetQuestionList,
  onChangeSetValue,
  onAddAnswer,
  onDeleteAnswer,
  onChangeSetAnswerValue,
  onMoveAnswerDown,
  onMoveAnswerUp,
  onDeleteCondition,
  onSetConditionQuestionId,
  onSetConditionAnswerValue,
  sectionType,
}) => {
  if (!question) return null;
  // TODO (orther) add validation if needed
  const errors = {};

  return (
    <div>
      <FormGroup
        fieldName="name"
        label="Name"
        errors={errors}
        tooltip="Enter the question text (for example: What is the name of your operation?)"
        required
      >
        <input
          type="text"
          id={`question_name-${questionId}`}
          className="form-control"
          onChange={onChangeSetValue('name')}
          value={question.name}
        />
      </FormGroup>

      <FormGroup
        fieldName="placeholder"
        label="Placeholder"
        tooltip="Use this to enter example text or helpful information that is shown if the field is empty."
      >
        <input
          type="text"
          id={`question_placeholder-${questionId}`}
          className="form-control"
          onChange={onChangeSetValue('placeholder')}
          value={question.placeholder}
        />
      </FormGroup>

      <FormGroup
        fieldName="tooltip"
        label="InfoTooltip"
        tooltip="Use this to enter helpful text for your clients that is specific to the question you are creating. The text will show up in a hover-over icon that the client will see when they hover over the question."
        errors={errors}
      >
        <input
          type="text"
          id={`question_tooltip-${questionId}`}
          className="form-control"
          onChange={onChangeSetValue('tooltip')}
          value={question.tooltip}
        />
      </FormGroup>

      <FormGroup
        fieldName="field_type"
        label="Field Type"
        tooltip="Select the type of answer for the question (Text: free text field, Number: to capture numbers, Checkbox list: to capture one or more pre-set possible answers, Select: for close-ended questions that offer a long list of possible answers to choose from, Bool: Usually for polar questions (yes-no questions), Files: for upload of a file in the system, Textarea: for answers that require more than a few words (several sentences long), Date: to capture dates"
        errors={errors}
        required
      >
        <FieldTypeSelector
          id={`question-field_type-${questionId}`}
          value={question.field_type}
          handleChange={onChangeSetValue('field_type')}
          className="form-control"
          sectionType={sectionType}
        />
      </FormGroup>

      <WorksheetQuestionAnswers
        question={question}
        onAddAnswer={onAddAnswer}
        onDeleteAnswer={onDeleteAnswer}
        onChangeSetAnswerValue={onChangeSetAnswerValue}
        onMoveAnswerDown={onMoveAnswerDown}
        onMoveAnswerUp={onMoveAnswerUp}
      />

      <WorksheetQuestionConditions
        questionId={questionId}
        question={question}
        worksheetQuestionList={worksheetQuestionList}
        onDeleteCondition={onDeleteCondition}
        onSetConditionQuestionId={onSetConditionQuestionId}
        onSetConditionAnswerValue={onSetConditionAnswerValue}
      />
    </div>
  );
};

WorksheetQuestion.propTypes = {
  questionId: worksheetQuestionIdType.isRequired,
  question: worksheetQuestionType.isRequired,
  worksheetQuestionList: PropTypes.arrayOf(worksheetQuestionType),
  onAddAnswer: PropTypes.func.isRequired,
  onDeleteAnswer: PropTypes.func.isRequired,
  onChangeSetAnswerValue: PropTypes.func.isRequired,
  onChangeSetValue: PropTypes.func.isRequired,
  onMoveAnswerDown: PropTypes.func.isRequired,
  onMoveAnswerUp: PropTypes.func.isRequired,
  onDeleteCondition: PropTypes.func.isRequired,
  onSetConditionQuestionId: PropTypes.func.isRequired,
  onSetConditionAnswerValue: PropTypes.func.isRequired,
  sectionType: PropTypes.string,
};

export default R.compose(connect(makeMapStateToProps, mapDispatchToProps))(
  WorksheetQuestion,
);
