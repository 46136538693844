import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Panel from 'sow/components/molecules/Panel';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormGroup from 'sow/components/organisms/FormGroup';
import Input from 'sow/components/atoms/Input';

const ProfileForm = ({ user, handleChange, errors }) => {
  const confirmRequired = user.password.length > 0;

  return (
    <Block>
      <Panel title="General Information">
        <FormGroup fieldName="name" errors={errors} label="Your name" required>
          <input
            type="text"
            id="name"
            value={user.name}
            onChange={e => handleChange('name', e.target.value)}
            className="form-control"
            required
          />
        </FormGroup>

        <FormGroup
          fieldName="username"
          errors={errors}
          label="Your email address"
          required
        >
          <Input
            type="email"
            id="username"
            value={user.username}
            onChange={e => handleChange('username', e.target.value)}
            className="form-control"
            required
          />
        </FormGroup>
      </Panel>

      <Panel title="Change Password">
        <Row>
          <Column md={6}>
            <FormGroup
              fieldName="old_password"
              errors={errors}
              label="Current Password"
              required={confirmRequired}
            >
              <Input
                type="password"
                id="old_password"
                value={user.old_password}
                onChange={e => handleChange('old_password', e.target.value)}
                className="form-control"
                required={confirmRequired}
              />
            </FormGroup>
          </Column>

          <Column md={6}>
            <FormGroup fieldName="password" errors={errors} label="New Password">
              <Input
                type="password"
                id="password"
                value={user.password}
                onChange={e => handleChange('password', e.target.value)}
                className="form-control"
              />
            </FormGroup>
          </Column>
        </Row>
      </Panel>
    </Block>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default ProfileForm;
