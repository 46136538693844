import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentOrg from 'sow/selectors/currentOrg';
import Spinner from 'sow/components/atoms/Spinner';
import actions from 'sow/actions/pure';
import ErrorLoadingDataPage from 'sow/components/pages/ErrorLoadingDataPage';

const mapStateToProps = (state, props) => ({
  loadFailed: currentOrg.isLoadFail(state, props),
  loading: currentOrg.isLoading(state, props),
  shouldLoad: currentOrg.shouldLoad(state, props),
  org: currentOrg.org(state, props),
  orgId: currentOrg.orgId(state, props),
});

const mapDispatchToProps = {
  loadOneOrg: actions.org.loadOne,
  resetOrgChildData: actions.org.resetChildData,
};

class OrgLoader extends React.Component {
  componentDidMount() {
    this.loadData(this.props);
  }

  componentDidUpdate() {
    this.loadData(this.props);
  }

  loadData = ({ orgId, shouldLoad, loadOneOrg, resetOrgChildData }) => {
    if (shouldLoad && orgId) {
      resetOrgChildData();
      loadOneOrg(orgId);
    }
  };

  render() {
    if (this.props.loadFailed) {
      return <ErrorLoadingDataPage />;
    }

    if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.org) {
      return this.props.children(this.props.org);
    }

    return null;
  }
}

OrgLoader.propTypes = {
  children: PropTypes.func,
  loadFailed: PropTypes.bool,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  org: PropTypes.any,
  orgId: PropTypes.number,
  shouldLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OrgLoader);
