import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import { FormattedDatetime } from './style/atoms';

// --
// -- Components
// --

const HeaderWrapper = styled.div`
  p {
    font-size: 1.6em;
    margin: 2em auto;
    text-align: center;
  }
`;

const Header = ({ planAppId, planAppSummary }) => {
  const ospDefName = R.path(['ospDefinition', 'name'], planAppSummary);
  return (
    <HeaderWrapper>
      Exported from Sow Organic on{' '}
      <strong>
        <FormattedDatetime />
      </strong>
      <p>
        {ospDefName} #{planAppId}
      </p>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  planAppId: sowTypes.planAppIdType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
};

Header.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
};

export default Header;
