import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { planAppStates } from 'sow/constants/planApp';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Heading from 'sow/components/atoms/Heading';
import OrgDashboardActionButtonsOrg from 'sow/containers/planApp/OrgDashboardActionButtonsOrg';

const Wrapper = styled.div`
  padding: 10px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  text-align: center;
`;

const QuestionIcon = styled.div`
  margin: 20px;
  font-size: 60pt;
`;

const NextText = styled.div`
  font-size: 10pt;
  margin: 10px;
`;

const PlanAppWhatsNext = ({ planApp, hasWorksheets }) => {
  let nextText;
  switch (planApp.state) {
    case planAppStates.INITIAL_APPLICATION:
      if (!hasWorksheets)
        nextText =
          'Welcome! To get started, fill out the Activity Checklist to add the necessary worksheets to your plan.';
      else if (planApp.percentageComplete < 100)
        nextText =
          "Finish filling out your worksheets and locations. Once you're done, you'll be able to submit your plan for review.";
      else
        nextText =
          'Your plan is ready to submit. When you are ready for these changes to be reviewed by your certifier click the submit button below and your certifier will be notified.';
      break;

    case planAppStates.REVISION:
      nextText =
        'You may make changes to your plan. When you are ready for these changes to be reviewed by your certifier click the submit button below and your certifier will be notified.';
      break;

    case planAppStates.INITIAL_APPLICATION_SUBMITTED:
      nextText =
        'Your certifier has received your plan. You can still make corrections or other modifications and your certifier will be notified automatically.';
      break;

    case planAppStates.REVISION_SUBMITTED:
      nextText =
        'Your certifier has received your update. You can still make corrections or other modifications and your certifier will be notified automatically.';
      break;

    case planAppStates.INITIAL_REVIEW:
      nextText =
        'Your certifier is reviewing your plan. You can still make corrections or other modifications and your certifier will be notified automatically.';
      break;

    case planAppStates.INSPECTION:
      nextText =
        'Your certifier is reviewing your plan. You can still make corrections or other modifications and your certifier will be notified automatically.';
      break;

    case planAppStates.FINAL_REVIEW:
      nextText =
        'Your certifier is reviewing your plan. You can still make corrections or other modifications and your certifier will be notified automatically.';
      break;

    case planAppStates.COMPLETE:
      nextText = 'Your plan has been approved.';
      break;

    case planAppStates.NONCOMPLIANCE:
      nextText =
        'There is a noncompliance in your plan. Please contact your certifier to determine how you should proceed.';
      break;

    case planAppStates.WITHDRAWN:
      nextText =
        'Your plan has been withdrawn. Please contact your certifier if you want it re-opened.';
      break;

    default:
      nextText = null;
  }

  return (
    <Wrapper>
      <QuestionIcon textInfo>
        <FontAwesome icon="question-circle" textInfo />
      </QuestionIcon>
      <Heading level={3} textInfo style={{ marginTop: '-5px' }}>
        {hasWorksheets && "What's Next"}
        {!hasWorksheets && 'Get Started'}
      </Heading>
      <NextText>{nextText}</NextText>

      <OrgDashboardActionButtonsOrg planAppId={planApp.id} planApp={planApp} />
    </Wrapper>
  );
};

PlanAppWhatsNext.propTypes = {
  planApp: sowTypes.planAppType.isRequired,
  hasWorksheets: PropTypes.bool.isRequired,
};

export default PlanAppWhatsNext;
