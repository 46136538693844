import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetMatrixCell from 'sow/components/organisms/WorksheetMatrix/WorksheetMatrixCell';

const mapStateToProps = (state, props) => ({
  question: fromPlanApp.question(state, props),
  change: R.isNil(props.locationId)
    ? fromPlanApp.questionChange(state, props)
    : fromPlanApp.locationQuestionChange(state, props),
  hasNotes: R.length(fromPlanApp.notes(state, props)) > 0,
});

const WorksheetMatrixCellContainer = props => {
  return <WorksheetMatrixCell {...props} />;
};

WorksheetMatrixCellContainer.propTypes = {
  // Passed props
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  questionId: sowTypes.planAppWorksheetQuestionIdType.isRequired,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType.isRequired,
  baseAnswersName: PropTypes.string.isRequired,
  baseAnswersChangesName: PropTypes.string.isRequired,

  // Connected props
  change: sowTypes.planAppChangeType,
  hasNotes: PropTypes.bool.isRequired,
};

WorksheetMatrixCellContainer.defaultProps = {
  change: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(WorksheetMatrixCellContainer);
