import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import actions from 'sow/actions/pure';
import * as currentAca from 'sow/selectors/currentAca';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import FormGroup from 'sow/components/organisms/FormGroup';
import FormPanel from 'sow/components/old/OrgSettings/FormPanel';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import Button from 'sow/components/atoms/Button';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  showAdminUI: currentUser.showAdminUI(state, props),
  orgActive: currentOrg.org(state, props).active,
});

const mapDispatchToProps = {
  activateOrg: actions.org.activate,
  deactivateOrg: actions.org.deactivate,
};

class AcaForm extends Component {
  getSettings = () => {
    const { org } = this.props;
    return R.defaultTo({}, R.prop('settings', org));
  };

  getAcaSettingsPath = field => {
    const { acaId } = this.props;
    return ['aca_byid', `${acaId}`, `${field}`];
  };

  getAcaSettingValue = field => {
    return R.pathOr('', this.getAcaSettingsPath(field), this.getSettings());
  };

  setAcaSettingValue = (field, value) => {
    return R.assocPath(this.getAcaSettingsPath(field), value, this.getSettings());
  };

  handleActivateOrg = () => {
    const { activateOrg, org } = this.props;
    activateOrg(org.id);
  };

  handleDeactivateOrg = () => {
    const { deactivateOrg, org } = this.props;
    deactivateOrg(org.id);
  };

  handleChangeInput = field => event => {
    const value = event.target.value;
    this.props.handleChange(field, value);
  };

  handleChangeSetting = field => value => {
    this.props.handleChange('settings', this.setAcaSettingValue(field, value));
  };

  handleChangeSettingInput = field => event => {
    const value = event.target.value;
    this.handleChangeSetting(field)(value);
  };

  render() {
    const { disabled, errors, orgActive, showAdminUI } = this.props;

    //const billingErrors  = R.propOr(null, 'billing_location', errors);
    //const legalErrors    = R.propOr(null, 'legal_location', errors);
    //const locationErrors = R.propOr(null, 'location', errors);

    return (
      <div>
        <InfoPanel
          text={
            <span>
              <b>NOTE:</b> The ACA settings tab is only accessible to ACA staff and
              organization users will not see it.
            </span>
          }
        />
        <FormPanel title="Client ID">
          <FormGroup fieldName="client_id_label" errors={errors} label="Label" required>
            <input
              type="text"
              id="client_id_label"
              value={this.getAcaSettingValue('client_id_label')}
              onChange={this.handleChangeSettingInput('client_id_label')}
              className="form-control"
              disabled={disabled}
            />
          </FormGroup>
          <FormGroup fieldName="client_id_value" errors={errors} label="Value" required>
            <input
              type="text"
              id="client_id_value"
              value={this.getAcaSettingValue('client_id_value')}
              onChange={this.handleChangeSettingInput('client_id_value')}
              className="form-control"
              disabled={disabled}
            />
            <small className="help-block">
              This field is searchable on the organization list page.
            </small>
          </FormGroup>
        </FormPanel>
        {showAdminUI && (
          <FormPanel
            title={
              <span>
                Sow Organic Admin Options <small>Not visible to ACA</small>
              </span>
            }
          >
            <InfoPanel
              text={
                <span>
                  <b>NOTE:</b> Inactive users will be able to login and to make changes
                  BUT they will see large notice to contact their ACA.
                </span>
              }
            />

            {orgActive ? (
              <Button danger onClick={this.handleDeactivateOrg}>
                Dectivate Organization
              </Button>
            ) : (
              <Button onClick={this.handleActivateOrg}>Activate Organization</Button>
            )}
          </FormPanel>
        )}
      </div>
    );
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaForm);
