import { combineReducers } from 'redux';

import byId, * as fromById from './byId';
import isLoading from './isLoading';

export default combineReducers({
  byId, // new convention for storing results in id=>key map
  isLoading,
});

export const getAllOrgIds = state => fromById.getIds(state.byId);
export const getIsLoading = state => state.isLoading;
export const getOrg = (state, id) => fromById.getOrg(state.byId, id);
export const getOrgList = state => fromById.getOrgList(state.byId);
