import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { worksheetRoute, locationRoute } from 'sow/routes';
import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Link from 'sow/components/atoms/Link';
import Heading from 'sow/components/atoms/Heading';
import Block from 'sow/components/atoms/Block';
import ChangeListItem from 'sow/containers/planApp/ChangeListItem';
import ChangeCommentDismissAllButton from 'sow/containers/planApp/ChangeCommentDismissAllButton';
import ChangeListFilterToggleButtons from './ChangeListFilterToggleButtons';
import LocationDetails from 'sow/containers/planApp/LocationDetails';

const WorksheetName = styled(Heading)`
  color: #333333;
`;

const PlanAppChangeRequestManager = ({
  title,
  orgId,
  planAppId,
  locationId,
  worksheetId,
  changeList,
  filter,
  onToggleFilter,
  changeCounts,
  worksheets,
  locations,
  showPlanChecklist,
  showPlanWorksheets,
  showLocations,
}) => {
  const worksheetChanges = worksheetId =>
    R.filter(R.whereEq({ worksheetId, landId: null }))(changeList);
  const worksheetHasChanges = worksheetId => !R.isEmpty(worksheetChanges(worksheetId));

  const locationChanges = locationId =>
    R.filter(R.whereEq({ landId: locationId }))(changeList);
  const locationHasChanges = locationId => !R.isEmpty(locationChanges(locationId));

  const activityChecklistChange = R.pipe(
    R.filter(
      R.whereEq({
        context: 'main',
        type: 'plan',
      }),
    ),
    R.propOr(null, 0),
  )(changeList);

  return (
    <Widget>
      <WidgetHeading>
        {title}
        <Block pullRight style={{ marginTop: '-5px' }}>
          <ChangeCommentDismissAllButton changeList={changeList} />

          <ChangeListFilterToggleButtons
            filter={filter}
            onToggleFilter={onToggleFilter}
            changeCounts={changeCounts}
          />
        </Block>
      </WidgetHeading>
      <WidgetBody>
        {!R.isEmpty(changeList) && (
          <Fragment>
            {showPlanChecklist && activityChecklistChange && (
              <ChangeListItem changeId={activityChecklistChange.id} />
            )}
            {showPlanWorksheets &&
              worksheets.map(
                ws =>
                  worksheetHasChanges(ws.uuid) && (
                    <Block key={ws.uuid}>
                      {/* Don't render worksheet name when looking at a worksheet */}
                      {!worksheetId && (
                        <Link to={worksheetRoute(orgId, planAppId, ws.uuid)}>
                          <WorksheetName level={4}>{ws.name}</WorksheetName>
                        </Link>
                      )}
                      {worksheetChanges(ws.uuid).map(change => (
                        <ChangeListItem key={change.id} changeId={change.id} />
                      ))}
                    </Block>
                  ),
              )}

            {showLocations &&
              locations.map(locId => (
                <LocationDetails key={locId} locationId={locId}>
                  {loc =>
                    locationHasChanges(locId) && (
                      // `locationId` is pulled from the route, hence locId/locationId
                      <Block>
                        {!locationId && (
                          <Link to={locationRoute(orgId, planAppId, locId)}>
                            <WorksheetName level={4}>{loc.name}</WorksheetName>
                          </Link>
                        )}
                        {locationChanges(locId).map(change => (
                          <Block key={change.id}>
                            <ChangeListItem key={change.id} changeId={change.id} />
                          </Block>
                        ))}
                      </Block>
                    )
                  }
                </LocationDetails>
              ))}
          </Fragment>
        )}

        {R.isEmpty(changeList) && (
          <Block textMuted>No changes matching current filters.</Block>
        )}
      </WidgetBody>
    </Widget>
  );
};

PlanAppChangeRequestManager.propTypes = {
  title: PropTypes.node.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  // worksheetId prop may optionally be passed in when CR manager is displayed on a worksheet page
  worksheetId: sowTypes.planAppWorksheetIdType,
  locationId: sowTypes.planAppLocationIdType,
  changeList: sowTypes.planAppChangeListType.isRequired,
  filter: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    accepted: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  changeCounts: PropTypes.shape({
    open: PropTypes.number.isRequired,
    accepted: PropTypes.number.isRequired,
    rejected: PropTypes.number.isRequired,
  }).isRequired,
  worksheets: PropTypes.arrayOf(sowTypes.planAppWorksheetType),
  locations: PropTypes.arrayOf(sowTypes.planAppLocationIdType),
  showPlanChecklist: PropTypes.bool.isRequired,
  showPlanWorksheets: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
};

PlanAppChangeRequestManager.defaultProps = {
  worksheetId: null,
  locationId: null,
};

export default PlanAppChangeRequestManager;
