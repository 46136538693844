import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { pending, rejected, done } from 'redux-saga-thunk';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import * as currentOrg from 'sow/selectors/currentOrg';
import { getAuthOrgListState } from 'sow/reducers/auth';
import { fromEntities, fromResource } from 'sow/store/selectors';
import OrgSwitcherModal from 'sow/components/organisms/OrgSwitcherModal';

const mapStateToProps = (state, props) => ({
  currentOrgId: currentOrg.orgId(state, props),
  authOrgList: getAuthOrgListState(state),
  orgListDone: done(state, `orgListRead`),
  orgListFailed: rejected(state, `orgListRead`),
  orgListPending: pending(state, `orgListRead`),
  orgList: fromEntities.getList(state, 'org', fromResource.getList(state, 'org')),
});

const mapDispatchToProps = {
  loadListOrg: actions.org.loadList,
};

const sortOrgList = R.sortWith([R.ascend(R.prop('name'))]);

class OrgSwitcherModalContainer extends Component {
  static propTypes = {
    currentOrgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    authOrgList: PropTypes.array,
    orgListDone: PropTypes.bool,
    orgListFailed: PropTypes.bool,
    orgListPending: PropTypes.bool,
    orgList: PropTypes.array,
    loadListOrg: PropTypes.func,
  };

  state = {
    showModal: false,
  };

  handleShowModal = () => {
    if (!this.props.orgListPending) {
      this.props.loadListOrg(this.props.authOrgList);
    }

    this.setState({ showModal: true });
  };

  handleHideModal = () => this.setState({ showModal: false });

  render() {
    const { showModal } = this.state;
    const { currentOrgId, authOrgList, orgListPending, orgList } = this.props;

    if (authOrgList.length < 2) {
      return null;
    }

    const switchableOrgs = orgList.filter(org => R.contains(org.id, authOrgList));
    const sortedSwitchableOrgs = sortOrgList(switchableOrgs, 'name');

    return (
      <OrgSwitcherModal
        showModal={showModal}
        onShowModal={this.handleShowModal}
        onHideModal={this.handleHideModal}
        currentOrgId={currentOrgId}
        orgListPending={orgListPending}
        switchableOrgs={sortedSwitchableOrgs}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OrgSwitcherModalContainer);
