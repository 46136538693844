import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

/** Image tag with convenience callback props */
class Image extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string,
  };

  state = {
    isLoaded: false,
    isErrored: false,
  };

  handleImageLoaded = event => {
    this.setState(R.assoc('isLoaded', true));
  };

  handleImageErrored = event => {
    this.setState(R.assoc('isErrored', true));
  };

  render() {
    const { imageUrl, ...props } = this.props;
    const { isErrored } = this.state;

    if (isErrored) return null;

    return (
      <img
        src={imageUrl}
        onLoad={this.handleImageLoaded}
        onError={this.handleImageErrored}
        {...props}
      />
    );
  }
}

export default Image;
