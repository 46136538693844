import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentOrg from 'sow/selectors/currentOrg';
import actions from 'sow/actions/pure';
import Form from 'sow/components/old/Form';

const mapStateToProps = (state, props) => ({
  org: currentOrg.org(state, props),
});

const mapDispatchToProps = {
  updateOrg: actions.org.updateOne,
};

class OrgSettingsEdit extends Component {
  constructor(props) {
    super(props);

    console.log('org:', props.org);
    this.state = {
      org: props.org,
    };
  }

  handleChange = (field, value) => {
    this.setState(R.assocPath(['org', field], value));
  };

  handleSubmit = successFn => {
    const { updateOrg } = this.props;
    const { org } = this.state;

    updateOrg(org, successFn);
  };

  render() {
    const { errors, children } = this.props;
    const { org } = this.state;

    const child = React.Children.only(children);

    return (
      <Form
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        org={org}
        errors={errors}
        inEditMode={true}
      >
        {child}
      </Form>
    );
  }
}

OrgSettingsEdit.propTypes = {
  org: PropTypes.object.isRequired,
  updateOrg: PropTypes.func,
  errors: PropTypes.object,
  children: PropTypes.node,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OrgSettingsEdit);
