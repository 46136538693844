import React from 'react';
import * as R from 'ramda';

import FormGroup from 'sow/components/organisms/FormGroup';
import AttachmentCategorySelect from './AttachmentCategorySelect';

const AttachmentForm = ({ attachment, errors, isAdmin, handleChange }) => (
  <div className="row">
    <div className={`col-xs-12 col-sm-${isAdmin ? 6 : 12}`}>
      <FormGroup fieldName="name" errors={errors} label="Name" required>
        <input
          type="text"
          id="name"
          value={attachment.name}
          onChange={e => handleChange('name', e.target.value)}
          className="form-control"
          required
        />
      </FormGroup>
      <FormGroup fieldName="category" errors={errors} label="Category">
        <AttachmentCategorySelect
          id="category"
          value={attachment.category}
          onChange={value => handleChange('category', value)}
        />
      </FormGroup>
    </div>

    {isAdmin && (
      <div className={`col-xs-12 col-sm-${isAdmin ? 6 : 12}`}>
        <FormGroup fieldName="public" errors={errors} label="Visibility" required>
          <select
            id="public"
            value={R.path(['public'], attachment)}
            onChange={e => handleChange('public', e.target.value === 'true')}
            className="form-control"
            required={true}
          >
            <option value="false">ACA Only</option>
            <option value="true">Public</option>
          </select>
        </FormGroup>
      </div>
    )}
  </div>
);

export default AttachmentForm;
