import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentOspApp from 'sow/selectors/currentOspApp';
import actions from 'sow/actions/pure';

import OspAppSelectBar from './OspAppSelectBar';

const mapStateToProps = (state, props) => ({
  orgId: currentOspApp.orgId(state, props),
  ospAppId: currentOspApp.ospAppId(state, props),
  ospAppList: currentOrg.ospAppList(state, props),
});

const mapDispatchToProps = {
  setCurrentOspAppForOrg: actions.ospApp.setCurrentForOrg,
  loadOspAppList: actions.ospApp.loadOrgList,
};

const mergeProps = ({ orgId, ospAppId, ospAppList }, dispatchProps) => ({
  ospAppId,
  ospAppList,
  setCurrentOspApp: newOspAppId =>
    dispatchProps.setCurrentOspAppForOrg(orgId, parseInt(newOspAppId, 10)),
  loadOspAppList: () => dispatchProps.loadOspAppList(orgId),
});

class OspAppSelectBarContainer extends React.Component {
  componentDidMount() {
    this.props.loadOspAppList();
  }

  componentDidUpdate(prevProps) {
    const { ospAppList } = this.props;

    // Once the OSP app list is loaded, set current to the most recent one
    if (R.isEmpty(prevProps.ospAppList) && !R.isEmpty(ospAppList)) {
      R.pipe(
        R.last,
        R.prop('id'),
        this.props.setCurrentOspApp,
      )(ospAppList);
    }
  }

  render() {
    return <OspAppSelectBar {...this.props} />;
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(OspAppSelectBarContainer);
