import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import * as sowTypes from 'sow/types';
import { acaRoute } from 'sow/routes';
import * as currentAca from 'sow/selectors/currentAca';
import { fetchActivityLog, resetActivityLog } from 'sow/actions/aca';
import AcaActivityDashboardPage from 'sow/components/pages/AcaActivityDashboardPage';
import AcaPageTemplate from '../AcaPageTemplate';
import Spinner from 'sow/components/atoms/Spinner';

const isMoment = R.is(moment);

const utcFormatDatetimeForApi = datetime => {
  if (!isMoment(datetime)) return null;
  return moment(datetime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
};

const compactObj = R.reject(R.isEmpty);

const formatFiltersDataForApi = R.pipe(
  R.evolve({
    start_datetime: utcFormatDatetimeForApi,
    end_datetime: utcFormatDatetimeForApi,
  }),
  compactObj,
);

const mapStateToProps = (state, props) => ({
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  acaName: currentAca.name(state, props),
  isFetching: state.acaActivityLog.isFetching,
  logs: state.acaActivityLog.items,
});

const mapDispatchToProps = { fetchActivityLog, resetActivityLog };

class AcaActivityDashboardPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      filters: this.defaultFilters(props),
    };
  }

  defaultFilters = props => {
    const query = queryString.parse(props.location.search);
    return {
      user_id: query.user_id,
      page_size: query.page_size || 2000,
      start_datetime: query.start_datetime
        ? moment.utc(query.start_datetime).local()
        : moment().subtract(14, 'days'),
      end_datetime: query.end_datetime
        ? moment.utc(query.end_datetime).local()
        : moment(),
      organization_ids: query.org_id ? [query.org_id] : [],
      action: query.action || '',
    };
  };

  componentDidMount() {
    this.loadActivityLog();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.state;

    if (!R.equals(filters, prevState.filters)) {
      const end = R.path(['end_datetime'], filters);
      const start = R.path(['start_datetime'], filters);

      const errors = compactObj({
        end_datetime: !isMoment(end) ? ['Invalid Date/Time'] : [],
        start_datetime: !isMoment(start) ? ['Invalid Date/Time'] : [],
      });

      if (!R.isEmpty(errors)) {
        this.setState(R.assoc('errors', errors), this.props.resetActivityLog);
      } else {
        this.setState(R.assoc('errors', {}), this.loadActivityLog());
      }
    }
  }

  loadActivityLog() {
    const { start_datetime, end_datetime } = this.state.filters;

    const filters = {
      ...this.state.filters,
      start_datetime: moment(start_datetime).second(0),
      end_datetime: moment(end_datetime)
        .second(0)
        .add('1', 'minute'),
    };

    const filtersData = formatFiltersDataForApi(filters);
    this.props.fetchActivityLog(this.props.acaId, filtersData);
  }

  handleChange = field => value => {
    let newValue;

    if (field === 'organization_ids') {
      if (value) newValue = [value];
      else newValue = [];
    } else {
      newValue = value || '';
    }

    this.setState(R.assocPath(['filters', field], newValue));
  };

  handleResetFilters = () => this.setState({ filters: this.defaultFilters(this.props) });

  render() {
    const { errors, filters } = this.state;
    const { acaId, aca, acaName, logs, isFetching } = this.props;

    return (
      <AcaPageTemplate title="Activity Log" breadcrumb={[[acaName, acaRoute(acaId)]]}>
        <AcaActivityDashboardPage
          acaId={acaId}
          aca={aca}
          logs={logs}
          errors={errors}
          filters={filters}
          onFilterChange={this.handleChange}
          isFetching={isFetching}
          onResetFilters={this.handleResetFilters}
        />
      </AcaPageTemplate>
    );
  }
}

AcaActivityDashboardPageContainer.propTypes = {
  acaId: sowTypes.acaIdType,
  aca: sowTypes.acaType,
  acaName: PropTypes.string,
  logs: PropTypes.arrayOf(PropTypes.object),
  fetchActivityLog: PropTypes.func,
  resetActivityLog: PropTypes.func,
  isFetching: PropTypes.bool,
  location: PropTypes.object,
};

AcaActivityDashboardPageContainer.defaultProps = {
  acaId: null,
  aca: null,
  acaName: null,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  // withCurrentAca,
)(AcaActivityDashboardPageContainer);
