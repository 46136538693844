import * as R from 'ramda';
import { createSelector } from 'reselect';

import { coerceToIntOr } from 'sow/utils/selectors';
import * as orgReducer from 'sow/reducers/organizations';
// NOTE: Unlike all other selectors, we DO import this module from it's parent
//       ACA selector. Do NOT import 'sow/selectors/core/aca' in this module!

export const orgState = state => state.organizations;

export const orgIdProp = (state, props) => R.path(['orgId'], props);

export const orgIdRouteParam = (state, props) => {
  return R.path(['match', 'params', 'orgId'], props);
};

export const currentOrgIdRaw = createSelector(
  [orgIdProp, orgIdRouteParam],
  R.defaultTo,
);

export const currentOrgId = createSelector(
  [currentOrgIdRaw],
  orgId => (orgId ? ~~orgId : null),
);

export const allOrgs = createSelector(
  [orgState, currentOrgId],
  orgReducer.getOrgList,
);

export const allOrgsMap = createSelector(
  [orgState],
  R.prop('byId'),
);

export const currentOrg = createSelector(
  [orgState, currentOrgId],
  (state, orgId) => orgReducer.getOrg(state, orgId),
);

// export const isLoading = createSelector([orgState], orgReducer.getIsLoading);
export const isLoadingList = createSelector(
  [orgState],
  R.path(['isLoading', 'list']),
);

export const isLoadingOneOrgIds = createSelector(
  [orgState],
  R.path(['isLoading', 'one']),
);

export const isLoadingOneFailedOrgIds = createSelector(
  [orgState],
  R.path(['isLoading', 'oneFailed']),
);

export const isLoadingCurrentOrg = createSelector(
  [currentOrgId, isLoadingOneOrgIds],
  R.contains,
);

export const isLoadFailedCurrentOrg = createSelector(
  [currentOrgId, isLoadingOneFailedOrgIds],
  R.contains,
);

// NOTE: we need to load the full org which is slightly different than the index version
//       we can detect if it's a show / detail version if it doesn't have aca_id. In the
//       future we should store two versions or merge their values somehow.
const isOrgDetail = org => !!R.path(['aca_id'], org);

// NOTE: we may want to add invalidate and what not in future
export const isLoadedCurrentOrg = createSelector(
  [currentOrg],
  isOrgDetail,
);

// NOTE selector to determine if the org should load
export const shouldLoadCurrentOrg = createSelector(
  [isLoadedCurrentOrg, isLoadingCurrentOrg, isLoadFailedCurrentOrg],
  (loaded, loading, failed) => {
    return !loaded && !loading && !failed;
  },
);

export const currentOrgName = createSelector(
  [currentOrg],
  org => org.name,
);

export const currentOrgAcaId = createSelector(
  [currentOrg],
  R.path(['aca_id']),
);

export const currentOrgAcaConfig = createSelector(
  [currentOrg],
  R.path(['aca_config']),
);

export const currentOrgAcaConfigPlanEditLocationByOrgAllowed = createSelector(
  [currentOrgAcaConfig],
  R.pipe(
    // NOTE this needs to be camel cased when we move orgs to URM entities
    R.path(['plan_edit_location_by_org_allowed']),
    flag => !!flag, // NOTE this makes it default to false
  ),
);

export const currentOrgAcaConfigPlanMinNumLocations = createSelector(
  [currentOrgAcaConfig],
  R.pipe(
    // NOTE this needs to be camel cased when we move orgs to URM entities
    R.path(['plan_min_num_locations']),
    coerceToIntOr(0),
  ),
);

export const currentOrgFacilityScopes = createSelector(
  [currentOrgAcaConfig],
  R.path(['facility_scopes']),
);

export const currentOrgOfficeScopes = createSelector(
  [currentOrgAcaConfig],
  R.path(['office_scopes']),
);

export const currentOrgParcelScopes = createSelector(
  [currentOrgAcaConfig],
  R.path(['parcel_scopes']),
);

export const currentOrgPastureScopes = createSelector(
  [currentOrgAcaConfig],
  R.path(['pasture_scopes']),
);

export const currentOrgVisibleTypes = createSelector(
  [currentOrgAcaConfig],
  R.path(['visible_types']),
);

export const currentOrgAllLandScopes = createSelector(
  [
    currentOrgFacilityScopes,
    currentOrgOfficeScopes,
    currentOrgParcelScopes,
    currentOrgPastureScopes,
  ],
  R.compose(
    R.uniq,
    R.unnest,
    R.unapply(R.sort(R.prop('length'))),
  ),
);
