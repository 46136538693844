import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';

import { worksheetQuestionIdType, worksheetQuestionType } from 'sow/types';
import FormGroup from 'sow/components/organisms/FormGroup';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';

import FormPanel from '../FormPanel';

const getQuestionSelectorOptions = questionList => {
  const questionOptions = questionList.map(question => ({
    value: question.uuid,
    label: question.name,
  }));

  return questionOptions;
};

export const AnswerValueSelector = ({ value, disabled, handleChange }) => {
  const options = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
  const onChange = question => handleChange(question.value);

  return (
    <Select
      disabled={disabled}
      options={options}
      onChange={onChange}
      value={value}
      clearable={false}
    />
  );
};

AnswerValueSelector.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

export const QuestionSelector = ({ value, questionList, handleChange, handleRemove }) => {
  const options = getQuestionSelectorOptions(questionList);
  const onChange = question => {
    if (question === null) {
      handleRemove();
    }

    handleChange(question.value);
  };

  return <Select options={options} onChange={onChange} value={value} clearable />;
};

QuestionSelector.propTypes = {
  value: PropTypes.any,
  questionList: PropTypes.arrayOf(worksheetQuestionType).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export const WorksheetQuestionConditions = ({
  conditionQuestionId,
  conditionAnswerValue,
  conditionQuestionList,
  onDeleteCondition,
  onSetConditionQuestionId,
  onSetConditionAnswerValue,
}) => {
  const hasConditionQuestions = conditionQuestionList.length < 1;

  // NOTE: we manually set the conditionIndex to 0 since we only support a single condition for now
  const handleRemove = onDeleteCondition(0);
  const handleChangeQuestionId = onSetConditionQuestionId(0);
  const handleChangeAnswerValue = onSetConditionAnswerValue(0);

  return (
    <FormPanel className="panel-info">
      <FormPanel.Heading>
        Conditional Show{' '}
        <InfoTooltip overlay="Questions can be hidden until a condition is met. Currently the only condition supported is for Bool questions (Yes / No)." />
      </FormPanel.Heading>
      <FormPanel.Body>
        <FormPanel.Body.ShortRow>
          <FormGroup
            fieldName="condition_question_selector"
            label="Question"
            tooltip="Select the question used to conditionally show this question."
          >
            <QuestionSelector
              value={conditionQuestionId}
              questionList={conditionQuestionList}
              handleChange={handleChangeQuestionId}
              handleRemove={handleRemove}
            />
          </FormGroup>

          <FormGroup
            fieldName="condition_answer_value_selector"
            label="Answer Value"
            tooltip="Select the value the above question must have to show this question."
          >
            <AnswerValueSelector
              value={conditionAnswerValue}
              handleChange={handleChangeAnswerValue}
              disabled={!conditionQuestionId}
            />
          </FormGroup>
        </FormPanel.Body.ShortRow>
      </FormPanel.Body>
    </FormPanel>
  );
};

WorksheetQuestionConditions.propTypes = {
  questionId: worksheetQuestionIdType.isRequired,
  question: worksheetQuestionType.isRequired,
  worksheetQuestionList: PropTypes.arrayOf(worksheetQuestionType).isRequired,
  conditionQuestionList: PropTypes.arrayOf(worksheetQuestionType).isRequired,
  conditionQuestionId: worksheetQuestionIdType,
  conditionAnswerValue: PropTypes.any,
  onDeleteCondition: PropTypes.func.isRequired,
  onSetConditionAnswerValue: PropTypes.func.isRequired,
  onSetConditionQuestionId: PropTypes.func.isRequired,
};

/**
 * Container Component Below
 */

export const getConditionQuestions = (currentQuestionId, worksheetQuestionList) => {
  if (R.type(currentQuestionId) !== 'String')
    throw `getConditionQuestions requires currentQuestionId argument be a string but was: ${currentQuestionId}`;

  if (R.type(worksheetQuestionList) !== 'Array')
    throw `getConditionQuestions requires worksheetQuestionList argument be an array but was: ${worksheetQuestionList}`;

  return R.filter(
    R.where({
      // filter out the question these conditions are for
      uuid: R.complement(R.equals(currentQuestionId)),
      // only show questions bool (Yes/No) until later implementations
      field_type: R.equals('bool'),
    }),
    worksheetQuestionList,
  );
};

export const mapStateToProps = (state, props) => {
  const { questionId, question, worksheetQuestionList } = props;

  // NOTE: we grab the first coniditon's values here until we support multiple conditions
  const condition = R.pathOr({}, ['conditions', 0], question);
  const conditionQuestionId = R.path(['condition', 'question_uuid'], condition);
  const conditionAnswerValue = R.path(['condition', 'answer_value'], condition);

  return {
    conditionQuestionId,
    conditionAnswerValue,
    conditionQuestionList: getConditionQuestions(questionId, worksheetQuestionList),
  };
};

export default R.compose(connect(mapStateToProps))(WorksheetQuestionConditions);
