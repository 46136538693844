import { normalize } from 'normalizr';

import * as orgOspAppApi from 'sow/api/org/ospApplication';
import * as schema from 'sow/schema';

import { makeBuildData } from 'sow/utils/data';

function mark(action) {
  return `soworganic/ospApplication/${action}`;
}

export const LOAD_ALL_REQUEST = mark('LOAD_ALL_REQUEST');
export const LOAD_ALL_SUCCESS = mark('LOAD_ALL_SUCCESS');
export const LOAD_ALL_FAILURE = mark('LOAD_ALL_FAILURE');
export const LOAD_ONE_REQUEST = mark('LOAD_ONE_REQUEST');
export const LOAD_ONE_SUCCESS = mark('LOAD_ONE_SUCCESS');
export const LOAD_ONE_FAILURE = mark('LOAD_ONE_FAILURE');

// export const LOAD_ALL_FOR_ORG_REQUEST = mark('LOAD_ALL_FOR_ORG_REQUEST');
// export const LOAD_ALL_FOR_ORG_SUCCESS = mark('LOAD_ALL_FOR_ORG_SUCCESS');
// export const LOAD_ALL_FOR_ORG_FAILURE = mark('LOAD_ALL_FOR_ORG_FAILURE');
export const LOAD_ONE_FOR_ORG_REQUEST = mark('LOAD_ONE_FOR_ORG_REQUEST');
export const LOAD_ONE_FOR_ORG_SUCCESS = mark('LOAD_ONE_FOR_ORG_SUCCESS');
export const LOAD_ONE_FOR_ORG_FAILURE = mark('LOAD_ONE_FOR_ORG_FAILURE');

export const SET_CURRENT_OSP_APP_FOR_ORG = mark('SET_CURRENT_OSP_APP_FOR_ORG');

export const loadOneOspAppForOrg = (orgId, ospAppId) => dispatch => {
  // const buildData = makeImmutableData({ orgId, ospAppId });
  const buildData = makeBuildData({ orgId, ospAppId });

  dispatch({
    type: LOAD_ONE_FOR_ORG_REQUEST,
    data: buildData(),
  });

  orgOspAppApi
    .fetchOne(orgId, ospAppId)
    .done(resp => {
      const data = normalize(resp.data.detail, schema.ospApp);

      dispatch({
        type: LOAD_ONE_FOR_ORG_SUCCESS,
        data: buildData(data),
      });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ONE_FOR_ORG_FAILURE,
        data: buildData({ err }),
      });
    });
};
