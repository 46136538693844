import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import actions from 'sow/actions/pure';

// Authentication
const initialAuthState = () => ({
  user_id: null,
  roles: null,
  metadata: null,
  isAuthenticating: false, // for logging in
  isValidating: false, // for validating existing token
});

const authReducer = handleActions(
  {
    [combineActions(actions.auth.ecertLogin, actions.auth.login)](state) {
      return R.assoc('isAuthenticating', true, state);
    },

    [combineActions(actions.auth.ecertLoginDone, actions.auth.loginDone)](state, action) {
      const { user_id, roles, metadata } = action.payload;

      return R.merge(state, {
        user_id,
        roles,
        metadata,
        isAuthenticating: false,
      });
    },

    [combineActions(actions.auth.ecertLoginFail, actions.auth.loginFail)](state) {
      return R.assoc('isAuthenticating', false, state);
    },

    [actions.auth.validateToken](state) {
      return R.assoc('isValidating', true, state);
    },

    [actions.auth.validateTokenDone](state, action) {
      const { user_id, roles, metadata } = action.payload;

      return R.merge(state, { user_id, roles, metadata, isValidating: false });
    },

    [actions.auth.validateTokenFail](state) {
      return R.assoc('isValidating', false, state);
    },

    [actions.auth.logoutDone](state) {
      return initialAuthState();
    },

    [actions.profile.updateUserDone](state, action) {
      const { user } = action.payload;

      return {
        ...state,
        metadata: { ...state.metadata, ...user },
      };
    },
  },
  initialAuthState(),
);

export default authReducer;

export function getAuthState(state) {
  return state.auth;
}

export function isAuthenticated(state) {
  return getAuthState(state).user_id !== null;
}

export function getAuthAcaIdState(state) {
  const metadata = getAuthState(state).metadata;
  const [acaId, ...r] = metadata ? metadata.acas || [null] : [null];
  return acaId;
}

export function getAuthOrgIdState(state) {
  const metadata = getAuthState(state).metadata;
  const [orgId, ...r] = metadata ? metadata.orgs || [null] : [null];
  return orgId;
}

export function getAuthOrgListState(state) {
  const metadata = getAuthState(state).metadata;
  return metadata ? metadata.orgs : [];
}
