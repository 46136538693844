import React from 'react';
import PropTypes from 'prop-types';
import { FastField } from 'formik';

import {
  worksheetQuestionType,
  orgIdType,
  planAppIdType,
  worksheetIdType,
} from 'sow/types';

import Block from 'sow/components/atoms/Block';
import WorksheetField from 'sow/components/organisms/WorksheetField';
import BaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';

const WorksheetQuestion = ({
  name,
  question,
  orgId,
  planAppId,
  worksheetId,
  disabled,
}) => {
  const field = (
    <FastField
      name={name}
      render={({ field, form }) => {
        return (
          <WorksheetField
            orgId={orgId}
            planAppId={planAppId}
            worksheetId={worksheetId}
            question={question}
            answer={field.value}
            handleChange={(questionId, value) => {
              form.setFieldValue(field.name, value, false /* <= shouldValidate */);
            }}
            disabled={disabled || form.isSubmitting}
          />
        );
      }}
    />
  );

  if (question && question.isBaseQuestion) {
    return (
      <BaseQuestion
        question={question}
        orgId={orgId}
        planAppId={planAppId}
        worksheetId={worksheetId}
        field={field}
        tooltip={question.tooltip}
        disabled={disabled}
      />
    );
  }

  return <Block>{field}</Block>;
};

WorksheetQuestion.defaultProps = {
  disabled: false,
};

WorksheetQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  question: worksheetQuestionType.isRequired,
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  worksheetId: worksheetIdType.isRequired,
  disabled: PropTypes.bool.isRequired,
  // TBI
  // changeRequest: PropTypes.bool.isRequired,
};

export default WorksheetQuestion;
