import yup from 'yup';

import { API_URL } from 'sow/constants/api';

export const defaultOrg = () => ({
  name: '',
  contactName: '',
  contactPhone: '',
  location: defaultLocation(),
  acaId: '',
  // legalName: '',
  // generalDescription: '',
  // nonOrganicProductionDescription: '',
  // currentlyCertified: '',
  // everDeniedCertification: '',
  // everSuspendedRevokedCertification: '',
  // prevSuspendedRevokedCert: '',
  // isExempt: '',
  // doesExport: '',
  // exportsToA: '',
  // usersA: '',
  // organizationTypeOther: '',
  // organizationType: '',
  // organizationTypeLabel: '',
  // certificationsMax: '',
  // phone: '',
  // url: '',
  // yearStarted: '',
  // employees: '',
  // contact2Name: '',
  // contact2Phone: '',
  // overseer: '',
  // overseerName: '',
  // legalLocation: defaultLocation(),
  // billingLocation: defaultLocation(),
  // acaName: '',
});

export const defaultLocation = () => ({
  name: '',
  addressText: '',
  address: '',
  city: '',
  state: '',
  postal: '',
  country: '',
  data: '',
});

export const downloadExportUrl = url => `${API_URL}/v1/file/by_path/${url}`;

export const exportFileTypes = {
  main_osp: 'Main Plan',
  lands: 'Locations',
  products: 'Products',
  include_notes: 'Include Notes',
  include_files: 'Include Files',
};

export const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  contactName: yup.string().required('Contact name is required'),
  contactPhone: yup.string().required('Contact phone is required'),
  location: yup
    .object({
      // name: yup.string(),
      // addressText: yup.string(),
      address: yup.string().required('Street address is required'),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      postal: yup.string().required('Postal code is required'),
      country: yup.string().required('Country is required'),
      // data: ???,
    })
    .required(),
  acaId: yup.number().required('You must select a certifier from the list'),
});
