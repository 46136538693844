import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import {
  resourceCreateRequest,
  resourceDetailReadRequest,
  resourceListReadRequest,
} from 'sow/store/actions';
import { downloadExportUrl } from 'sow/utils/org';
import { bugsnagClient } from 'sow/services/bugsnag';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentOrg from 'sow/selectors/currentOrg';
import renderHtml from 'sow/components/organisms/PlanAppExportContent/renderHtml';

const mapStateToProps = (state, props) => ({
  exportTitle: currentOrg.name(state, props),
  exportFileIds: fromPlanApp.exportFileIdList(state, props),
});

const mapDispatchToProps = (dispatch /*ownProps*/) => {
  return {
    toast: (...args) => dispatch(actions.shell.toast(...args)),

    loadUsers: ({ orgId }) => {
      const url = `org/${orgId}/users`;
      const action = resourceListReadRequest(url);
      return dispatch(action);
    },
    loadPlanApp: ({ orgId, planAppId }) => {
      const url = `org/${orgId}/application`;
      const needle = planAppId;
      const action = resourceDetailReadRequest(url, needle, {}, 'planAppResp');
      return dispatch(action);
    },
    loadWorksheetAnswers: ({ orgId, planAppId }) => {
      const url = `org/${orgId}/application/${planAppId}/worksheet_answers`;
      const action = resourceListReadRequest(url, {}, 'worksheetAnswerMap', {
        autoCaseKeys: false,
      });
      return dispatch(action);
    },
    loadChanges: ({ orgId, planAppId, changeRequestId }) => {
      const url = `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`;
      const action = resourceListReadRequest(url, {}, 'change');
      return dispatch(action);
    },
    loadLocations: ({ orgId, planAppId }) => {
      const url = `org/${orgId}/application/${planAppId}/land`;
      const action = resourceListReadRequest(url, {}, 'planAppLocation');
      return dispatch(action);
    },
    loadLocationAnswers: ({ orgId, planAppId }) => {
      const url = `org/${orgId}/application/${planAppId}/land_answers`;
      const action = resourceListReadRequest(url, {}, 'locationAnswers');
      return dispatch(action);
    },
    loadNotes: ({ orgId, planAppId }) => {
      const url = `org/${orgId}/application/${planAppId}/note`;
      const action = resourceListReadRequest(url, {}, 'planAppNote');
      return dispatch(action);
    },

    generateExport: ({ orgId, planAppId, exportHtml, fileIds }) => {
      const url = `org/${orgId}/application/${planAppId}/export`;
      const params = { content: exportHtml, fileIds };
      const action = resourceCreateRequest(url, params);
      return dispatch(action);
    },
  };
};

/**
 * <PlanAppExportBuilderContainer
 *   render={({
 *     buildExport,
 *     isBuilding,
 *     isLoadingData,
 *     exportHtml,
 *     downloadUrl,
 *     fileIds,
 *   }) => <SomeComponent {...} />}
 * />
 **/
class PlanAppExportBuilderContainer extends React.Component {
  state = {
    isLoadingData: false,
    isBuilding: false,
    userList: [],
    exportHtml: null,
    downloadUrl: null,
    latestBuildExportConfig: null,
  };

  // ==
  // == Data Loading (individual)
  // ==

  async loadUserList({ orgId }) {
    const resp = await this.props.loadUsers({ orgId });
    return resp.data;
  }

  async loadPlanApp({ orgId, planAppId }) {
    const resp = await this.props.loadPlanApp({ orgId, planAppId });
    return resp.data;
  }

  async loadWorksheetAnswers({ orgId, planAppId }) {
    const resp = await this.props.loadWorksheetAnswers({ orgId, planAppId });

    return resp.data;
  }

  async loadChanges({ orgId, planAppId, changeRequestId }) {
    const resp = await this.props.loadChanges({ orgId, planAppId, changeRequestId });
    return resp.data;
  }

  async loadLocations({ orgId, planAppId }) {
    const resp = await this.props.loadLocations({ orgId, planAppId });
    return resp.data;
  }

  async loadLocationAnswers({ orgId, planAppId }) {
    const resp = await this.props.loadLocationAnswers({ orgId, planAppId });
    return resp.data;
  }

  async loadNotes({ orgId, planAppId }) {
    const resp = await this.props.loadNotes({ orgId, planAppId });
    return resp.data;
  }

  // ==
  // == Build Process
  // ==

  /**
   * NOTE: exportConfig object should match PlanAppExportContentContainer.propTypes minus `userList`
   **/
  buildExport = async () => {
    const { exportConfig, toast } = this.props;

    this.setState({ isBuilding: true, latestBuildExportConfig: exportConfig });

    try {
      // load data -> render html -> store results to state -> create export
      const { userList } = await this.loadExportData(exportConfig);
      const exportTitle = this.getExportTitle();
      const exportHtml = renderHtml({ exportConfig, userList }, exportTitle);
      const downloadUrl = await this.createExport(exportConfig, exportHtml);
      this.setState({ exportHtml, userList, downloadUrl, isBuilding: false });
      return;
    } catch (e) {
      // TODO consider converting this to return some error value
      bugsnagClient.notify(e);
      toast('danger', 'There was a problem generating the export. Please try again.');
    }
    this.setState({ isBuilding: false });
  };

  getExportTitle() {
    return this.props.exportTitle || '--';
  }

  async createExport(exportConfig, exportHtml) {
    const { exportFileIds } = this.props;
    const resp = await this.props.generateExport({
      ...exportConfig,
      exportHtml,
      fileIds: exportConfig.addFiles ? exportFileIds : [],
    });
    const downloadUrl = downloadExportUrl(resp.data.path);
    return downloadUrl;
  }

  async loadExportData(exportConfig) {
    this.setState({ isLoadingData: true });

    try {
      const userList = await this.loadUserList(exportConfig);
      const planApp = await this.loadPlanApp(exportConfig);

      // Main Plan
      if (exportConfig.showMainPlan) await this.loadWorksheetAnswers(exportConfig);

      // Change Request and Changes
      const changeRequestId = R.path(['detail', 'changeRequest', 'id'], planApp);
      // TODO should we test if the CR exists and is not closed?
      if (changeRequestId) await this.loadChanges({ ...exportConfig, changeRequestId });

      // Locations
      if (exportConfig.showLocations) {
        await this.loadLocations(exportConfig);
        await this.loadLocationAnswers(exportConfig);
      }

      // Notes
      if (exportConfig.addNotes) await this.loadNotes(exportConfig);
      this.setState({ isLoadingData: false });
      return { userList };
    } catch (error) {
      throw error;
    }
  }

  // ==
  // == Render
  // ==

  render() {
    const {
      isBuilding,
      isLoadingData,
      exportHtml,
      userList,
      downloadUrl,
      latestBuildExportConfig,
    } = this.state;
    const { exportFileIds } = this.props;

    return this.props.render({
      buildExport: this.buildExport,
      isBuilding,
      isLoadingData,
      exportHtml,
      userList,
      downloadUrl,
      fileIds: exportFileIds,
      latestBuildExportConfig, // <<-- for live preview
    });
  }
}

PlanAppExportBuilderContainer.propTypes = {
  // passed props
  render: PropTypes.func.isRequired,
  planAppId: sowTypes.planAppIdType,
  exportConfig: sowTypes.planAppExportConfigType,

  // connected props
  exportTitle: PropTypes.string,
  exportFileIds: sowTypes.fileIdListType.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadPlanApp: PropTypes.func.isRequired,
  loadWorksheetAnswers: PropTypes.func.isRequired,
  loadChanges: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  loadLocationAnswers: PropTypes.func.isRequired,
  loadNotes: PropTypes.func.isRequired,
  generateExport: PropTypes.func.isRequired,
  toast: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PlanAppExportBuilderContainer);
