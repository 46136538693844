import React from 'react';
import PropTypes from 'prop-types';

import Select from 'sow/components/molecules/Select';

const optionMapFn = type => ({ value: type, text: type });

const LandTypeSelect = ({ visibleTypes, ...props }) =>
  <Select {...props} options={visibleTypes.map(optionMapFn)} />;

LandTypeSelect.propTypes = {
  visibleTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LandTypeSelect;
