import React from 'react';
import * as R from 'ramda';
import { MenuItem, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as currentUser from 'sow/selectors/currentUser';
import EditModal from 'sow/components/old/EditModal';
import FormGroup from 'sow/components/organisms/FormGroup';
import FormModal from 'sow/components/old/FormModal';
import { actions as ospSecAction } from '../reducers/ospSections';
import { actions as wsAction } from '../reducers/worksheets';
import { confirm } from 'sow/actions/pure/messaging';
import { hocEditable, hocPreviewable } from 'sow/components/old/hocHelpers/uiState';

import * as select from '../selectors';
import BooleanCheckbox from '../FormFields/BooleanCheckbox';
import FormPanel from '../FormPanel';
import WorksheetQuestions from './WorksheetQuestions';
import WorksheetRegulations from './WorksheetRegulations';
import Preview from './Preview';

const makeMapStateToProps = () => (state, props) => ({
  worksheet: select.makeWorksheetSelector()(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

const mapDispatchToProps = (dispatch, { ospSectionId, worksheetId }) => {
  return {
    // worksheet events
    onDelete: event =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet?',
          onConfirm: () =>
            dispatch(ospSecAction.worksheetRemove(ospSectionId, worksheetId)),
        }),
      ),
    onSetValue: R.curry((field, event) => {
      dispatch(wsAction.setValue(worksheetId, field, event.target.value));
    }),
    onSetStaticValue: R.curry((field, value, event) => {
      dispatch(wsAction.setValue(worksheetId, field, value));
    }),

    // regulation event handlers
    onAddRegulation: R.curry((worksheetId, event) => {
      dispatch(wsAction.regulationAdd(worksheetId));
    }),
    onDeleteRegulation: R.curry((worksheetId, regulationId, event) =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet regulation?',
          onConfirm: () => dispatch(wsAction.regulationRemove(worksheetId, regulationId)),
        }),
      ),
    ),
    onMoveRegulationDown: R.curry((worksheetId, regulationId, event) => {
      dispatch(wsAction.regulationMoveDown(worksheetId, regulationId));
    }),
    onMoveRegulationUp: R.curry((worksheetId, regulationId, event) => {
      dispatch(wsAction.regulationMoveUp(worksheetId, regulationId));
    }),
    onSetRegulationValue: R.curry((worksheetId, regulationId, field, event) => {
      dispatch(
        wsAction.regulationSetValue(worksheetId, regulationId, field, event.target.value),
      );
    }),

    // question event handlers
    onAddQuestion: R.curry((worksheetId, event) => {
      dispatch(wsAction.questionAdd(worksheetId));
    }),
    onDeleteQuestion: R.curry((worksheetId, questionId, event) =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet question?',
          onConfirm: () => dispatch(wsAction.questionRemove(worksheetId, questionId)),
        }),
      ),
    ),
    onMoveQuestionDown: R.curry((worksheetId, questionId, event) => {
      dispatch(wsAction.questionMoveDown(worksheetId, questionId));
    }),
    onMoveQuestionUp: R.curry((worksheetId, questionId, event) => {
      dispatch(wsAction.questionMoveUp(worksheetId, questionId));
    }),
  };
};

export const Worksheet = ({
  worksheet,
  worksheetId,
  onDelete,
  onSetValue,
  onSetStaticValue,
  onMoveWorksheetDown,
  onMoveWorksheetUp,

  // question event handlers
  onAddQuestion,
  onDeleteQuestion,
  onMoveQuestionDown,
  onMoveQuestionUp,

  // regulation event handlers
  onAddRegulation,
  onDeleteRegulation,
  onSetRegulationValue,
  onMoveRegulationDown,
  onMoveRegulationUp,

  isEditing,
  onEditDone,
  onEditStart,
  isPreviewing,
  onPreviewDone,
  onPreviewStart,

  sectionType,
  canEditOspDef,
}) => {
  if (!worksheet) return null;
  const questionIds = R.path(['questions'], worksheet);
  const regulations = R.pathOr([], ['regulations'], worksheet);
  const worksheetDropdown = (
    <FormPanel.Heading.Dropdown id={`Worksheet-${worksheetId}`}>
      <MenuItem onClick={onPreviewStart}>
        <Glyphicon glyph="eye-open" /> Preview Worksheet
      </MenuItem>
      <MenuItem divider />
      <MenuItem onClick={onEditStart} disabled={!canEditOspDef}>
        <Glyphicon glyph="pencil" /> Edit Worksheet
      </MenuItem>
      <MenuItem onClick={onDelete} disabled={!canEditOspDef}>
        <Glyphicon glyph="remove" /> Delete Worksheet
      </MenuItem>
    </FormPanel.Heading.Dropdown>
  );

  return (
    <FormPanel.Body.ShortRow
      handlerId={worksheetId}
      onMoveDown={onMoveWorksheetDown}
      onMoveUp={onMoveWorksheetUp}
      customControl={worksheetDropdown}
      stackMoveButtons={true}
      canEditOspDef={canEditOspDef}
    >
      {worksheet.name}
      {isPreviewing && (
        <EditModal
          bsSize="large"
          show={isPreviewing}
          title="Preview Worksheet"
          onHide={onPreviewDone}
        >
          <Preview worksheet={worksheet} questionIds={questionIds} />
        </EditModal>
      )}
      {isEditing && canEditOspDef && (
        <FormModal
          bsSize="large"
          show={isEditing}
          title="Edit Worksheet"
          onHide={onEditDone}
          onSubmit={onEditDone}
        >
          <FormPanel>
            <FormPanel.Heading>General</FormPanel.Heading>
            <FormPanel.Body>
              <FormPanel.Body.Row>
                <FormGroup
                  fieldName="name"
                  label="Worksheet Name"
                  tooltip="Enter the name of the Plan Worksheet. "
                  required
                >
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    onChange={onSetValue('name')}
                    value={worksheet.name}
                    required
                  />
                </FormGroup>

                <FormGroup
                  fieldName="tooltip"
                  label="Worksheet Tooltip"
                  tooltip="Use this to enter helpful text for your clients that is specific to the Worksheet you are creating."
                >
                  <input
                    type="text"
                    id="tooltip"
                    className="form-control"
                    onChange={onSetValue('tooltip')}
                    value={worksheet.tooltip}
                  />
                </FormGroup>

                <div className="form-group">
                  <label className="control-label">Settings</label>
                  <BooleanCheckbox
                    className="form-control"
                    onChange={onSetStaticValue('matrix')}
                    value={worksheet.matrix}
                    text="Matrix"
                  />
                  <BooleanCheckbox
                    className="form-control"
                    onChange={onSetStaticValue('not_applicable')}
                    value={worksheet.not_applicable}
                    text="Show N/A option"
                  />
                </div>
                <FormGroup fieldName="header" label="Worksheet Header Text">
                  <input
                    type="text"
                    id="header"
                    className="form-control"
                    onChange={onSetValue('header')}
                    value={worksheet.header}
                  />
                </FormGroup>
              </FormPanel.Body.Row>
            </FormPanel.Body>
          </FormPanel>

          <WorksheetRegulations
            worksheetId={worksheetId}
            regulations={regulations}
            onAddRegulation={onAddRegulation}
            onDeleteRegulation={onDeleteRegulation}
            onMoveRegulationDown={onMoveRegulationDown}
            onMoveRegulationUp={onMoveRegulationUp}
            onSetRegulationValue={onSetRegulationValue}
          />

          <WorksheetQuestions
            worksheetId={worksheetId}
            questionIds={questionIds}
            onAddQuestion={onAddQuestion}
            onDeleteQuestion={onDeleteQuestion}
            onMoveQuestionDown={onMoveQuestionDown}
            onMoveQuestionUp={onMoveQuestionUp}
            sectionType={sectionType}
          />
        </FormModal>
      )}
    </FormPanel.Body.ShortRow>
  );
};

export default R.compose(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  ),
  hocEditable,
  hocPreviewable,
)(Worksheet);
