import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import { PlanAppLoader, PlanAppChangeLoader } from 'sow/store/containers';
import Spinner from 'sow/components/atoms/Spinner';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';
import OrgPlanAppChecklistPage from 'sow/components/pages/OrgPlanAppChecklistPage';

const mapStateToProps = (state, props) => ({
  qualificationQuestions: fromPlanApp.qualificationQuestions(state, props),
  qualificationAnswers: fromPlanApp.qualificationAnswers(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
});

const OrgPlanAppChecklistPageContainer = ({
  qualificationQuestions,
  qualificationAnswers,
  changeRequestId,
}) => {
  return (
    <OrgPlanAppPageTemplate title="Activities Checklist">
      <PlanAppLoader autoLoad>
        {({ status }) => {
          if (status.loading) return <Spinner />;
          if (!status.success) return null;
          return (
            <PlanAppChangeLoader autoLoad={!!changeRequestId}>
              {({ status }) => {
                if (status.loading) return <Spinner />;
                if (!status.done && changeRequestId) return null;
                return (
                  <OrgPlanAppChecklistPage
                    questions={qualificationQuestions}
                    answers={qualificationAnswers}
                  />
                );
              }}
            </PlanAppChangeLoader>
          );
        }}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppChecklistPageContainer.propTypes = {
  qualificationAnswers: PropTypes.arrayOf(sowTypes.planAppQualificationAnswerType),
  qualificationQuestions: PropTypes.arrayOf(sowTypes.planAppQualificationQuestionType),
  changeRequestId: sowTypes.planAppChangeRequestIdType,
};

OrgPlanAppChecklistPageContainer.defaultProps = {
  org: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgPlanAppChecklistPageContainer);
