import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { SectionHeading } from './style/atoms';
import Worksheet from 'sow/containers/planAppExport/Content/Worksheet';

const Location = ({ locationDetails, isDiscarded, isExcluded, ...props }) => {
  if (isDiscarded) return null;

  // TODO figure out if we need to render something when location is excluded
  if (isExcluded) return null;

  const locationPath = path => R.path(path, locationDetails);
  const name = locationPath(['name']);
  const type = locationPath(['type']);
  const scope = locationPath(['scope']);
  const address = locationPath(['location', 'address']);
  const addressText = locationPath(['location', 'addressText']);
  const city = locationPath(['location', 'city']);
  const state = locationPath(['location', 'state']);
  const postal = locationPath(['location', 'postal']);
  const country = locationPath(['location', 'country']);

  const worksheetIdList = R.propOr([], 'worksheetIds', locationDetails);

  return (
    <Fragment>
      <SectionHeading pageBreak="always">{name}</SectionHeading>

      <ul>
        <li>Type: {type}</li>
        <li>Scope: {scope}</li>
        {!address ? (
          <li>Street address: {addressText}</li>
        ) : (
          <Fragment>
            <li>Street address: {address}</li>
            <li>City: {city}</li>
            <li>State: {state}</li>
            <li>Postal code: {postal}</li>
          </Fragment>
        )}
        <li>Country: {country}</li>
      </ul>

      {worksheetIdList.map(worksheetId => (
        <Worksheet
          key={worksheetId}
          locationId={location.id}
          worksheetId={worksheetId}
          {...props}
        />
      ))}
    </Fragment>
  );
};

Location.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
  showMainPlan: PropTypes.bool,
  showLocations: PropTypes.bool,
  addNotes: PropTypes.bool,
  addComments: PropTypes.bool,
  addFiles: PropTypes.bool,
  userList: sowTypes.userLimitedListType.isRequired,
  locationDetails: sowTypes.planAppLocationType.isRequired,
  locationChange: sowTypes.planAppChangeType,
  isExcluded: PropTypes.bool.isRequired,
  isDiscarded: PropTypes.bool.isRequired,
};

export default Location;
