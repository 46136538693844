import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { resourceUpdate, resourceDelete } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppLocationDeleteButton from 'sow/components/organisms/PlanAppLocationDeleteButton';
import DeletionChange from 'sow/components/organisms/DeletionChange';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isNewOrUpdatedLocation: fromPlanApp.isNewOrUpdatedLocation(state, props),
  deletionChange: fromPlanApp.locationDeletionChange(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
});

const mapResourceToProps = () => {
  const deleteLocationResource = (orgId, planAppId) =>
    resourceDelete(`org/${orgId}/application/${planAppId}/land`, 'planAppLocation');

  const createDeletionChangeResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const mapResourceStateToProps = (state, props) => {
    const { selectors } = deleteLocationResource(props.orgId, props.planAppId);

    return {
      isPending: selectors.pending(state, props),
    };
  };

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const { orgId, planAppId, changeRequestId, locationId, locationDetails } = ownProps;
    const { action: deleteLocation } = deleteLocationResource(orgId, planAppId);
    const { action: createDeletionChange } = createDeletionChangeResource(
      orgId,
      planAppId,
      changeRequestId,
    );

    const deletionChange = {
      context: 'land',
      type: 'self',
      action: 'deleted',
      applicationId: planAppId,
      landId: locationId,
      old: locationDetails,
    };

    return {
      deleteLocation: () =>
        dispatch(deleteLocation(locationId)).then(() =>
          dispatch(actions.shell.toast('info', 'Location deleted.')),
        ),

      createDeletionChange: () =>
        dispatch(createDeletionChange(null, { changes: [deletionChange] })).then(() =>
          dispatch(
            actions.shell.toast(
              'info',
              'Location marked for deletion pending Certifier approval.',
            ),
          ),
        ),
    };
  };

  return connect(
    mapResourceStateToProps,
    mapResourceDispatchToProps,
  );
};

const PlanAppLocationDeleteButtonContainer = ({
  isStateInitialApplication,
  isNewOrUpdatedLocation,
  deletionChange,
  deleteLocation,
  createDeletionChange,
  isPending,
}) => {
  if (isStateInitialApplication) {
    return <PlanAppLocationDeleteButton onClick={deleteLocation} disabled={isPending} />;
  }

  // Locations added by a change cannot be deleted
  if (isNewOrUpdatedLocation) {
    return <PlanAppLocationDeleteButton onClick={() => {}} disabled={true} />;
  }

  if (!deletionChange) {
    return <PlanAppLocationDeleteButton onClick={createDeletionChange} />;
  }

  return (
    <DeletionChange
      change={deletionChange}
      changeUIDisabled={false}
      changeText="This location will be deleted from the plan."
    />
  );
};

PlanAppLocationDeleteButtonContainer.propTypes = {
  // Passed props
  locationId: sowTypes.planAppLocationIdType.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  deleteLocation: PropTypes.func.isRequired,
  createDeletionChange: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  deletionChange: sowTypes.planAppChangeType,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isNewOrUpdatedLocation: PropTypes.bool.isRequired,
};

PlanAppLocationDeleteButtonContainer.defaultProps = {
  deletionChange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
  mapResourceToProps(),
)(PlanAppLocationDeleteButtonContainer);
