import * as R from 'ramda';
import {
  planAppStates,
  PLAN_APP_STATES_OPEN_FOR_ORG,
  PLAN_APP_STATES_UNLOCKED_FOR_ORG,
} from 'sow/constants/planApp';

export const appState = R.path(['state']);
export const isProgressNotStarted = R.pathEq(['percentageComplete'], 0);
export const isProgressStarted = R.complement(isProgressNotStarted);
export const isProgressComplete = R.pathEq(['percentageComplete'], 100);

export const isStateUnlocked = R.compose(
  R.contains(R.__, PLAN_APP_STATES_UNLOCKED_FOR_ORG),
  appState,
);

export const isStateLocked = R.complement(isStateUnlocked);

export const isStateOpen = R.compose(
  R.contains(R.__, PLAN_APP_STATES_OPEN_FOR_ORG),
  appState,
);

export const isStateClosed = R.complement(isStateOpen);

export const isStateInitialApplication = R.pipe(
  appState,
  R.equals(planAppStates.INITIAL_APPLICATION),
);
export const isStateInitialApplicationSubmitted = R.pipe(
  appState,
  R.equals(planAppStates.INITIAL_APPLICATION_SUBMITTED),
);
export const isStateRevision = R.pipe(
  appState,
  R.equals(planAppStates.REVISION),
);
export const isStateUpdateSubmitted = R.pipe(
  appState,
  R.equals(planAppStates.REVISION_SUBMITTED),
);
export const isStateInitialReview = R.pipe(
  appState,
  R.equals(planAppStates.INITIAL_REVIEW),
);
export const isStateInspection = R.pipe(
  appState,
  R.equals(planAppStates.INSPECTION),
);
export const isStateFinalReview = R.pipe(
  appState,
  R.equals(planAppStates.FINAL_REVIEW),
);
export const isStateComplete = R.pipe(
  appState,
  R.equals(planAppStates.COMPLETE),
);
export const isStateNoncompliance = R.pipe(
  appState,
  R.equals(planAppStates.NONCOMPLIANCE),
);
export const isStateWithdrawn = R.pipe(
  appState,
  R.equals(planAppStates.WITHDRAWN),
);

export const isStateSubmitted = R.either(
  isStateInitialApplicationSubmitted,
  isStateUpdateSubmitted,
);

export const isUnderReview = planApp => {
  const reviewStates = [
    planAppStates.INITIAL_REVIEW,
    planAppStates.INSPECTION,
    planAppStates.FINAL_REVIEW,
  ];
  return R.pipe(
    appState,
    R.contains(R.__, reviewStates),
  )(planApp);
};
