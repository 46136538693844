import React from 'react';

import { planAppStates, planAppStateText } from 'sow/constants/planApp';
import { planAppStateType } from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Panel from 'sow/components/molecules/Panel';
import Strong from 'sow/components/atoms/Strong';

const StatePanel = ({ appState, children, ...props }) => (
  <Panel
    {...props}
    heading={
      <Block textCenter>
        <Strong>{planAppStateText[appState]}</Strong>
        {children}
      </Block>
    }
    style={{ margin: 0 }}
  />
);

StatePanel.propTypes = {
  appState: planAppStateType.isRequired,
};

const AppStateText = ({ appState, children }) => {
  const sharedProps = { appState, children };

  switch (appState) {
    case planAppStates.INITIAL_APPLICATION:
    case planAppStates.REVISION:
      return <StatePanel {...sharedProps} success />;

    case planAppStates.INITIAL_APPLICATION_SUBMITTED:
    case planAppStates.REVISION_SUBMITTED:
      return <StatePanel {...sharedProps} warning />;

    case planAppStates.INITIAL_REVIEW:
    case planAppStates.INSPECTION:
    case planAppStates.FINAL_REVIEW:
      return <StatePanel {...sharedProps} warning />;

    case planAppStates.COMPLETE:
      return <StatePanel {...sharedProps} info />;

    case planAppStates.NONCOMPLIANCE:
      return <StatePanel {...sharedProps} danger />;

    case planAppStates.WITHDRAWN:
      return <StatePanel {...sharedProps} warning />;

    default:
      console.error(`appState ${appState} not found in list!`);
      return null;
  }
};

AppStateText.propTypes = {
  appState: planAppStateType.isRequired,
};

export default AppStateText;
