import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import yup from 'yup';

import Button from 'sow/components/atoms/Button';
import FormikField from 'sow/components/organisms/FormikField';
import AcaSelect from 'sow/containers/AcaSelect';

const MigrateOrgToAcaForm = ({ onSubmit }) => (
  <Formik
    initialValues={{ acaId: '' }}
    validationSchema={yup.object({ acaId: yup.number().required() })}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, isSubmitting }) => (
      <Form onSubmit={handleSubmit}>
        <Field
          name="acaId"
          label="ACA migrating to:"
          component={FormikField}
          type={AcaSelect}
          required
        />

        <Button type="submit" primary disabled={isSubmitting}>
          Migrate Organization to ACA
        </Button>
      </Form>
    )}
  </Formik>
);

MigrateOrgToAcaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MigrateOrgToAcaForm;
