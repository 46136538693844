import * as R from 'ramda';
import { v4 as guid } from 'uuid';

// Checks whether the filename contains any non-ascii (00-7F) or ascii extended (80-FF) characters
export const hasValidFilename = R.pipe(
  R.prop('name'),
  R.match(/[^\x00-\xff]/g),
  R.isEmpty,
);

export const formatItemForQueue = (file, uploadUrl) => ({
  guid: guid(),
  uploadUrl: uploadUrl,
  status: 'pending',
  progress: 0,
  error: null,
  errors: [],
  file,
});

export const formatBadItemForQueue = file => ({
  guid: guid(),
  uploadUrl: null,
  status: 'failed',
  progress: 0,
  error: true,
  errors: {
    filename: [
      'At this time, only filenames containing US/English characters are supported. You must rename the file before it can be uploaded.',
    ],
  },
  file,
});
