import { rgb, rgba } from 'polished';

import { planAppStates } from 'sow/constants/planApp';

const theme = {};

theme.colors = {
  white: rgb(0, 0, 0),
  lightGrey: rgb(187, 187, 187),
  darkGrey: rgb(50, 50, 50),
  green: rgb(40, 130, 0),
  red: rgb(169, 68, 66),
  yellow: rgb(230, 175, 0),
  blue: rgb(91, 192, 222),
};

theme.cr = {
  open: theme.colors.yellow,
  accepted: theme.colors.green,
  rejected: theme.colors.red,
  comment: theme.colors.blue,
  blocked: theme.colors.lightGrey,
};

theme.crBackground = {
  open: rgba(theme.cr.open, 0.25),
  accepted: rgba(theme.cr.accepted, 0.25),
  rejected: rgba(theme.cr.rejected, 0.5),
  comment: rgba(theme.cr.comment, 0.5),
  blocked: rgba(theme.cr.blocked, 0.25),
};

theme.note = {
  background: rgba(theme.colors.yellow, 0.1),
  backgroundDeleted: rgba(theme.colors.lightGrey, 0.25),
};

theme.planState = {
  [planAppStates.INITIAL_APPLICATION]: theme.colors.darkGrey,
  [planAppStates.REVISION]: theme.colors.darkGrey,
  [planAppStates.INITIAL_APPLICATION_SUBMITTED]: theme.colors.darkGrey,
  [planAppStates.REVISION_SUBMITTED]: theme.colors.darkGrey,
  [planAppStates.INITIAL_REVIEW]: theme.colors.darkGrey,
  [planAppStates.INSPECTION]: theme.colors.darkGrey,
  [planAppStates.FINAL_REVIEW]: theme.colors.darkGrey,
  [planAppStates.COMPLETE]: theme.colors.green,
  [planAppStates.NONCOMPLIANCE]: theme.colors.red,
  [planAppStates.WITHDRAWN]: theme.colors.red,
};

export default theme;
