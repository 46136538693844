import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import WorksheetAnswer from './WorksheetAnswer';
import { CommentMarker, CommentRow } from './Comment';
import { NoteMarker, NoteRow } from './Note';

const PlanAppExportContentWorksheetMatrixRow = ({
  questionList,
  matrixRowAnswerMap,
  changeList,
  commentList,
  noteList,
  ...props
}) => {
  const { worksheet, matrixRowId, addNotes, addComments } = props;
  if (!questionList) return null;
  const questionIdList = R.path(['questions'], worksheet);

  const colspan = questionList.length;
  const getValue = questionId =>
    R.path([matrixRowId, 'values', questionId], matrixRowAnswerMap);

  return (
    <Fragment>
      <tr>
        {questionList.map(question => (
          <td key={question.uuid}>
            <WorksheetAnswer value={getValue(question.uuid)} question={question} />
          </td>
        ))}
      </tr>
      <tr
        style={{
          borderStyle: 'hidden',
          borderLeft: 0,
          borderRight: 0,
          borderBottom: 0,
        }}
      >
        {questionIdList.map(questionId => {
          const questionNoteList = R.filter(
            R.whereEq({ uuid: questionId, subUuid: matrixRowId }),
            noteList,
          );
          const questionChangeList = R.filter(
            R.whereEq({ questionId, matrixRowId }),
            changeList,
          );
          const questionChangeCommentIdList = R.chain(R.prop('comments'))(
            questionChangeList,
          );
          const questionCommentList = R.filter(
            R.where({
              id: R.contains(R.__, questionChangeCommentIdList),
            }),
            commentList,
          );

          return (
            <td key={questionId}>
              {addNotes &&
                questionNoteList.map(note => <NoteMarker key={note.id} note={note} />)}
              {addComments &&
                questionCommentList.map(comment => (
                  <CommentMarker key={comment.id} comment={comment} />
                ))}
            </td>
          );
        })}
      </tr>
      {addNotes &&
        noteList.map(note => <NoteRow key={note.id} note={note} colspan={colspan} />)}
      {addComments &&
        commentList.map(comment => (
          <CommentRow key={comment.id} comment={comment} colspan={colspan} />
        ))}
    </Fragment>
  );
};

PlanAppExportContentWorksheetMatrixRow.propTypes = {
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  questionList: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionType).isRequired,
  changeList: sowTypes.planAppChangeListType.isRequired,
  matrixRowAnswerMap: PropTypes.object.isRequired,
  commentList: sowTypes.planAppChangeCommentListType.isRequired,
  noteList: sowTypes.planAppNoteListType.isRequred,
  addNotes: PropTypes.bool.isRequired,
  addComments: PropTypes.bool.isRequired,

  matrixRowAnswerMap: PropTypes.object,
  matrixRowIdList: sowTypes.planAppWorksheetQuestionMatrixRowIdTListType.isRequired,
};

export default PlanAppExportContentWorksheetMatrixRow;
