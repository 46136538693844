import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import * as sowTypes from 'sow/types';
import WorksheetQuestion from 'sow/components/organisms/WorksheetQuestion';
import Block from 'sow/components/atoms/Block';
import Spacer from 'sow/components/atoms/Spacer';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import QuestionName from 'sow/components/organisms/WorksheetQuestionName';
import PlanAppChange from 'sow/containers/planApp/Change';
import PlanAppWorksheetCreateChangeButton from 'sow/containers/planApp/WorksheetCreateChangeButton';
import Notes from 'sow/containers/planApp/Notes';
import ScrollTarget from 'sow/components/atoms/ScrollTarget';

const isChangeShownForField = R.complement(R.propEq('fieldType', 'descriptive_text'));

const questionNameNotEmpty = R.pipe(
  R.propOr('', 'name'),
  R.trim,
  R.length,
  R.gt(R.__, 0),
);

const WorksheetQuestionChange = ({
  orgId,
  planAppId,
  worksheetId,
  changeRequest,
  disabled,
  question,
  showAcaUI,
  baseAnswersName,
  baseAnswersChangesName,
  matrixRowId,
  locationId,
  change,
  changeId,
  changeRequestIsOpen,
  changeUIDisabled,
}) => {
  const questionId = question.uuid;
  const showChangeUI = isChangeShownForField(question) && changeRequestIsOpen;

  return (
    <Block>
      <ScrollTarget.PlanAppWorksheetQuestion questionId={questionId} />
      <Notes questionId={question.uuid} matrixRowId={matrixRowId}>
        {({ NotesToggle, NoteList }) => (
          <Block className="panel panel-default">
            {questionNameNotEmpty(question) && (
              <Block className="panel-heading" style={{ paddingBottom: 0 }}>
                <QuestionName question={question} />
                <Block pullRight>{NotesToggle}</Block>
              </Block>
            )}

            <Block className="panel-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Row>
                <Column md={NoteList ? 8 : 12}>
                  <PlanAppChange
                    changeId={changeId}
                    showChangeUI={showChangeUI}
                    changeUIDisabled={changeUIDisabled}
                    renderAnswer={change => (
                      <WorksheetQuestion
                        name={`${baseAnswersName}${questionId}`}
                        orgId={orgId}
                        planAppId={planAppId}
                        worksheetId={worksheetId}
                        question={question}
                        disabled={!!change || disabled}
                      />
                    )}
                    renderChange={change => (
                      <WorksheetQuestion
                        name={`${baseAnswersChangesName}${question.uuid}`}
                        orgId={orgId}
                        planAppId={planAppId}
                        worksheetId={worksheetId}
                        question={question}
                        disabled={!changeRequestIsOpen || changeUIDisabled}
                      />
                    )}
                  />
                </Column>
                {NoteList && (
                  <Column md={4} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    {NoteList}
                  </Column>
                )}
              </Row>
            </Block>

            {showChangeUI && changeRequestIsOpen && !change && (
              <Block className="panel-footer text-right" style={{ padding: '5px 7px' }}>
                <Field
                  name={`${baseAnswersName}${question.uuid}`}
                  changeFieldName={`${baseAnswersChangesName}${question.uuid}`}
                  component={PlanAppWorksheetCreateChangeButton}
                  orgId={orgId}
                  planAppId={planAppId}
                  changeRequestId={changeRequest.id}
                  worksheetId={worksheetId}
                  matrixRowId={matrixRowId}
                  locationId={locationId}
                  questionId={question.uuid}
                  showAcaUI={showAcaUI}
                  disabled={changeUIDisabled}
                />
              </Block>
            )}
          </Block>
        )}
      </Notes>
      <Spacer vertical={20} />
    </Block>
  );
};

WorksheetQuestionChange.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  question: sowTypes.worksheetQuestionType.isRequired,
  disabled: PropTypes.bool.isRequired,
  changeRequest: sowTypes.planAppChangeRequestType,
  change: sowTypes.planAppChangeType,
  changeId: sowTypes.planAppChangeIdType,
  showAcaUI: PropTypes.bool,
  baseAnswersName: PropTypes.string.isRequired,
  baseAnswersChangesName: PropTypes.string.isRequired,
  matrixRowId: PropTypes.string,
  locationId: sowTypes.locationIdType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  changeUIDisabled: PropTypes.bool.isRequired,
};

WorksheetQuestionChange.defaultProps = {
  change: null,
  matrixRowId: null,
  locationId: null,
};

export default WorksheetQuestionChange;
