import React from 'react';

import { acaGeneralRoute, acaStaffRoute, acaWorkflowRoute } from 'sow/routes';
import CurrentAca from 'sow/containers/CurrentAca';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const AcaSettingsNavigation = () => (
  <CurrentAca>
    {(aca, acaId) => (
      <Nav tabs>
        <NavLink to={acaGeneralRoute(acaId)}>General</NavLink>
        <NavLink to={acaWorkflowRoute(acaId)}>Workflow</NavLink>
        <NavLink to={acaStaffRoute(acaId)}>Staff</NavLink>
      </Nav>
    )}
  </CurrentAca>
);

export default AcaSettingsNavigation;
