import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import Button from 'sow/components/atoms/Button';
import Panel from 'sow/components/molecules/Panel';
import ListGroup from 'sow/components/molecules/ListGroup';
import ListGroupItem from 'sow/components/atoms/ListGroupItem';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';

import InspectorAssignForm from 'sow/containers/InspectorAssignForm';

const HeaderNoticeMsg = (
  <Block>
    <Strong>NOTE:</Strong> The <Strong>Inspectors</Strong> settings tab is only accessible
    to ACA staff and organization users will not see it.
  </Block>
);

const OrgSettingsInspectors = ({
  orgId,
  inspectorAccessList,
  inspectorAccessListDone,
  inspectorAccessListFailed,
  inspectorAccessListPending,
  inspectorList,
  inspectorListDone,
  inspectorListFailed,
  inspectorListPending,
  handleRevokeInspectorAccess,
}) => {
  if (inspectorListFailed || inspectorAccessListFailed) {
    return (
      <Panel
        title="Something went wrong while fetching inspectors. Please try again later."
        danger
      />
    );
  }
  if (
    !inspectorListDone ||
    !inspectorAccessListDone ||
    inspectorAccessListPending ||
    inspectorListPending
  ) {
    return <Spinner />;
  }

  return (
    <Block style={{ marginTop: '15px' }}>
      <InfoPanel text={HeaderNoticeMsg} />

      <Widget>
        <WidgetHeading>Current Inspectors</WidgetHeading>
        <WidgetBody>
          {inspectorAccessList.length < 1 ? (
            'No inspectors assigned to this organization.'
          ) : (
            <ListGroup>
              {inspectorAccessList.map(inspectorAccess => (
                <ListGroupItem key={inspectorAccess.inspector.id}>
                  {inspectorAccess.inspector.name}
                  {' - '}
                  {inspectorAccess.inspector.email}
                  <Button
                    danger
                    onClick={handleRevokeInspectorAccess(inspectorAccess.inspector.id)}
                    className="pull-right"
                    height={38}
                    style={{
                      padding: 4,
                      marginTop: -5,
                    }}
                  >
                    Remove
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </WidgetBody>
      </Widget>

      <Block style={{ marginTop: '10px' }} />

      <InspectorAssignForm orgId={orgId} inspectorList={inspectorList} />
    </Block>
  );
};

OrgSettingsInspectors.propTypes = {
  orgId: PropTypes.number,
  inspectorAccessList: PropTypes.array,
  inspectorAccessListDone: PropTypes.bool,
  inspectorAccessListFailed: PropTypes.bool,
  inspectorAccessListPending: PropTypes.bool,
  inspectorList: PropTypes.array,
  inspectorListDone: PropTypes.bool,
  inspectorListFailed: PropTypes.bool,
  inspectorListPending: PropTypes.bool,
  handleRevokeInspectorAccess: PropTypes.func,
};

export default OrgSettingsInspectors;
